import AuthService from "../../Services/authService";
import { setMessage, clearMessage } from "./messageAction";
import { setLoading, removeLoading } from "./loadingAction";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "./types";

export const LoginAction =
  (username: any, password: any) => (dispatch: any) => {
    // show loading indicator:
    dispatch(setLoading());

    return AuthService.Login({ username, password }).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        dispatch(
          setMessage("Sie haben sich erfolgreich angemeldet.", "success")
        );
        dispatch(removeLoading());
        setTimeout(() => {
          // document.body.classList.remove("active-modal");
          dispatch(LogOutAction());
        }, 8.64e7);

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.message === "Request failed with status code 401") {
          dispatch(setMessage("Ungültige Email oder Passwort", "error"));
        }
        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());

        return Promise.reject();
      }
    );
  };

export const LogOutAction = () => (dispatch: any) => {
  AuthService.Logout();
  dispatch({
    type: LOGOUT,
  });
  dispatch(removeLoading());
  dispatch(clearMessage());
};
