import "./License.css";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteLicense } from "../../../Store/actions/licenseAction";

// components:
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

export interface LicenseProps {
  licenseID: number;
  location: any;
  validFrom: any;
  validTo: any;
  count: any;
  pathname: any;
}

const License: React.FC<LicenseProps> = ({
  licenseID,
  location,
  validFrom,
  validTo,
  count,
  pathname,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //local state
  const [isDeletePopup, setDeletePopup] = useState(false);

  // delete license function:
  const DeleteLicense = () => {
    dispatch(deleteLicense(licenseID))
      .then(() => {
        history.push("/manage-licenses");
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <>
      {isDeletePopup ? (
        <tr>
          <td>
            <DeletePopup
              label="Soll diese Lizenz wirklich gelöscht werden?"
              Delete={DeleteLicense}
              closeDeletePopup={() => setDeletePopup(false)}
            />
          </td>
        </tr>
      ) : null}
      <tr>
        <td className="my-td-label-license">
          <h3>{location}</h3>
        </td>
        <td className="my-td-label-license">
          <h3>{moment(validFrom).format("DD.MM.YYYY")}</h3>
          {/* <h3>{validFrom}</h3> */}
        </td>
        <td className="my-td-label-license">
          <h3>{moment(validTo).format("DD.MM.YYYY")}</h3>
        </td>
        <td className="my-td-label-license">
          <h3>{count}</h3>
        </td>
        <td className="my-td-label-license">
          <Link to={pathname + licenseID}>
            <DefaultButton>Ändern</DefaultButton>
          </Link>
        </td>
        <td className="my-td-label-license">
          <DefaultButton onClick={() => setDeletePopup(true)}>
            Löschen
          </DefaultButton>
        </td>
      </tr>
    </>
  );
};

export default License;
