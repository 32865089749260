import { SHOW_LOADING, REMOVE_LOADING } from "../actions/types";

const initialState = { loading: false };

const LoadingReducer = (state = initialState, action: any) => {
  const { type } = action;

  switch (type) {
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default LoadingReducer;
