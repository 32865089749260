import {
  UPSERT_SUB_PROCESS,
  GET_SUB_PROCESSES,
  // GET_SUB_PROCESSES_BY_PROCESS_ID,
  // UPDATE_SUB_PROCESS,
  DELETE_SUB_PROCESS,
} from "./types";
import subProcessService from "../../Services/subProcessService";
import { setMessage } from "../actions/messageAction";
import { setLoading, removeLoading } from "../actions/loadingAction";

export const upsertSubProcess = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    await subProcessService.upsertSubProcess(data).then(
      (data) => {
        dispatch({
          type: UPSERT_SUB_PROCESS,
          payload: data,
        });
        dispatch(removeLoading());
        dispatch(setMessage("Der Teilprozess wurde gespeichert.", "success"));

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(removeLoading());
        dispatch(setMessage(message, "error"));

        return Promise.reject(message);
      }
    );
  } catch (err) {
    dispatch(removeLoading());
    dispatch(setMessage(err, "error"));

    // return Promise.reject(err);
  }
};

export const createSubProcess = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    await subProcessService.createSubProcess(data).then(
      (data) => {
        dispatch({
          type: UPSERT_SUB_PROCESS,
          payload: data,
        });
        dispatch(removeLoading());
        dispatch(
          setMessage("Der Teilprozess wurde erfolgreich erstellt.", "success")
        );

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(removeLoading());
        dispatch(setMessage(message, "error"));

        return Promise.reject();
      }
    );
  } catch (err) {
    dispatch(removeLoading());
    dispatch(setMessage(err, "error"));

    return Promise.reject(err);
  }
};

// export const getSubProcessesByProcessID = (id) => async (dispatch) => {
//   try {
//     // dispatch(setLoading());

//     await subProcessService.getSubProcessesByProcessID(id).then(
//       (res) => {
//         dispatch({
//           type: GET_SUB_PROCESSES_BY_PROCESS_ID,
//           payload: res.data,
//         });
//         // dispatch(removeLoading());
//         return Promise.resolve(res.data);
//       },
//       (error) => {
//         const message =
//           (error.response &&
//             error.response.data &&
//             error.response.data.message) ||
//           error.message ||
//           error.toString();

//         // dispatch(removeLoading());
//         dispatch(setMessage(message, "error"));

//         return Promise.reject();
//       }
//     );
//   } catch (err) {
//     // dispatch(removeLoading());
//     dispatch(setMessage(err, "error"));
//     console.log(err);
//   }
// };

export const getAllSubProcesses = () => async (dispatch: any) => {
  try {
    // dispatch(setLoading());

    await subProcessService.getAllSubProcesses().then(
      (res) => {
        dispatch({
          type: GET_SUB_PROCESSES,
          payload: res.data,
        });
        // dispatch(removeLoading());
        return Promise.resolve(res.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // dispatch(removeLoading());
        dispatch(setMessage(message, "error"));

        return Promise.reject();
      }
    );
  } catch (err) {
    // dispatch(removeLoading());
    dispatch(setMessage(err, "error"));
    console.log(err);
  }
};

export const deleteSubProcess = (id: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    await subProcessService.deleteSubProcess(id).then(
      (data) => {
        dispatch({
          type: DELETE_SUB_PROCESS,
          payload: { id },
        });
        dispatch(removeLoading());
        dispatch(
          setMessage("Der Teilprozess wurde erfolgreich gelöscht.", "success")
        );
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject();
      }
    );
  } catch (err) {
    console.log(err);
  }
};
