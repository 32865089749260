import "./OverviewPage.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// actions
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import { setMessage } from "../../../Store/actions/messageAction";
import { setPresetProcess } from "../../../Store/actions/presetProcessAction";
import { setSubPresetProcess } from "../../../Store/actions/presetSubProcessAction";

// services
import ImageService from "../../../Services/imageService";
import ProcessService from "../../../Services/processService";

//Components:
import Loader from "../../../Components/Loader/Loader";
import TrashIcon from "../../../Components/TrashIcon/TrashIcon";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";
import ProcessPopup from "../../../Components/ProcessPopup/ProcessPopup";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

//Images:
import edit from "../../../Assets/Images/edit.png";
import Image from "../../../Assets/Images/image.png";
// import NeuerProzess from "../../../Assets/Images/neuer-prozess.png";

export interface OverviewPageProps {}

const OverviewPage: React.FC<OverviewPageProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  const [processes, setProcesses] = useState<any>();
  const [processImages, setProcessImages] = useState<any>();
  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    processID: "",
  });

  const [isProcessPopup, setProcessPopup] = useState({
    processPopup: false,
    processID: "",
    image: "",
    name: "",
    label:""
   
  });
  useEffect(() => {
    getProcesses();
    document.title = "JMS - Übersicht Prozesse";
    // eslint-disable-next-line
  }, [isProcessPopup.processPopup]);

  // when clicking on any process in overview page we redirect to the manage processes with detail of process clicked:
  const goToProcess = (process: any) => {
    history.push("/manage-sub-processes");
    // history.push("/create-edit-process/" + processID);
    dispatch(
      setPresetProcess({
        presetProcessID: process.processID,
        presetProcessName: process.name,
      })
    );
    dispatch(setSubPresetProcess(""));
  };

  // get and display all processes in the DB:
  const getProcesses = () => {
    dispatch(setLoading());
    ProcessService.getAllProcesses()
      .then((response) => {
        setProcesses(response.data);
        getImages(response.data);
        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function to get all images that take images IDs
  function getImages(data: any) {
    if (data !== undefined) {
      let imageIDs = [];
      for (let item of data) {
        if (item.imageID !== undefined) {
          imageIDs.push(item.imageID);
        }
      }
      dispatch(setLoading());
      ImageService.getAllImages(imageIDs, data)
        .then((res) => {
          setProcessImages(res.data);
          dispatch(removeLoading());
        })
        .catch((e: any) => {
          console.log(e);
          dispatch(removeLoading());
        });
    }
  }

  // function to delete a process:
  const DeleteProcess = (id: any) => {
    ProcessService.deleteProcess(id)
      .then(
        () => {
          setDeletePopup({ deletePopup: false, processID: "" });
          getProcesses();
          dispatch(
            setMessage("Der Prozess wurde erfolgreich gelöscht.", "success")
          );
          history.push("/overview-page");
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setDeletePopup({ deletePopup: false, processID: "" });
          getProcesses();
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((error: any) => {
        setDeletePopup({ deletePopup: false, processID: "" });
        getProcesses();
        dispatch(setMessage(error.message, "error"));
        console.log(error);
      });
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Soll dieser Prozess wirklich gelöscht werden?"
          Delete={() => DeleteProcess(isDeletePopup.processID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, processID: "" })
          }
        />
      ) : null}

      {isProcessPopup.processPopup && isProcessPopup ? (
        <ProcessPopup
          label={isProcessPopup.label}
          processID={isProcessPopup.processID}
          name={isProcessPopup.name}
          image={isProcessPopup.image}
          Save={() => {
            // setSavePopup({ savePopup: false });
    
          }}
          closeProcessPopup={() =>
            setProcessPopup({
              processPopup: false,
              processID: "",
              name: "",
              image: "",
              label:""
            })
          }
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
    <>
      <div className="overview-page-new-process-button">
        <DefaultButton
          onClick={() => {
            setProcessPopup({
              processPopup: true,
              processID: "",
              name: "",
              image: "",
              label: "Neuen Prozess erstellen",
            });
          }}
        >
          Neuer Prozess
        </DefaultButton>
      </div>
      <div className="overview-processes">
        {processes
          ? processes.map((process: any, index: any) => (
              <>
                <div key={index} className="one-process">
                  {processImages &&
                    processImages
                      .filter(
                        (item: any) => item.imageID === process.imageID
                      )
                      .map(function (image: any) {
                        process.image = image.image;
                        return null;
                      })}

                  <div
                    className="overview-page-clickable-div"
                    onClick={() => goToProcess(process)}
                  >
                    <h4 className="overview-page-process-title">
                      {process.name}
                    </h4>
                    <img
                      className="one-process-img"
                      src={
                        process.imageID !== undefined
                          ? "data:image/jpeg;base64," + process.image
                          : Image
                      }
                      alt={process.name}
                      title={process.name}
                    />
                  </div>
                  <div className="one-process-icons-container">
                    <img
                      className="icon-edit"
                      src={edit}
                      alt="edit"
                      onClick={() => {
                        setProcessPopup({
                          processPopup: true,
                          processID: process.processID,
                          image: process.image,
                          name: process.name,
                          label:"Prozess bearbeiten"
                        });
                      }}
                    />
                    <TrashIcon
                      onClick={() =>
                        setDeletePopup({
                          deletePopup: true,
                          processID: process.processID,
                        })
                      }
                      id={process.processID}
                    />
                  </div>
                </div>
              </>
            ))
          : null}
          </div>
        </>
      )}
    </>
  );
};

export default OverviewPage;
