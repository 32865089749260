import "./LocationList.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocations } from "../../../Store/actions/locationAction";
import FunctionService from "./../../../Services/functionService";
import { useHistory } from "react-router-dom";

// components:
import Location from "../Location/Location";

export interface LocationListProps {}

const LocationList: React.FC<LocationListProps> = () => {
  const dispatch = useDispatch<any>();
  // redux state
  const locations = useSelector((state: any) => state.LocationReducer);

  // local state
  const [functions, setFunctions] = useState([]);

  // function that get all functions
  const GetAllFunctions = () => {
    FunctionService.getAllFunctions()
      .then((res) => setFunctions(res.data))
      .catch((err) => console.log(err));
  };

  //get all locations once the component renders:
  const history = useHistory();
  useEffect(() => {
    dispatch(getAllLocations());
    //make sure locations are rerendered so when we create a new location it appears without refresh
    if (history.location.state) {
      const previous_path = history.location.state as { from: string };
      if (previous_path.from === "create-edit-location") {
        setTimeout(() => {
          dispatch(getAllLocations());
        }, 800);
      }
    }

    GetAllFunctions();
  }, [dispatch, history.location.state]);

  return (
    <>
      <table className="locations">
        <tbody>
          <tr>
            <th className="my-td-label-locations">
              <h2>Stadt/ Bereich</h2>
            </th>
            {functions.map((fun: any, index: any) => (
              <th key={index} className="my-td-label-locations">
                <h2 id="function-name">{fun.name}</h2>
              </th>
            ))}
            <th colSpan={2} className="my-td-label-locations">
              <h2>Aktion</h2>
            </th>
          </tr>
          {locations.map((location: any, index: any) => (
            <Location
              key={index}
              index={index}
              id={location.locationID}
              label={location.city}
              assignedFunctions={location.assignedFunctions}
              pathname="create-edit-location/"
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LocationList;
