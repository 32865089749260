import "./Login.css";
import { useEffect } from "react";
import { useFormik } from "formik";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "../../Store/actions/authAction";
import { LoginSchema } from "./../../Validations/LoginValidations";
import { setPresetProcess } from "../../Store/actions/presetProcessAction";

// Components:
import Input from "../../Components/Input/Input";
import Loader from "../../Components/Loader/Loader";
import DefaultButton from "../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../Components/MessageNotification/MessageNotification";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

export interface LoginProps {}

const Login: React.FC<LoginProps> = (LoginProps) => {
  const dispatch = useDispatch<any>();
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { isLoggedIn } = useSelector((state: any) => state.LoginReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // useFormik react hook: to handle the login form:
  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: LoginSchema,
      onSubmit: ({ email, password }) => {
        dispatch(LoginAction(email, password));
      },
    });

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    dispatch(setPresetProcess(""), { signal: signal });

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  if (isLoggedIn && user.userRole === "Admin") {
    return <Redirect to="/" />;
  } else if (isLoggedIn && user.userRole === "SensrecAdmin") {
    return <Redirect to="/manage-locations" />;
  }

  setTimeout(() => {
    // document.body.classList.remove("active-modal");
    if (!isLoggedIn) {
      document.body.classList.remove("active-modal");
    }
    document.title = "JMS - Login";
  }, 0);

  return (
    <>
      <Header />
      <div className="home">
        <h1>
          JMS<sup>+</sup>(Job Management System)
        </h1>
      </div>
      <div className="login">
        {messageText ? (
          <MessageNotification
            messageText={messageText}
            messageType={messageType}
          />
        ) : null}

        {loading ? (
          <Loader />
        ) : (
          <>
            <h1>Anmelden</h1>
            <h3>Bitte verwenden Sie ein lokales Konto für die Anmeldung.</h3>
            <hr className="login-hr" />
            <form onSubmit={handleSubmit} className="login-form">
              <table>
                <tbody>
                  <tr>
                    <td className="login-label">
                      <h2>E-Mail</h2>
                    </td>
                    <td>
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        value={values.email}
                        placeholder="username@example.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.email && errors.email ? (
                        <div className="login-error-message">
                          {errors.email}
                        </div>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h2>Kennwort</h2>
                    </td>
                    <td>
                      <Input
                        id="password"
                        //change type
                        type="password"
                        value={values.password}
                        placeholder="* * * * * * * *"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.password && errors.password ? (
                        <div className="login-error-message">
                          {errors.password}
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="login-btn">
                <DefaultButton type="submit">Anmelden</DefaultButton>
              </div>
            </form>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Login;
