import {
  CREATE_LOCATION,
  GET_LOCATIONS,
  GET_ONE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
} from "./types";

import LocationService from "../../Services/locationService";
import { setMessage } from "../actions/messageAction";
import { setLoading, removeLoading } from "../actions/loadingAction";

export const createLocation =
  (cityName: any, code: any) => async (dispatch: any) => {
    try {
      // show loading indicator:
      dispatch(setLoading());

      // API call to create location:
      await LocationService.createLocation({ cityName, code }).then(
        (data) => {
          dispatch({
            type: CREATE_LOCATION,
            payload: data,
          });
          // remove loading indicator:
          dispatch(removeLoading());
        },
        (error) => {
          // get error message from everywhere in the response:
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // remove loading indicator:
          dispatch(removeLoading());
          return Promise.reject(message);
        }
      );
    } catch (err) {
      // remove loading indicator:
      dispatch(removeLoading());

      // show failed message:
      dispatch(setMessage(err, "error"));
    }
  };

export const getAllLocations = () => async (dispatch: any) => {
  try {
    // API call to get all locations:
    await LocationService.getAllLocations().then((res) => {
      dispatch({
        type: GET_LOCATIONS,
        payload: res.data,
      });

      dispatch(removeLoading());
      return Promise.resolve(res.data);
    });
  } catch (err: any) {
    dispatch(removeLoading());
    dispatch(setMessage(err.message, "error"));
    console.log(err);
  }
};

export const updateLocation =
  (id: number, cityName: any, code: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading());

      // API call to update location:
      await LocationService.updateLocation(id, { cityName, code }).then(
        (data) => {
          dispatch({
            type: UPDATE_LOCATION,
            payload: data,
          });
          dispatch(removeLoading());

          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(removeLoading());

          return Promise.reject(message);
        }
      );
    } catch (err: any) {
      dispatch(removeLoading());
      dispatch(setMessage(err, "error"));
      return Promise.reject(err);
    }
  };

export const getLocation = (title: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    // API call to get one location:
    const res = await LocationService.getLocation(title);

    dispatch({
      type: GET_ONE_LOCATION,
      payload: res.data,
    });
    dispatch(removeLoading());
  } catch (err: any) {
    dispatch(setMessage(err, "error"));
    dispatch(removeLoading());
    console.log(err);
  }
};

export const deleteLocation = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    await LocationService.deleteLocation(id).then(
      (data) => {
        dispatch({
          type: DELETE_LOCATION,
          payload: { id },
        });
        dispatch(removeLoading());
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(removeLoading());
        return Promise.reject(message);
      }
    );
  } catch (err) {
    dispatch(setMessage(err, "error"));
    console.log(err);
  }
};