import * as Yup from "yup";

export const ProcessSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Erforderlich!"),
  // processDescription: Yup.string().max(50).required("Required!"),
  // image: Yup.string().max(50).required("Required!"),
  // subProcessName: Yup.string().max(50).required("Required!"),
  // subProcessImage: Yup.number().max(50).required("Required!"),
});

export const SubProcessSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Erforderlich!"),
  subProcessName: Yup.string().max(50).required("Erforderlich!"),
  // processDescription: Yup.string().max(50).required("Required!"),
  // image: Yup.string().max(50).required("Required!"),
  // subProcessName: Yup.string().max(50).required("Required!"),
  // subProcessImage: Yup.number().max(50).required("Required!"),
});

export const ScanSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  scanItem: Yup.string().max(50),
  // scanItem: Yup.string().max(50).required("Erforderlich!"),
  // description: Yup.string().max(200).required("Erforderlich!"),
  // name: Yup.string().max(50).required("Erforderlich!")
  name: Yup.string().max(50),
});

export const ListSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  name: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200),
});

export const FreeTextSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200),
});

export const SignatureSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200),
});

export const DateSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200),
});

export const NumberSchema = Yup.object().shape({
  title: Yup.string().max(50).required("RErforderlich!"),
  description: Yup.string().max(200),
});

export const InstructionSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200).required("Erforderlich!"),
});

export const PhotoSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200),
});

export const YesNoSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200),
  // name: Yup.string().required("Required!"),
});

export const CountSchema = Yup.object().shape({
  title: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(200),
  // name: Yup.string().required("Required!"),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
    .max(100, "Das Passwort muss maximal 100 Zeichen lang sein.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])/,
      "Das Passwort muss aus mindestens einem Großbuchstaben, einer Zahl und einem Sonderzeichen bestehen."
    )
    .required("Erforderlich!"),

  confirmedPassword: Yup.string()
    .max(100, "Das Passwort muss maximal 100 Zeichen lang sein.")
    .required("Erforderlich!")
    .oneOf(
      [Yup.ref("password"), null],
      "Die Passwörter müssen übereinstimmen."
    ),
});

export const UnitSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Erforderlich!"),
});

// Ip address Schema Validation
export const IpAddressSchema = Yup.object().shape({
  ip: Yup.string()
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Ungültige IP Adresse",
      excludeEmptyString: true,
    })
    .test(
      "ipAddress",
      "Ungültige IP Adresse: die Komponenten der IP Adresse müssen im Bereich von 0 bis 255 liegen.",
      (value) => {
        if (value === undefined || value.trim() === "") return true;
        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      }
    )
    .max(50)
    .required("Erforderlich!"),
});