import http from "./http-common";
import authHeader from "./authHeader";

const getUser = (id: number) => {
  return http.get(`/User/${id}`, { headers: authHeader() });
};

const getAllUsers = () => {
  return http.get("/User", { headers: authHeader() });
};

const createUser = (data: any) => {
  return http.post("/User/register", data, { headers: authHeader() });
};

const updateUser = (id: number, data: any) => {
  return http.put(`/User/${id}`, data, { headers: authHeader() });
};

const deleteUser = (id: number) => {
  return http.delete(`/User/${id}`, { headers: authHeader() });
};

const resetUserPassword = (data: any) => {
  return http.put("/User/ResetPassword/", data, { headers: authHeader() });
};

const UserService = {
  getUser,
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  resetUserPassword,
};

export default UserService;
