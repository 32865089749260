import "./IpAddress.css";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Action
import { setMessage } from "../../../Store/actions/messageAction";
import { deleteLocationIP } from "../../../Store/actions/locationIPAction";

// components:
import Loader from "../../../Components/Loader/Loader";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

type LicenseProps = {
  LocationIpID: string;
  ipAddress: any;
  pathname: any;
  setIpAddressCount: any;
  ipAddressCount: any;
};

const IpAddress = (props: LicenseProps) => {
  const dispatch = useDispatch<any>();

  // redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local state
  const [isDeletePopup, setDeletePopup] = useState(false);

  // delete ip-address function:
  const DeleteIpAddress = () => {
    dispatch(deleteLocationIP(props.LocationIpID))
      .then(() => {

        dispatch(
          setMessage(" Der Ip-Addresse wurde erfolgreich gelöscht.", "success")
        );
       props.setIpAddressCount(props.ipAddressCount -1);
        setDeletePopup(false);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <>
      {isDeletePopup ? (
        <tr>
          <td>
            <DeletePopup
              label="Soll diese IP-Adresse wirklich gelöscht werden?"
              Delete={DeleteIpAddress}
              closeDeletePopup={() => setDeletePopup(false)}
            />
          </td>
        </tr>
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <tr>
          <td className="ip-address-column ">
            <h3>{props.ipAddress}</h3>
          </td>
          <td className="ip-address-column ">
            <Link to={props.pathname + props.LocationIpID}>
              <DefaultButton>Ändern</DefaultButton>
            </Link>
          </td>
          <td className="ip-address-column ">
            <DefaultButton onClick={() => setDeletePopup(true)}>
              Löschen
            </DefaultButton>
          </td>
        </tr>
      )}
    </>
  );
};
export default IpAddress;
