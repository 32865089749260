import "./ManageLocations.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// components:
import Loader from "../../../Components/Loader/Loader";
import LocationList from "../LocationList/LocationList";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
// import NewBackButtons from "./../../../Components/NewBackButtons/NewBackButtons";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

export interface ManageLocationsProps {}

const ManageLocations: React.FC<ManageLocationsProps> = () => {
  // redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  useEffect(() => {
    document.title = "JMS - Standorte verwalten";
  }, []);

  // // checking if use if loggedIn or no:
  // if (isLoggedIn && user.userRole !== "SensrecAdmin") {
  //   dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
  //   return <Redirect to="/overview-page" />;
  // }

  return (
    <>
      <SensrecAdminNavbar />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="manage-locations-body">
            <div className="title-btns">
              <h1>Standorte verwalten:</h1>
              <div className="topBtn">
                <Link to="/create-edit-location">
                  <DefaultButton>Neu Erstellen</DefaultButton>
                </Link>
              </div>
            </div>

            <div>
              <LocationList />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManageLocations;
