import axios from "axios";

export default axios.create({
  baseURL: "https://jms-ba.astlab.info",
  // baseURL: " http://192.168.0.3:42793/",
  // baseURL: "http://localhost:42793/",
  headers: {
    "Content-type": "application/json",
  },
});
