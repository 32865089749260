/* eslint-disable eqeqeq */
import "./Photo.css";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Services
import InstructionService from "../../../Services/instructionService";
import JobParameterService from "../../../Services/jobParameterService";

// Actions
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import {
  addJobParameter,
  resetJobParameter,
  updateJobParameterRedux,
} from "../../../Store/actions/jobParameterAction";
import { setMessage } from "../../../Store/actions/messageAction";

// components:
// import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import UserButton from "../../../Components/UserButton/UserButton";

function Photo({
  subProcess,
  setSubProcess,
}: {
  subProcess: any;
  setSubProcess: any;
}) {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const job = useSelector((state: any) => state.JobReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const jobParametersArray = useSelector(
    (state: any) => state.JobParameterReducer
  );

  const jobID = job.jobID;

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  //local state:
  const [photo, setPhoto] = useState<any>();
  // initial state of the image
  const [image, setImage] = useState<any>();
  const [hasPhoto, setHasPhoto] = useState(false);
  // if we are in update mode this will be the job parameter ID
  const [jobParameterID, setJobParameterID] = useState<string>();
  //state that stores the result from the redux store
  const [capturedPhoto, setCapturedPhoto] = useState<any>();

  const [nextFunction, setNextFunction] = useState<any>("");
  const [isLastFunction, setLastFunction] = useState<any>(false);
  // popup state
  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
    isLogin: false,
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { fun, id } = params;
  const code = fun.toUpperCase();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);
  const videoRef = useRef(null);
  const photoRef = useRef(null);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { width: 1920, height: 1080 },
      })
      .then((Stream) => {
        let video: any = videoRef.current;
        video.srcObject = Stream;
        video.play();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const takePhoto = () => {
    const width = 400;
    const height = 300;

    let video: any = videoRef.current;
    let photo: any = photoRef.current;
    photo.width = width;
    photo.height = height;

    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);
    setHasPhoto(true);

    setImage(photo.toDataURL().split(/[ ,]+/)[1]);
  };

  const closePhoto = () => {
    let photo: any = photoRef.current;
    let ctx = photo?.getContext("2d");
    // eslint-disable-next-line no-lone-blocks
    {
      capturedPhoto
        ? setCapturedPhoto("")
        : ctx.clearRect(0, 0, photo.width, photo.height);
    }
    setHasPhoto(false);
  };

  useEffect(() => {
    getVideo();
    return () => {};
  }, [videoRef]);

  const nextFunctionFun = () => {
    subProcess?.assignedFunctions?.map((subProcessData: any, index: any) => {
      if (
        subProcessData.code === code &&
        subProcessData.subProcessFunctionAssignmentID == id
      ) {
        if (index === subProcess?.assignedFunctions.length - 1) {
          setLastFunction(true);
          setNextFunction(index + 1);
        } else {
          setNextFunction(index + 1);
        }
      }
      return nextFunction;
    });
  };

  // get the job parameter by filter mean: by jobID and InstructionID
  const getJobParameterByFilter = () => {
    const { instructionID } = photo;
    // API call
    JobParameterService.getJobParametersByMultipleFilter(jobID, [instructionID])
      .then((response: any) => {
        setCapturedPhoto(jobParametersArray[nextFunction - 1].image);
        setImage(jobParametersArray[nextFunction - 1].image);
        setHasPhoto(true);
        setJobParameterID(response.data[0].jobParameterID);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    dispatch(removeLoading());
    nextFunctionFun();

    nextFunction &&
      getInstructionBySubProcessFunctionID(
        subProcess?.assignedFunctions[nextFunction - 1]
          ?.subProcessFunctionAssignmentID,
        { signal: signal }
      );

    // get job parameter by jobID and instruction ID
    jobParametersArray[0] &&
      (jobParametersArray[0]?.jobID === jobID ||
        jobParametersArray[0][0]?.jobID === jobID) &&
      photo?.instructionID &&
      jobParametersArray[nextFunction - 1]?.instructionID ===
        photo?.instructionID &&
      getJobParameterByFilter();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [subProcess, nextFunction, photo?.instructionID, dispatch, id]);

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number, signal: any) => {
    // dispatch(setLoading());

    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response: any) => {
        if (response?.data?.length > 0) {
          setPhoto(response.data[0]);
        } else {
          dispatch(
            setMessage("No data have been added from the admin", "error")
          );
        }

        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  const saveJobParameter = () => {
    const { instructionID } = photo;
    let pathType = "Photos";

    dispatch(setLoading());
    jobParametersArray[0] &&
    (jobParametersArray[0]?.jobID === jobID ||
      jobParametersArray[0][0]?.jobID === jobID) &&
    jobParameterID
      ? // API call update
        JobParameterService.updateJobParameter([
          { jobID, instructionID, jobParameterID, image, pathType },
        ])
          .then(
            (response) => {
              dispatch(removeLoading());
              // add the data created in the redux store
              dispatch(
                updateJobParameterRedux({
                  jobParameterName: "Photo",
                  title: photo?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  image: image,
                })
              );
              // navigate even to the next instruction or to the final page
              isLastFunction ||
              subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("message", message);

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          })
      : JobParameterService.createJobParameter([
          {
            jobID,
            instructionID,
            image,
            pathType,
          },
        ])
          .then(
            (response) => {
              dispatch(removeLoading());
              dispatch(
                addJobParameter({
                  jobParameterName: "Photo",
                  title: photo?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  image: image,
                })
              );
              isLastFunction
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          });
  };

  // navigate to the subProcess page and empty the redux store
  const saveJob = () => {
    if (isSavePopup.isLogin === false) {
      history.push(`/sub-processes-overview/T/${subProcess.processID}`);
    } else {
      history.push("/login");
    }
    setSubProcess(null);
    dispatch(resetJobParameter());
  };
  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        if (e.target.tagName.toLowerCase() === "a") {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: true,
          });
        } else {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: false,
          });
        }
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Möchtest du aufhören?"
          Save={() => {
            saveJob();
            setSavePopup({ savePopup: false, isLogin: false });
          }}
          closeSavePopup={() =>
            setSavePopup({ savePopup: false, isLogin: false })
          }
          buttonText="Ja"
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="sub-process-function-name">
            <p>{subProcess.name}</p>
            <h3 className="sub-process-function-name-h3">{photo?.title}</h3>
          </div>

          <div>
            <p>{photo?.description}</p>
          </div>

          <div style={{ width: 400, height: 300, marginLeft: "2rem" }}>
            <div className="user-photo">
              <div className={hasPhoto ? "none" : "camera"}>
                <video className="photo-video" ref={videoRef}></video>
                <div className="snap-button">
                  <UserButton onClick={takePhoto}>ein Bild machen</UserButton>
                </div>
              </div>

              <div className={hasPhoto ? "result" : "none"}>
                {capturedPhoto ? (
                  <img
                    className="photo-video"
                    src={"data:image/jpeg;base64," + capturedPhoto}
                    alt="Captured img"
                  />
                ) : (
                  <canvas ref={photoRef}></canvas>
                )}
                <div>
                  <UserButton onClick={closePhoto}>
                    erneut fotografieren
                  </UserButton>
                </div>
              </div>
            </div>
          </div>

          <div className="back-next-buttons">
            <UserButton
              onClick={() => {
                nextFunction === 1 &&
                subProcess?.assignedFunctions.length !==
                  jobParametersArray?.length
                  ? setSavePopup({
                      savePopup: true,
                      isLogin: false,
                    })
                  : subProcess?.assignedFunctions.length ===
                    jobParametersArray?.length
                  ? history.push("/sub-processes-overview/final-page/")
                  : history.push(
                      "/sub-processes-overview/" +
                        subProcess?.assignedFunctions[nextFunction - 2]?.code +
                        "/" +
                        subProcess?.assignedFunctions[nextFunction - 2]
                          ?.subProcessFunctionAssignmentID
                    );
              }}
            >
              zurück
            </UserButton>
            <UserButton disabled={!hasPhoto} onClick={saveJobParameter}>
              {subProcess?.assignedFunctions.length ===
              jobParametersArray?.length
                ? "speichern"
                : "weiter"}
            </UserButton>
          </div>
        </>
      )}
    </>
  );
}

export default Photo;
