import "./CreateEditFunction.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  createFunction,
  updateFunction,
} from "../../../Store/actions/functionAction";
import { setMessage } from "../../../Store/actions/messageAction";
import { FunctionSchema } from "../../../Validations/SensrecAdminValidations";

//Services
import FunctionService from "../../../Services/functionService";
import ImageService from "../../../Services/imageService";

// Components:
import Loader from "../../../Components/Loader/Loader";
// import TrashIcon from "../../../Components/TrashIcon/TrashIcon";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import Input from "../../../Components/Input/Input";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import FormikControl from "../../../Components/FormikControl/FormikControl";

//Images:
import image from "../../../Assets/Images/image.png";

export interface CreateEditFunctionProps {
  props: any;
  match: any;
}

const CreateEditFunction: React.FC<CreateEditFunctionProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  // local states
  // local state funciton:
  const [func, setFunction] = useState({
    name: "",
    description: "",
    image: image,
    // imageID:null
  });

  //set image Id of current function
  const [imageID, setimageID] = useState(0);

  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(image);

  //get the id from url params:
  const { id } = props.match.params;

  //store saved function coming from DB in a state
  const [savedFunction, setSavedFunction] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  // constant to know if the form is on creation or editing license:
  const isAddMode = !id;

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  // if the form is on edit, so we get function data from DB:
  const GetFunction = (id: number) => {
    FunctionService.getFunction(id)
      .then(
        (response) => {
          setFunction(response.data);
          setSavedFunction(response.data);
          if (response.data.imageID !== undefined) {
            setimageID(response.data.imageID); //set image Id of current function
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
        }
      )
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    !isAddMode && GetFunction(props.match.params.id);
    document.title = "JMS - Funktion  bearbeiten";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAddMode, props.match.params.id]);

  //useEffect to show the process image in the preview position if we are editing an existing function:
  useEffect(() => {
    if (imageID) {
      ImageService.getImage(imageID).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.png");
        document.body.appendChild(link);
        setBaseImage(link);
      });
    }
  }, [dispatch, props.match.params.id, imageID]);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  // onSubmit general function that check if the form is create or edit
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    const { name, description, image } = func;
    let data = new FormData();
    data.append("name", name);
    data.append("description", description ? description : "");
    data.append("image", image);

    if (isAddMode) {
      dispatch(createFunction(data))
        .then((data: any) => {
          history.push("/manage-functions");
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      dispatch(updateFunction(id, data))
        .then((data: any) => {
          history.push("/manage-functions");
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  // check user authentication:
  if (!isLoggedIn) {
    return <Redirect to="/" />;
  } else if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    return <Redirect to="/manage-functions" />;
  }

  // show image preview when selected:
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
    setFunction({
      ...func,
      image: file,
    });
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFunction({ ...func, [name]: value });
  };

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/" />;
  }

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);
  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (JSON.stringify(savedFunction) === JSON.stringify(func)) {
      //both states are same
      setIsStateChanged(false);
    } else {
      //states are not equal
      if (isAddMode && func.name === "") {
        setIsStateChanged(false);
      } else {
        setIsStateChanged(true);
      }
    }
  };

  return (
    <>
      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Soll die Funktion gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      <SensrecAdminNavbar />
      <div className="create-function-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div ref={formRef}>
              <Formik
                initialValues={func}
                validationSchema={FunctionSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                  //store errors in a stated to send as prop for save pop up
                  setTimeout(() => {
                    setValidationError(errors);
                  }, 0);
                  return (
                    <>
                      <div className="title-btns">
                        {isAddMode ? (
                          <h1>Funktion erstellen:</h1>
                        ) : (
                          <h1>Funktion ändern:</h1>
                        )}
                        <div className="back-btn">
                          <DefaultButton onClick={() => history.goBack()}>
                            Zurück
                          </DefaultButton>
                        </div>
                      </div>
                      <Form className="create-license-form">
                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Name <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="name"
                                  id="name"
                                  type="text"
                                  value={func.name}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>Beschreibung</h2>
                              </td>
                              <td className="my-td-input">
                                <Field
                                  component="textarea"
                                  rows="4"
                                  name="description"
                                  type="textarea"
                                  // value={values.description && values.description}
                                  value={func.description}
                                  placeholder="Beschreibung"
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2> Bild auswählen </h2>
                              </td>
                              <td className="create-function-label">
                                <label
                                  className="image-input"
                                  htmlFor="image-input"
                                >
                                  <img
                                    src={baseImage ? baseImage : image}
                                    alt="add"
                                  />

                                  <Input
                                    id="image-input"
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    placeholder="Durchsuchen.."
                                    onChange={handleImageChange}
                                  />
                                </label>
                              </td>
                              {/* <div className="trach-function-img"> //TODO: remove this commented block
                              <TrashIcon
                                onClick={() => {
                                 //remove image Id and its preview
                                  setFunction({
                                    ...func,
                                    image: "",
                                    imageID:null
                                  });
                                  setBaseImage(null)
                                }}
                              /></div> */}
                            </tr>
                          </tbody>
                        </table>
                        <div className="save-cancel-btns">
                          <DefaultButton
                            disabled={!isStateChanged}
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>

                          <DefaultButton
                            onClick={() => history.push("/manage-functions")}
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateEditFunction;
