import http from "./http-common";
import authHeader from "./authHeader";

const getSubProcess = (id: string) => {
  return http.get(`/SubProcess/${id}`, { headers: authHeader() });
};

const assignFunctionToSubProcess = (id: any, data: any) => {
  return http.post(`/SubProcess/AssignFunctionToSubProcess/${id}`, data, {
    headers: authHeader(),
  });
};

const getSubProcessesByProcessID = (id: string) => {
  return http.get(`/SubProcess/GetByProcessID/${id}`, {
    headers: authHeader(),
  });
};

const getAllSubProcesses = () => {
  return http.get("/SubProcess", { headers: authHeader() });
};

const upsertSubProcess = async (data: any) => {
  const res = await http.post("/subprocess/upsert", data, {
    headers: authHeader(),
  });
  return res;
};

const createSubProcess = async (data: any) => {
  const res = await http.post("/subprocess/upsert", data, {
    headers: authHeader(),
  });
  return res;
};

const updateSubProcess = (data: any) => {
  return http.put("/SubProcess", data, {
    headers: authHeader(),
  });
};

const deleteSubProcess = (id: string) => {
  return http.delete(`/SubProcess/${id}`, { headers: authHeader() });
};

const deleteSubProcessFunctionAssignment = (
  subProcessFunctionAssignmentID: any
) => {
  return http.delete(
    `/SubProcess/DeleteSubProcessFunctionAssignment/${subProcessFunctionAssignmentID}`,
    { headers: authHeader() }
  );
};

const SubProcessService = {
  getSubProcess,
  assignFunctionToSubProcess,
  getSubProcessesByProcessID,
  getAllSubProcesses,
  upsertSubProcess,
  createSubProcess,
  updateSubProcess,
  deleteSubProcess,
  deleteSubProcessFunctionAssignment,
};

export default SubProcessService;
