import { CREATE_FUNCTION, GET_FUNCTIONS, DELETE_FUNCTION } from "../actions/types";

const initialState = [] as any;

function FunctionReducer(functions = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_FUNCTION:
      return [...functions, payload];

    case GET_FUNCTIONS:
      return payload;

    case DELETE_FUNCTION:
      return functions.filter(({ id }: { id: any }) => id !== payload.id);

    default:
      return functions;
  }
}

export default FunctionReducer;
