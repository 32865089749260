import "./ProcessTable.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import ProcessService from "./../../../Services/processService";
import { setMessage } from "./../../../Store/actions/messageAction";

// components:
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

export interface ProcessTableProps {}

const ProcessTable: React.FC<ProcessTableProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  const [processes, setProcesses] = useState([]);
  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    processID: "",
  });

  // get all process from DB
  const getProcesses = (signal: any) => {
    ProcessService.getAllProcesses().then((res) => {
      setProcesses(res.data);
    });
  };

  // function to delete a process:
  const DeleteProcess = (id: any) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    ProcessService.deleteProcess(id)
      .then(
        () => {
          setDeletePopup({ deletePopup: false, processID: "" });
          getProcesses({ signal: signal });
          dispatch(
            setMessage("Der Prozess wurde erfolgreich gelöscht.", "success")
          );
          history.push("/manage-processes");
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setDeletePopup({ deletePopup: false, processID: "" });
          getProcesses({ signal: signal });
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((error: any) => {
        setDeletePopup({ deletePopup: false, processID: "" });
        getProcesses({ signal: signal });
        dispatch(setMessage(error.message, "error"));
        console.log(error);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    getProcesses({ signal: signal });
    document.title = "JMS - Prozesse verwalten";

    return function cleanup() {
      abortController.abort();
    };
  }, [dispatch]);

  const pathname = "/create-edit-process/";

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Soll dieser Prozess wirklich gelöscht werden?"
          Delete={() => DeleteProcess(isDeletePopup.processID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, processID: "" })
          }
        />
      ) : null}

      <table>
        <tbody>
          <tr>
            <th className="my-td-label-process">
              <h2>Prozessname</h2>
            </th>
            <th colSpan={2} className="my-td-label-process">
              <h2>Aktion</h2>
            </th>
          </tr>
          {processes !== undefined
            ? processes.map((process: any) => (
                <tr key={process.processID}>
                  <td className="my-td-label-process">
                    <h3>{process.name}</h3>
                  </td>
                  <td className="my-td-label-process">
                    <Link to={pathname + process.processID}>
                      <DefaultButton>Umbenennen</DefaultButton>
                    </Link>
                  </td>
                  <td className="my-td-label-process">
                    <DefaultButton
                      onClick={() =>
                        setDeletePopup({
                          deletePopup: true,
                          processID: process.processID,
                        })
                      }
                    >
                      Löschen
                    </DefaultButton>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </>
  );
};

export default ProcessTable;
