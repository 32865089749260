import "./TrashIcon.css";

//Images:
import trash from "../../Assets/Images/trash.png";

export interface TrashIconProps {
  id?: any;
  onClick: any;
  className?: any;
}

const TrashIcon: React.FC<TrashIconProps> = ({ id, onClick ,className}) => {
  return <img className="icon-trash-popup" src={trash} alt="trash" onClick={onClick} />;
};

export default TrashIcon;
