import "./CreateEditLocation.css";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";

//validation
import { LocationSchema } from "../../../Validations/SensrecAdminValidations";

//Action
import {
  createLocation,
  getAllLocations,
  updateLocation,
} from "../../../Store/actions/locationAction";
import { setMessage } from "./../../../Store/actions/messageAction";

//Services
import LocationService from "../../../Services/locationService";
import FunctionService from "../../../Services/functionService";

// Components:
import Loader from "../../../Components/Loader/Loader";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import FormikControl from "../../../Components/FormikControl/FormikControl";

export interface CreateEditLocationProps {
  props: any;
  match: any;
}

const CreateEditLocation: React.FC<CreateEditLocationProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  const [location, setLocation] = useState({ city: "", code: "" });
  const [functions, setFunctions] = useState<any>();

  //store saved location coming from DB in a state
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [savedLocation, setSavedLocation] = useState<any>();
  //check if state is changed

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  //saved assigned functions
  const [assignedFunctions, setAssignedFunctions] = useState<any>();
  const [savedFunctions, setSavedFunctions] = useState<any>();

  //selected functions
  const [selectedFunctions, setSelectedFunctions] = useState<any>();
  const [functionIDs, setSelectedFunctionIDs] = useState();
  const [newSelectedFunctions, setNewSelectedFunctions] = useState<any>();

  //function deletion
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [deletedFunction, setDeletedFunction] = useState<any>();

  // this id is from the URL:
  const { id } = props.match.params;

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  // declare selectStyles to override the menu className for react-select
  const selectStyles = { menu: () => ({ background: "hsl(0, 0%, 100%)" }) };

  // here we convert the naming from react select defaults to options as we need the naming in our state and DB
  let options: any;
  //get function options
  if (functions) {
    options = functions.map(function (item: any) {
      return { value: item.functionID, label: item.name };
    });
  }

  // isAddMode to know if the form is for creating new location or for updating exciting one:
  const isAddMode = !id;

  // get and display all locations in the DB:
  const GetLocation = (id: number) => {
    LocationService.getLocation(id)
      .then((response) => {
        //get saved function options to display them in dropdown
        const saved_options = response.data.assignedFunctions.map(function (
          item: any
        ) {
          return { value: item.functionID, label: item.name };
        });
        const saved_functions = [] as any;
        saved_options.forEach((option: any) => {
          saved_functions.push(option.value);
        });
        setAssignedFunctions(saved_options);
        //get saved function Ids as array of integers
        const saved_functions_IDs = saved_functions.map(function (obj: any) {
          return obj;
        });
        setSavedFunctions(saved_functions_IDs);
        setLocation(response.data);
        setSavedLocation(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get and display all functions in the DB:
  const GetFunctions = () => {
    FunctionService.getAllFunctions()
      .then((response) => {
        setFunctions(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // useEffect
  useEffect(() => {
    GetFunctions();
    !isAddMode && GetLocation(props.match.params.id);
    document.title = "JMS - Standort  bearbeiten";
  }, [isAddMode, props.match.params.id]);

  function CreateLocation(fields: any, setSubmitting: any) {
    dispatch(createLocation(fields.city, fields.code))
      .then(
        () => {
          AssignFunctions();
          // setMessage notification that location is created:
          dispatch(
            setMessage("Der Standort wurde erfolgreich erstellt.", "success")
          );
        },
        (error: any) => {
          // get error message from everywhere in the response:
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // show failed message:
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((e: any) => {
        console.log(e);
      });
  }

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //get edited function Ids as array of integers
  const edited_functions = [] as any;
  selectedFunctions &&
    selectedFunctions.forEach((option: any) => {
      edited_functions.push(option.value);
    });

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);

  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (edited_functions.length > 0) {
      if (
        JSON.stringify(savedLocation) === JSON.stringify(location) &&
        JSON.stringify(edited_functions) === JSON.stringify(savedFunctions) &&
        deletedFunction === undefined
      ) {
        //check if user selected other functions and different from saved
        setIsStateChanged(false);
      } else {
        //compare state in create mode
        if (isAddMode && (location.city === "" || location.code === "")) {
          setIsStateChanged(false);
        } else {
          setIsStateChanged(true);
        }
      }
    } else {
      if (
        JSON.stringify(savedLocation) === JSON.stringify(location) &&
        deletedFunction === undefined
      ) {
        setIsStateChanged(false);
      } else {
        //compare state in create mode
        if (isAddMode && (location.city === "" || location.code === "")) {
          setIsStateChanged(false);
        } else {
          setIsStateChanged(true);
        }
      }
    }
  };

  // handle the name input change:
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setLocation({ ...location, [name]: value });
  };

  function AssignFunctions() {
    LocationService.getAllLocations()
      .then((response) => {
        //we need the location ID so we can pass it to Assign Functions
        const locationId = response.data[response.data.length - 1].locationID;
        const LocationFunction = { locationId, functionIDs };
        if (functionIDs !== undefined) {
          LocationService.assignFunctionsToLocation(LocationFunction);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    history.push(`/manage-locations`, { from: "create-edit-location" });
  }

  // update exciting location function:
  function UpdateLocation(id: number, fields: any, setSubmitting: any) {
    dispatch(updateLocation(id, fields.city, fields.code))
      .then(
        (response: any) => {
          const locationId = id;
          const functionIDs = newSelectedFunctions;
          const LocationFunction = { locationId, functionIDs };
          if (functionIDs !== undefined) {
            LocationService.assignFunctionsToLocation(LocationFunction).then(
              (res) => {
                LocationService.getAllLocations();
              }
            );
          }
          dispatch(getAllLocations());
          history.push("/manage-locations");
          // LocationService.getAllLocations();
          // AssignFunctions();
          dispatch(setMessage("Location updated successfully!", "success"));
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((e: any) => {
        console.log(e);
      });
    history.push(`/manage-locations`, { from: "create-edit-location" });
  }

  // onSubmit checking if the form is create or update:
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAddMode) {
      CreateLocation(fields, setSubmitting);
    } else {
      const updated_fields = {
        locationID: fields.locationID,
        city: JSON.parse(localStorage.getItem("location-city")!)
          ? JSON.parse(localStorage.getItem("location-city")!)
          : fields.city,
        code: JSON.parse(localStorage.getItem("location-code")!)
          ? JSON.parse(localStorage.getItem("location-code")!)
          : fields.code,
        assignedFunctions: fields.assignedFunctions,
      };
      UpdateLocation(id, updated_fields, setSubmitting);
    }
  }

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    return <Redirect to="/manage-processes" />;
  }

  const deleteFunction = () => {
    const locationID = deletedFunction.id;
    const functionID = deletedFunction.removed_item;
    const functionIDs = deletedFunction.removed_function;
    const LocationFunctions = { locationID, functionIDs };
    //check if one single function needs to be deleted or all functions
    if (functionIDs && functionIDs.length > 0) {
      LocationService.deleteAssignedFunctions(LocationFunctions)
        .then(
          (res: any) => {
            dispatch(
              setMessage("Der Funktion wurde erfolgreich geändert.", "success")
            );
            GetLocation(id);
            setDeletePopup(false);
          },
          (error: any) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
          }
        )
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      LocationService.deleteAssignedFunction(locationID, functionID)
        .then(
          (res) => {
            dispatch(
              setMessage("Der Funktion wurde erfolgreich geändert.", "success")
            );
            setDeletePopup(false);
            GetLocation(id);
          },
          (error: any) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
          }
        )
        .catch((err) => {
          console.log(err);
        });
    }
    document.body.classList.remove("active-modal");
  };

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/" />;
  }
  //store city input in local storage
  const location_city = document.getElementById("city") as HTMLInputElement;
  localStorage.setItem(
    "location-city",
    location_city && JSON.stringify(location_city.value)
  );

  //store code input in local storage
  const location_code = document.getElementById("code") as HTMLInputElement;
  localStorage.setItem(
    "location-code",
    location_code && JSON.stringify(location_code.value)
  );

  return (
    <>
      {isDeletePopup ? (
        <DeletePopup
          label="Soll diese Funktion wirklich gelöscht werden?"
          Delete={deleteFunction}
          closeDeletePopup={() => {
            setDeletePopup(false);
            GetLocation(id);
            document.body.classList.remove("active-modal");
          }}
        />
      ) : null}

      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Soll der Prozess gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      <SensrecAdminNavbar />

      {messageText === "error" ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      <div className="create-locations-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div ref={formRef}>
              <Formik
                // take values from local state location as formik values:
                initialValues={location}
                // LocationSchema are Yup validation declared in validation file:
                validationSchema={LocationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  dirty,
                  isValid,
                }) => {
                  //store errors in a stated to send as prop for save pop up
                  setTimeout(() => {
                    setValidationError(errors);
                  }, 0);
                  return (
                    <>
                      <div className="title-btns">
                        {isAddMode ? (
                          <h1>Standort erstellen:</h1>
                        ) : (
                          <h1>Standort ändern:</h1>
                        )}

                        <div className="back-btn">
                          <DefaultButton onClick={() => history.goBack()}>
                            Zurück
                          </DefaultButton>
                        </div>
                      </div>
                      <Form className="create-locations-form">
                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Stadt/ Bereich{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="city"
                                  id="city"
                                  type="text"
                                  value={location.city}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Code <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="code"
                                  id="code"
                                  type="text"
                                  value={location.code}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Funktionen
                                  {/* <span className="required-p">*</span> */}
                                </h2>
                              </td>
                              <td>
                                <Select
                                  name="functions"
                                  options={functions && options}
                                  styles={selectStyles}
                                  menuPosition="fixed"
                                  isMulti
                                  value={
                                    assignedFunctions
                                      ? assignedFunctions
                                      : selectedFunctions
                                  }
                                  placeholder=" Funktionen auswählen"
                                  onChange={(value) => {
                                    setAssignedFunctions(undefined);
                                    setSelectedFunctions(value);
                                    const selected_functions = [] as any;
                                    value.forEach((option) => {
                                      selected_functions.push(option.value);
                                    });
                                    setSelectedFunctionIDs(selected_functions);

                                    //get only the new selected functions
                                    let new_selected_functions = !isAddMode
                                      ? selected_functions.filter(
                                          (item: any) =>
                                            !savedFunctions.includes(item)
                                        )
                                      : null;
                                    setNewSelectedFunctions(
                                      new_selected_functions
                                    );
                                    //get the function that was removed
                                    let removed_function = !isAddMode
                                      ? savedFunctions.filter(
                                          (item: any) =>
                                            !selected_functions.includes(item)
                                        )
                                      : null;
                                    if (
                                      !isAddMode &&
                                      removed_function[0] !== undefined
                                    ) {
                                      const removed_item = removed_function[0];
                                      setDeletePopup(true);
                                      //check if one function is selected or all need to be removed
                                      removed_function.length > 1
                                        ? setDeletedFunction({
                                            id,
                                            removed_function,
                                          })
                                        : setDeletedFunction({
                                            id,
                                            removed_item,
                                          });
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="functions"
                                  component="div"
                                  className="new-location-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  (<span className="required-p">*</span>
                                  ):{" "}
                                  <span className="required-p">
                                    Erforderlich
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="save-cancel-btns">
                          <DefaultButton
                            disabled={!isStateChanged}
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                          <DefaultButton
                            onClick={() => history.push("/manage-locations")}
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateEditLocation;
