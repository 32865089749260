import { useState } from "react";
import "./ChangePasswordPopup.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { changePasswordSchema } from "../../../Validations/AdminValidations";
import UserService from "../../../Services/userService";
import { setMessage } from "../../../Store/actions/messageAction";

// Components:
import Loader from "../../../Components/Loader/Loader";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

export default function ChangePasswordPopup({
  closeChangePasswordPopup,
  userId,
}: {
  closeChangePasswordPopup: any;
  userId: any;
}) {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  //redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  //local states
  // const [password, setPassword] = useState<any>({
  //   password: "",
  //   confirmedPassword: "",
  // });
  const [ChangePasswordPopup, setChangePasswordPopup] = useState(true);

  //input change handler
  // const handleInputChange = (event: any) => {
  //   const { id, value } = event.target;
  //   setPassword({ [id]: value });
  // };

  // const handleInputChange = (event: any) => {
  //   const { id, value } = event.target;
  //   setPassword((previousState: any) => {
  //     //update the state with the current value
  //     previousState[id] = value;
  //     return {
  //       ...previousState,
  //       value,
  //     };
  //   });
  // };

  // function that change the password
  const ChangePassword = (values: any) => {
    const { password, confirmedPassword } = values;
    // check if the password match with confirmedPassword
    if (password === confirmedPassword) {
      UserService.resetUserPassword({ userId, password })
        .then(() => {
          dispatch(
            setMessage("Das Passwort wurde erfolgreich geändert.", "success")
          );

          setChangePasswordPopup(false);
          closeChangePasswordPopup(false);
          // check if the userRole is admin,to go to the overview-page after resetting the password
          if (isLoggedIn && user.userRole === "Admin") {
            history.push("/overview-page");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const toggleModal = () => {
    setChangePasswordPopup(!ChangePasswordPopup);
  };

  if (ChangePasswordPopup) {
    document.body.classList.add("active-modal");
    // document.body.classList.add("create-license-form");
  } else {
    //var b = document.querySelector("create-license-form");
    //document.getElementById('classRight').style.display = 'none';
    //b.setAttribute('display', 'none');
    // document.body.className("create-license-form").style.display = 'none';
    document.body.classList.remove("active-modal");
    // document.body.classList.remove("create-license-form");
  }

  // checking the user role to restrict some page
  // if (isLoggedIn && user.userRole !== "Admin") {
  //   dispatch(setMessage("Sie sind kein Admin", "error"));
  //   return <Redirect to="/manage-locations" />;
  // }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{ password: "", confirmedPassword: "" }}
            validationSchema={changePasswordSchema}
            onSubmit={
              ChangePassword
              // ChangePassword(password.password, password.confirmedPassword)
            }
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form className="create-license-form">
                    <div className="modal">
                      <div onClick={toggleModal} className="overlay"></div>
                      <div className="modal-content">
                        <h2>Bitte geben Sie ein neues Passwort ein:</h2>

                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Neues Passwort:
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td>
                                <Field
                                  id="password"
                                  name="password"
                                  type="password"
                                  // value={password.password}
                                  placeholder="* * * * * * "
                                  className="change-password-container "
                                  // onChange={handleInputChange}
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Passwort bestätigen:
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td>
                                <Field
                                  id="confirmedPassword"
                                  name="confirmedPassword"
                                  type="password"
                                  // value={password.confirmedPassword}
                                  placeholder="* * * * * * "
                                  className="change-password-container "
                                  // onChange={handleInputChange}
                                />
                                <ErrorMessage
                                  name="confirmedPassword"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  (<span className="required-p">*</span>
                                  ):{" "}
                                  <span className="required-p">
                                    Erforderlich
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div>
                          <p>
                            Das Passwort muss mindestens 8 Zeichen lang sein und
                            mindestens ein Zeichen aus allen der vier folgenden
                            Kategorien enthalten:
                          </p>
                          <ul>
                            <li>
                              <p
                                style={{ fontSize: ".8rem", textAlign: "left" }}
                              >
                                Großbuchstaben A,B,C...Z
                              </p>
                            </li>
                            <li>
                              <p style={{ fontSize: ".8rem" }}>
                                Kleinbuchstaben a,b,c...z
                              </p>
                            </li>
                            <li>
                              <p style={{ fontSize: ".8rem" }}>
                                Zahlen 1,2,3...9
                              </p>
                            </li>
                            <li>
                              <p style={{ fontSize: ".8rem" }}>
                                Gültige Sonderzeichen !@#$%^&*"
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="popup-btns">
                          <DefaultButton type="submit">Change</DefaultButton>
                          <DefaultButton onClick={closeChangePasswordPopup}>
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
