import "./Header.css";
import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LogOutAction } from "../../Store/actions/authAction";
import { setPresetProcess } from "../../Store/actions/presetProcessAction";

// components:
import ChangePasswordPopup from "../../Pages/Admin/ChangePasswordPopup/ChangePasswordPopup";

export interface HeaderProps {
  subProcess?: any;
}

const Header: React.FC<HeaderProps> = (subProcess) => {
  const dispatch = useDispatch<any>();
  const location = useLocation();

  //redux states
  const loginDetails = useSelector((state: any) => state.LoginReducer.user);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const presetProcessName = useSelector(
    (state: any) => state.PresetProcessReducer
  );

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  // local states
  const [changePasswordPopup, setChangePasswordPopup] = useState({
    changePasswordPopup: false,
    userId: "",
  });

  const logoutBtnHandler = () => {
    dispatch(LogOutAction());
  };

  return (
    <>
      {changePasswordPopup.changePasswordPopup ? (
        <ChangePasswordPopup
          closeChangePasswordPopup={() =>
            setChangePasswordPopup({
              changePasswordPopup: false,
              userId: changePasswordPopup.userId,
            })
          }
          userId={changePasswordPopup.userId}
        />
      ) : null}

      {/* the overview button to show processes */}
      <div className="header">
        <div className="header1">
          {isLoggedIn && loginDetails.userRole === "Admin" ? (
            <Link to={{ pathname: "/" }}>
              <p className="header-btn"> Übersicht Prozesse</p>
            </Link>
          ) : !isLoggedIn && presetProcessName.processName ? (
            // <Link to={{ pathname: "/user-overview-page" }}>
            <p> {presetProcessName.processName}</p>
          ) : //</Link>
          // check the current path to change the button accordingly
          !isLoggedIn && currentRoute.includes("/sub-processes-overview/t") ? (
            <Link to={{ pathname: "/" }}>
              <p className="header-btn">Übersicht Prozesse </p>
            </Link>
          ) : !isLoggedIn &&
            subProcess.subProcess &&
            currentRoute.includes("/sub-processes-overview/") ? (
            <Link to={{ pathname: "/" }}>
              <p className="header-btn">Übersicht TeilProzesse </p>
            </Link>
          ) : !isLoggedIn &&
            !subProcess.subProcess &&
            currentRoute.includes("/sub-processes-overview/") ? (
            <Link to={{ pathname: "/" }}>
              <p className="header-btn">Übersicht Prozesse </p>
            </Link>
          ) : (
          !isLoggedIn && (
            <Link to={{ pathname: "/" }}>
              <p className="header-btn">Benutzerzone </p>
            </Link>
          )
          )}
        </div>

        {/* the zone title */}
        <div className="header2">
          {isLoggedIn && loginDetails.userRole === "Admin" ? (
            <h3> Ansicht Admin</h3>
          ) : null}
          {isLoggedIn && loginDetails.userRole === "SensrecAdmin" ? (
            <h3> Ansicht Sensrec Admin</h3>
          ) : null}
        </div>

        {/* the user name, clickable to open change password popup */}
        <div className="header3">
          {isLoggedIn && loginDetails.userRole === "Admin" ? (
            <p>
              Hallo,{" "}
              <button
                className="change-password-button"
                onClick={() =>
                  setChangePasswordPopup({
                    changePasswordPopup: true,
                    userId: loginDetails.userId,
                  })
                }
              >
                {loginDetails.firstName + " " + loginDetails.lastName}!
              </button>
            </p>
          ) : // check if the userRole is SensrecAdmin
          isLoggedIn && loginDetails.userRole === "SensrecAdmin" ? (
            <p>
              Hallo,{" "}
              <button
                className="change-password-button"
                onClick={() =>
                  setChangePasswordPopup({
                    changePasswordPopup: true,
                    userId: loginDetails.userId,
                  })
                }
              >
                {loginDetails.firstName + " " + loginDetails.lastName}!
              </button>
            </p>
          ) : (
            ""
          )}

          {/* Logout button */}
          {isLoggedIn && (
            <Link
              to={{ pathname: "/login" }}
              className="header-btn"
              onClick={logoutBtnHandler}
            >
              Abmelden
            </Link>
          )}

          {!isLoggedIn && location.pathname !== "/login" && (
            <Link
              to={{ pathname: "/login" }}
              className="header-btn"
              onClick={() => dispatch(setPresetProcess(""))}
            >
              Anmelden
            </Link>
          )}
        </div>
      </div>
      <hr className="header-hr" />
    </>
  );
};

export default Header;
