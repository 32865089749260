import "./YesNoFunction.css";
import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

// Actions
import { setMessage } from "../../../../Store/actions/messageAction";
import {
  setLoading,
  removeLoading,
} from "../../../../Store/actions/loadingAction";

// Services
import InstructionService from "../../../../Services/instructionService";

// Validations
import { FreeTextSchema } from "../../../../Validations/AdminValidations";

// Components:
import Loader from "../../../../Components/Loader/Loader";
import DeletePopup from "../../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "./../../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../../Components/MessageNotification/MessageNotification";

// Images:
import add from "../../../../Assets/Images/add.png";
import minus from "../../../../Assets/Images/minus.png";
import SavePopup from "../../../../Components/SavePopup/SavePopup";

export interface YesNoFunctionProps {
  subProcess: any;
}

const YesNoFunction: React.FC<YesNoFunctionProps> = (props) => {
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local states
  const [questions, setQuestion] = useState([
    {
      instructionID: "",
      name: "",
      order: "1" as any,
    },
  ]);

  //store saved yesno coming from DB in a state
  const [savedYesNo, setSavedYesNo] = useState<any>();
  //store saved questions coming from DB in a state
  const [savedQuestion, setSavedQuestion] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  //state for the delete popup"
  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    instructionID: "",
  });
  //create a new state for the reset button
  const [isDeleteInstructionPopup, setDeleteInstructionPopup] = useState({
    deletePopup: false,
    instructionIDs: "",
  });

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  // local state Yes No:
  const [yesNo, setYesNo] = useState({
    instructionID: "",
    title: "",
    description: "",
  });

  // state to know if the form is on POST or PUT:
  const [isAdd, setIsAdd] = useState(true);

  //state that stores the instructionIDs
  const [yesNoInstructionIDs, setYesNoInstructionIDs] = useState<any>();

  // get the subProcessFunctionID from the url:
  let params: any = useParams();
  const { id } = params;
  const subProcessFunctionID = id;

  // value to be sent to the backend to know if they chek for name duplicates or no
  const checkForNameDuplication = true;

  //function that add an empty sub-process to the state to be created when clicking on the + button:
  const handleAddFields = (index: any) => {
    setQuestion([
      ...questions,
      {
        instructionID: "",
        name: "",
        order: index + 2,
      },
    ]);
  };

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleRemoveFields = (index: any, id: any) => {
    if (id) {
      setDeletePopup({
        deletePopup: true,
        instructionID: id,
      });
    } else {
      const values = [...questions];
      values.splice(index, 1);
      //add a for loop to reorder the questions after removing any of them
      for (let i = 0; i < questions.length - 1; i++) {
        values[i].order = i + 1;
      }
      setQuestion(values);
    }

    if (questions.length === 1) {
      setQuestion([
        {
          instructionID: "",
          name: "",
          order: "1",
        },
      ]);
    }
  };

  // input change handler for questions:
  // use trim function to disable the user from adding a space
  const handleInputChange = (index: any, event: any) => {
    const values: any = [...questions];
    values[index][event.target.name] = event.target.value.trimStart();
    setQuestion(values);
    // disable the popup on the onChange
    setSavePopup({ savePopup: false });
    // setDisabled(false);
  };

  // handle the name input change:
  const handleQuestionsInputChange = (event: any) => {
    const { name, value } = event.target;
    setYesNo({ ...yesNo, [name]: value.trimStart() });
    // disable the popup on the onChange
    setSavePopup({ savePopup: false });
  };

  // we get the location and fill them in the dropdown, and if edit we get license by ID
  useEffect(() => {
    subProcessFunctionID &&
      getInstructionBySubProcessFunctionID(subProcessFunctionID);
    // eslint-disable-next-line
  }, [subProcessFunctionID]);

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number) => {
    dispatch(setLoading());
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response) => {
        setQuestion(response.data);
        //declare the instructionIDsArray 
        const instructionIDsArray = [];

        dispatch(removeLoading());
        setSavedQuestion(JSON.stringify(response.data));
        response.data.length > 0 ? setIsAdd(false) : setIsAdd(true);
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            // push the instructionIDs into a new array
            instructionIDsArray.push(response.data[i]?.instructionID);
            //store the instructionIDsArray into a local state
            setYesNoInstructionIDs(instructionIDsArray);

            setYesNo(() => {
              return {
                instructionID: response.data[0]?.instructionID
                  ? response.data[0]?.instructionID
                  : "",
                title: response.data[0]?.title ? response.data[0].title : "",
                description: response.data[0]?.description
                  ? response.data[0].description
                  : "",
              };
            });
            setSavedYesNo(() => {
              return {
                instructionID: response.data[0]?.instructionID
                  ? response.data[0]?.instructionID
                  : "",
                title: response.data[0]?.title ? response.data[0].title : "",
                description: response.data[0]?.description
                  ? response.data[0].description
                  : "",
              };
            });
          }
        } else {
          setQuestion([
            {
              instructionID: "",
              name: "",
              order: "1",
            },
          ]);

          setYesNo({
            instructionID: "",
            title: "",
            description: "",
          });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        // setDisabled(true);
        dispatch(removeLoading());
      }
    )
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAdd) {
      createQuestion(fields, setSubmitting);
    } else {
      updateQuestion(fields, setSubmitting);
      // }
    }
  }

  // create Yes No function:
  function createQuestion(fields: any, setSubmitting: any) {
    let { title, description } = fields;
    dispatch(setLoading());

    let questionsArray: any = [];

    for (let i = 0; i < questions.length; i++) {
      questionsArray.push({
        // instructionID: questions[i].instructionID,
        title: title,
        description: description,
        subProcessFunctionID: subProcessFunctionID,
        checkForNameDuplication,
        name: questions[i].name,
        order: questions[i].order,
        instructionItems: [],
      });
    }
    dispatch(setLoading());
    InstructionService.createInstruction(questionsArray)
      .then(
        (data: any) => {
          dispatch(removeLoading());
          dispatch(setMessage("Eingaben erfolgreich gespeichert.", "success"));
          subProcessFunctionID &&
            getInstructionBySubProcessFunctionID(subProcessFunctionID);
          // setDisabled(true);
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((e: any) => {
        console.log(e);
        dispatch(setMessage(e.message, "error"));
        dispatch(removeLoading());
      });
  }
  const [approved, setApproved] = useState(true);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };
  // set the timeout function inside a useEffect to prevent the delay 
  useEffect(() => {
    //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
    //call the setTimeout method to prevent the popup from appearing continuously
    setTimeout(() => CheckIfStateChanged(), 0);
  });
  const CheckIfStateChanged = () => {
    //compare states coming from DB with our states
    if (
      JSON.stringify(savedYesNo) === JSON.stringify(yesNo) &&
      JSON.stringify(questions) === savedQuestion
    ) {
      //both states are same
      setIsStateChanged(false);
    } else {
      //states are not equal
//disable the save button when there is no name 
      for (let i = 0; i < questions.length; i++) {
        // check for the title
        if (!questions[i].name || !yesNo.title) {
          setIsStateChanged(false);
        } else {
          if (questions[i].name || yesNo.title) {
            setIsStateChanged(true);
          }
        }
      }
    }
  };

  // update YesNo function:
  function updateQuestion(fields: any, setSubmitting: any) {
    const { title, description } = fields;
    dispatch(setLoading());

    let questionsArray: any = [];

    for (let i = 0; i < questions.length; i++) {
      questionsArray.push({
        instructionID: questions[i].instructionID
          ? questions[i].instructionID
          : null,
        title: title,
        description: description,
        checkForNameDuplication,
        subProcessFunctionID: subProcessFunctionID,
        name: questions[i].name,
        order: questions[i].order,
        instructionItems: [],
      });
    }

    for (let i = 0; i < questionsArray.length; i++) {
      setApproved(true);
      if (!questionsArray[i].name) {
        dispatch(setMessage("Bitte geben Sie eine Frage ein.", "error"));
        // setDisabled(true);
        dispatch(removeLoading());
        return setApproved(false);
      }
      dispatch(removeLoading());
    }
    if (approved) {
      dispatch(setLoading());

      InstructionService.updateInstruction(questionsArray)

        .then(
          (response: any) => {
            dispatch(
              setMessage("Eingaben erfolgreich gespeichert.", "success")
            );
            subProcessFunctionID &&
              getInstructionBySubProcessFunctionID(subProcessFunctionID);
            // setDisabled(true);
            dispatch(removeLoading());
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
            // setDisabled(true);
            dispatch(removeLoading());
          }
        )
        .catch((e: any) => {
          console.log(e);
          dispatch(setMessage(e.message, "error"));
          dispatch(removeLoading());
        });
      // }
    }
  }

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleDelete = (id: any) => {
    // we check if we have id so we show the popup else only we remove it from the local state
    if (id) {
      //call the setDeleteInstructionPopup state on the handleDelete
      setDeleteInstructionPopup({
        deletePopup: true,
        instructionIDs: id,
      });
    } else {
      setQuestion([
        {
          instructionID: "",
          name: "",
          order: "1",
        },
      ]);

      setYesNo({
        instructionID: "",
        title: "",
        description: "",
      });
    }
  };

  // function to delete an instruction
  const deleteInstruction = (id: any) => {
    dispatch(setLoading());
    InstructionService.deleteInstruction(id)
      .then((res) => {
        setDeletePopup({ deletePopup: false, instructionID: "" });
        dispatch(removeLoading());
        dispatch(setMessage("Eingaben erfolgreich gelöscht.", "success"));
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
      },(error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
            // setDisabled(true);
            dispatch(removeLoading());
          })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // API call to the deleteInstructions endpoint, it takes yesNoInstructionIDs as a param
  // and it deletes all the instructionsIDs sdded by the user
  const deleteInstructions = (yesNoInstructionIDs: any) => {
    dispatch(setLoading());
    InstructionService.deleteInstructions(yesNoInstructionIDs)
      .then(
        (res) => {
          setDeleteInstructionPopup({ deletePopup: false, instructionIDs: "" });
          dispatch(removeLoading());
          dispatch(setMessage("Eingaben erfolgreich gelöscht.", "success"));
          subProcessFunctionID &&
            getInstructionBySubProcessFunctionID(subProcessFunctionID);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // function to validate if a feild value exict or we show a required error message:
  const validateName = (value: any) => {
    let error;
    if (!value) {
      error = "Erforderlich";
    }
    return error;
  };

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("You are not an Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Sollen Ihre Eingaben zur Funktion Ja-Nein gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
            // to prevent the popup from appearing after saving
            setSavePopup({ savePopup: false });
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Sollen Ihre Eingaben wirklich gelöscht werden?"
          Delete={() => deleteInstruction(isDeletePopup.instructionID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, instructionID: "" })
          }
        />
      ) : null}
      {/* call the delete instructions popup to pass the deleteInstructions as an onclick event*/}
      {isDeleteInstructionPopup.deletePopup ? (
        <DeletePopup
          label="Sollen Ihre Eingaben wirklich gelöscht werden?"
          Delete={() => deleteInstructions(yesNoInstructionIDs)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, instructionID: "" })
          }
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div ref={formRef}>
            <Formik
              initialValues={yesNo}
              validationSchema={FreeTextSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                isValid,
              }) => {
                //store errors in a stated to send as prop for save pop up
                setTimeout(() => {
                  setValidationError(errors);
                }, 0);
                return (
                  <>
                    <div className="list-function-body">
                     
                      <h1>Ja-Nein</h1>
                      <Form>
                        <div className="yes-no-body">
                          <div className="first-table-yesno">
                            <table>
                              <tbody>
                                {questions.map((question, index) => (
                                  <tr key={index} className="col-2-2">
                                    <td className="create-sub-process-label-yesno">
                                      <h2>Frage:</h2>
                                    </td>
                                    <td className="instructionItem-name-input">
                                      <Field
                                        id="name"
                                        type="text"
                                        name="name"
                                        value={question.name}
                                        placeholder="Frage eingeben"
                                        onChange={(event: any) =>
                                          handleInputChange(index, event)
                                        }
                                        validate={() =>
                                          validateName(questions[index].name)
                                        }
                                      />
                                      {!questions[index].name && (
                                        <ErrorMessage
                                          name="name"
                                          component="div"
                                          className="new-license-error-message"
                                        />
                                      )}
                                    </td>

                                    {(questions[index].name &&
                                      questions[index].name.length !== 0) ||
                                    index !== 0 ? (
                                      <td>
                                        <img
                                          src={minus}
                                          className="icon-img-yesno"
                                          alt="remove"
                                          onClick={() =>
                                            handleRemoveFields(
                                              index,
                                              questions[index].instructionID
                                            )
                                          }
                                        />
                                      </td>
                                    ) : null}

                                    {questions.length === index + 1 &&
                                    questions[index].name.length !== 0 ? (
                                      <td>
                                        <img
                                          className="icon-img-yesno2"
                                          src={add}
                                          alt="add"
                                          onClick={() => handleAddFields(index)}
                                        />
                                      </td>
                                    ) : null}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="second-table-yesno">
                            <table className="function-table-yesno">
                              <tbody>
                                <tr>
                                  <td>
                                    <h2>Überschrift:</h2>
                                  </td>
                                  <td className="yesno-text">
                                    <Field
                                      name="title"
                                      value={yesNo.title}
                                      placeholder="Überschrift einfügen"
                                      onChange={handleQuestionsInputChange}
                                    />
                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="new-license-error-message"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="header-yesno">
                                    <h2>Anweisungen:</h2>
                                  </td>
                                  <td style={{ width: "20rem" }}>
                                    <Field
                                      as="textarea"
                                      rows="5"
                                      className="yesno-description"
                                      name="description"
                                      value={yesNo.description}
                                      placeholder="Anweisung einfügen"
                                      type="textarea"
                                      onChange={handleQuestionsInputChange}
                                    />
                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      className="new-license-error-message"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td style={{ width: "10rem" }}></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="yesno-button">
                            <DefaultButton
                              type="reset"
                              // pass the yesNoInstructionIDs to the handleDelete fn to remove all the form values
                              onClick={() => handleDelete(yesNoInstructionIDs)}
                            >
                              zurücksetzen
                            </DefaultButton>
                            {/* add !questions[0].name to check if there is a name field */}
                            <DefaultButton
                              disabled={
                                !isStateChanged ||
                                !yesNo.title 
                              }
                              type="submit"
                              id="clickSave"
                            >
                              Speichern
                            </DefaultButton>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default YesNoFunction;
