/* eslint-disable eqeqeq */
import "./FinalPage.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { setMessage } from "../../../Store/actions/messageAction";
import { resetJobParameter } from "../../../Store/actions/jobParameterAction";
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";

//Services
import JobService from "../../../Services/jobService";

// components:
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";

//Images
import edit from "../../../Assets/Images/edit.png";
import check from "../../../Assets/Images/check-tick.png";
import wrong from "../../../Assets/Images/red-cross-icon.png";
import correct from "../../../Assets/Images/green-check-icon.png";

//get setSubProcess as a prop
function FinalPage({
  subProcess,
  setSubProcess,
}: {
  subProcess: any;
  setSubProcess: any;
}) {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const job = useSelector((state: any) => state.JobReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const jobParametersArray = useSelector(
    (state: any) => state.JobParameterReducer
  );

  // Local State
  // popup state
  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
    isLogin: false,
  });

  const jobID = job.jobID;
  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  //call the finishJob api, remove the redux store of the jobParameter array
  const saveJob = () => {
    dispatch(setLoading());

    JobService.finishJob(jobID)
      .then((response: any) => {
        dispatch(removeLoading());
        dispatch(setMessage("Job finished successfully", "success"));
        history.push(`/sub-processes-overview/T/${subProcess.processID}`);
        setSubProcess(null);
        dispatch(resetJobParameter());
      })
      .catch((err: any) => {
        dispatch(removeLoading());
        dispatch(setMessage("Error", "error"));
        console.log("err", err);
      });
  };

  //call the terminate function api, remove the redux store of the jobParameter array
  const stopJob = () => {
    if (isSavePopup.isLogin === false) {
      history.push(`/sub-processes-overview/T/${subProcess.processID}`);
    } else {
      history.push("/login");
    }
    setSubProcess(null);
    dispatch(resetJobParameter());
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        if (e.target.tagName.toLowerCase() === "a") {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: true,
          });
        } else {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: false,
          });
        }
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Möchtest du aufhören?"
          Save={() => {
            stopJob();
            setSavePopup({ savePopup: false, isLogin: false });
          }}
          closeSavePopup={() =>
            setSavePopup({ savePopup: false, isLogin: false })
          }
          buttonText="Ja"
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <main>
          <section className="sub-process-function-name">
            <p>{subProcess.name}</p>
            <h3 className="sub-process-function-name-h3">
              Übersicht und Abgabe
            </h3>
          </section>

          <section className="user-final-page-body">
            {jobParametersArray?.map((jobParameter: any, index: any) => {
              const date = new Date(jobParameter.value);
              const month = date.getUTCMonth() + 1;
              const day = date.getUTCDate();
              const year = date.getUTCFullYear();

              const newDate = day + "." + month + "." + year;

              return (
                <div className="user-final-page-item">
                  {jobParameter.jobParameterName === "scan" ? (
                    <>
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />
                      <div className="user-final-page-image-container">
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}: {jobParameter.value}
                        </h4>
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "list" ? (
                    <>
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />
                      <div className="user-final-page-image-container">
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}:{" "}
                          {jobParameter.doneInstructionItems.map(
                            (items: string, index: string) => (
                              <span>{(index ? ", " : "") + items}</span>
                            )
                          )}
                        </h4>
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "freeText" ? (
                    <>
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />
                      <div className="user-final-page-image-container">
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}: {jobParameter.value}
                        </h4>
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "instruction" ? (
                    <>
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />
                      <div className="user-final-page-image-container">
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}: {jobParameter.value}
                        </h4>
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "signature" ? (
                    <>
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />
                      <div className="user-final-page-image-container">
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}:&nbsp;
                        </h4>
                        <img
                          className="final-page-img"
                          src={"data:image/jpeg;base64," + jobParameter.image}
                          alt={jobParameter.name}
                        />
                        {jobParameter.value}
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "Date" ? (
                    <>
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />
                      <div className="user-final-page-image-container">
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}:&nbsp;
                          {jobParameter.value ? newDate : null}
                        </h4>
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "number" ? (
                    <>
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />

                      <div className="user-final-page-image-container">
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}: {jobParameter.value}
                        </h4>
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "yesNo" ? (
                    <>
                      <div className="user-final-page-image-container">
                        <img
                          className="final-page-check-icon"
                          src={check}
                          alt="check-icon"
                          title="Done"
                        />
                        <h4 className="final-page-job-parameter-values">
                          {jobParameter.title}:&nbsp;
                        </h4>
                        <table>
                          <tbody className="count-table-final-page">
                            <tr>
                              <td>
                                {jobParameter?.name.map((item: any) => {
                                  return (
                                    <tr>
                                      <td className="count-table-final-page">
                                        <h4>{item}</h4>
                                      </td>
                                      <td className="count-table-final-page">
                                        <img
                                          className="final-page-edit-icon"
                                          src={
                                            jobParameter.yesInstruction.includes(
                                              item
                                            )
                                              ? correct
                                              : wrong
                                          }
                                          alt="unselected question"
                                          title=""
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <img
                          onClick={() => history.push(jobParameter.path)}
                          className="final-page-edit-icon"
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    </>
                  ) : jobParameter.jobParameterName === "Photo" ? (
                    <div className="user-final-page-image-container">
                      <img
                        className="final-page-check-icon"
                        src={check}
                        alt="check-icon"
                        title="Done"
                      />
                      <h4 className="final-page-job-parameter-values">
                        {jobParameter.title}:&nbsp;
                      </h4>
                      <img
                        className="final-page-img"
                        src={"data:image/jpeg;base64," + jobParameter.image}
                        alt={jobParameter.name}
                      />
                      <img
                        onClick={() => history.push(jobParameter.path)}
                        className="final-page-edit-icon"
                        src={edit}
                        alt="edit"
                      />
                    </div>
                  ) : (
                    jobParameter.map((e: any, index: any) => (
                      <>
                        {e.jobParameterName === "count" && index < 1 ? (
                          <>
                            <img
                              className="final-page-check-icon"
                              src={check}
                              alt="check-icon"
                              title="Done"
                            />
                            <h4 className="final-page-job-parameter-values">
                              {e?.title}:&nbsp;
                            </h4>
                            <table className="count-table-final-page">
                              {jobParameter.map((e: any) => {
                                const UnitID = e?.instructionUnits.filter(
                                  (countItem: any) =>
                                    countItem.unitID == e.unitID
                                );
                                return (
                                  <tbody className="count-table-final-page">
                                    <tr>
                                      <td className="count-table-final-page">
                                        <h5 className="table-count-user">
                                          {e.name}
                                        </h5>
                                      </td>

                                      {UnitID.map((o: any) => {
                                        return (
                                          <td className="count-table-final-page">
                                            <h5 className="table-count-user">
                                              {o.name}
                                            </h5>
                                          </td>
                                        );
                                      })}
                                      <td>
                                        <h5 className="table-count-user">
                                          {e.count}
                                        </h5>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                            <img
                              onClick={() => history.push(e.path)}
                              className="final-page-edit-icon"
                              src={edit}
                              alt="edit"
                            />
                          </>
                        ) : null}
                      </>
                    ))
                  )}
                </div>
              );
            })}
          </section>

          <section className="final-page-back-next-buttons">
            {/* <div className="final-page-back-next-buttons">
              <UserButton onClick={() => history.go(-1)}>zurück</UserButton>
            </div> */}
            <div className="final-page-done-button-note">
              <p>Hiermit bestätige Ich meine Eingaben:</p>
              <button className="final-page-done-button" onClick={saveJob}>
                abgeben
              </button>
            </div>
          </section>
        </main>
      )}
    </>
  );
}

export default FinalPage;
