import {
  CREATE_LOCATION_IP,
  DELETE_LOCATION_IP,
  UPDATE_LOCATION_IP,
  GET_LOCATIONS_IP,
} from "../actions/types";

// initiate the default state value = empty array:
const initialState = [] as any;

function LocationIPReducer(locationsIp = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LOCATION_IP:
      // return all existing locationIP and add the new one returned in the payload:
      return [...locationsIp, payload];

    case GET_LOCATIONS_IP:
      // return all the locationIP from DB:
      return payload;

    case UPDATE_LOCATION_IP:
      let temp = locationsIp;
      temp.map((item: any, index: any) => {
        if (item.locationIpID === payload.locationIpID) {
          return (temp[index] = payload);
        }
        return 0;
      });
      return temp;

    case DELETE_LOCATION_IP:
      return locationsIp.filter(
        ({ locationIpID }: { locationIpID: any }) => locationIpID !== payload
      );

    default:
      return locationsIp;
  }
}

export default LocationIPReducer;
