import "./ManageLicenses.css";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../Store/actions/messageAction";
import { Link } from "react-router-dom";

//components:
import LicenseList from "../LicenseList/LicenseList";
import Loader from "../../../Components/Loader/Loader";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
// import NewBackButtons from "./../../../Components/NewBackButtons/NewBackButtons";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

export interface ManageLicensesProps {}

const ManageLicenses: React.FC<ManageLicensesProps> = () => {
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  useEffect(() => {
    document.title = "JMS - Lizenzen verwalten";
  }, []);

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/" />;
  }

  return (
    <>
      <SensrecAdminNavbar />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="manage-licenses-body">
            <div className="title-btns">
              <h1>Lizenzen verwalten:</h1>
              <div className="topBtn">
                <Link to="/create-edit-license">
                  <DefaultButton>Neu Erstellen</DefaultButton>
                </Link>
              </div>
            </div>
            <div>
              <LicenseList />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManageLicenses;
