import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

// Admin
import OverviewPage from "./Pages/Admin/OverviewPage/OverviewPage";

import SystemSetting from "./Pages/Admin/SystemSetting/SystemSetting";
import ManageProcesses from "./Pages/Admin/ManageProcesses/ManageProcesses";
import CreateEditProcess from "./Pages/Admin/CreateEditProcess/CreateEditProcess";
import AssignFunctions from "./Pages/Admin/AssignFunctions/AssignFunctions";
import ProcessParameter from "./Pages/Admin/ProcessParameter/ProcessParameter";
import ManageSubProcesses from "./Pages/Admin/ManageSubProcesses/ManageSubProcesses";
// Add CreateEditIPAddress component
import CreateEditIPAddress from "./Pages/Admin/CreateEditIpAddress/CreateEditIpAddress";

//Sensrec Admin
import ManageUsers from "./Pages/SensrecAdmin/ManageUsers/ManageUsers";
import ManageLicenses from "./Pages/SensrecAdmin/ManageLicenses/ManageLicenses";
import ManageLocations from "./Pages/SensrecAdmin/ManageLocations/ManageLocations";
import ManageFunctions from "./Pages/SensrecAdmin/ManageFunctions/ManageFunctions";
import CreateEditFunction from "./Pages/SensrecAdmin/CreateEditFunction/CreateEditFunction";

import EditUser from "./Pages/SensrecAdmin/EditUser/EditUser";
import CreateUser from "./Pages/SensrecAdmin/CreateUser/CreateUser";
import CreateEditLicense from "./Pages/SensrecAdmin/CreateEditLicense/CreateEditLicense";
import CreateEditLocation from "./Pages/SensrecAdmin/CreateEditLocation/CreateEditLocation";

//User
import FinalPage from "./Pages/User/FinalPage/FinalPage";
import UserOverviewPage from "./Pages/User/UserOverviewPage/UserOverviewPage";
import SubProcessesOverview from "./Pages/User/SubProcessesOverview/SubProcessesOverview";

const App = () => {
  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  return (
    <BrowserRouter>
      {!isLoggedIn ? (
        <>
          <div className="App">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/" component={UserOverviewPage} />
              <Route
                exact
                path="/sub-processes-overview/:fun?/:id?/"
                component={SubProcessesOverview}
              />
              <Route exact path="/final-page/" component={FinalPage} />
              <Redirect to="/login" />
            </Switch>
          </div>
          {/* <Footer /> */}
        </>
      ) : user.userRole === "SensrecAdmin" ? (
        <>
          <Header />
          <div className="App">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/manage-locations"
                component={ManageLocations}
              />
              <Route exact path="/manage-licenses" component={ManageLicenses} />
              <Route exact path="/manage-users" component={ManageUsers} />
              <Route
                exact
                path="/manage-functions"
                component={ManageFunctions}
              />
              <Route
                exact
                path="/create-edit-location/:id?"
                component={CreateEditLocation}
              />
              <Route
                exact
                path="/create-edit-license/:id?"
                component={CreateEditLicense}
              />
              <Route
                exact
                path="/create-edit-function/:id?"
                component={CreateEditFunction}
              />
              <Route exact path="/create-user" component={CreateUser} />
              <Route exact path="/edit-user/:id" component={EditUser} />
              <Redirect to="/login" />
            </Switch>
          </div>
          <Footer />
        </>
      ) : (
        <>
          <Header />
          <div className="App">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/" component={OverviewPage} />
              <Route
                exact
                path="/manage-processes"
                component={ManageProcesses}
              />
              <Route
                exact
                path="/create-edit-process/:id?"
                component={CreateEditProcess}
              />
              <Route
                exact
                path="/manage-sub-processes"
                component={ManageSubProcesses}
              />
              <Route
                exact
                path="/assign-functions"
                component={AssignFunctions}
              />
              <Route
                exact
                path="/process-parameter/:fun?/:id?"
                component={ProcessParameter}
              />
              <Route exact path="/create-user" component={CreateUser} />
              <Route exact path="/edit-user/:id" component={EditUser} />
              {/* Add the route for the CreateEditIPAddress*/}
              <Route
                exact
                path="/create-edit-ip-address/:id?"
                component={CreateEditIPAddress}
              />
              <Route exact path="/system-setting" component={SystemSetting} />
              <Redirect to="/login" />
            </Switch>
          </div>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
