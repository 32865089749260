import "./CreateEditLicense.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

// services
import LicenseService from "../../../Services/licenseService";

// actions
import {
  createLicense,
  updateLicense,
} from "../../../Store/actions/licenseAction";
import { setMessage } from "../../../Store/actions/messageAction";
import { getAllLocations } from "../../../Store/actions/locationAction";

// validations
import { LicenseSchema } from "../../../Validations/SensrecAdminValidations";

// Components:
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import FormikControl from "../../../Components/FormikControl/FormikControl";

import de from "date-fns/locale/de";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import _ from "lodash";

registerLocale("de", de);
setDefaultLocale("de");

export interface CreateEditLicenseProps {
  props: any;
  match: any;
  locationID: any;
  validFrom: any;
  validTo: any;
  count: any;
}

const CreateEditLicense: React.FC<CreateEditLicenseProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const locations = useSelector((state: any) => state.LocationReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  // get the licenses global state from redux:
  const licenses = useSelector((state: any) => state.LicenseReducer);

  //local states
  // local state license:
  const [license, setLicense] = useState({
    locationID: "",
    validFrom: new Date(),
    validTo: new Date(),
    count: "",
  });

  //store saved License coming from DB in a state
  const [savedLicense, setSavedLicense] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  //get the id from url params:
  const { id } = props.match.params;

  // constant to know if the form is on creation or editing license:
  const isAddMode = !id;

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  // function that filter the location: to ignore locations that already have license to be in the list of create or edit license
  var filteredArray = locations.filter(function (location: any) {
    return (
      licenses.filter(function (license: any) {
        return location.locationID === license.location?.locationID;
      }).length === 0
    );
  });

  // if the form is on edit, so we get license data from DB:
  const getLicense = (id: number) => {
    LicenseService.getLicense(id)
      .then((response) => {
        setLicense(() => {
          return {
            locationID: response.data.location.locationID,
            validFrom: response.data.validFrom,
            validTo: response.data.validTo,
            count: response.data.count,
          };
        });
        setSavedLicense(() => {
          return {
            locationID: response.data.location.locationID,
            validFrom: response.data.validFrom,
            validTo: response.data.validTo,
            count: response.data.count,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // we get the location and fill them in the dropdown, and if edit we get license by ID
  useEffect(() => {
    dispatch(getAllLocations());
    !isAddMode && getLicense(props.match.params.id);
    document.title = "JMS - Lizenz bearbeiten";
  }, [dispatch, isAddMode, props.match.params.id]);

  // function that handle the select location drop down
  const handleDropDownChange = (event: any) => {
    const locationSelected = event.target.value;
    setLicense((previousState) => {
      return {
        ...previousState,
        locationID: locationSelected,
      };
    });
  };

  // function that handle the date valid from
  const handleDatesChange = (event: any) => {
    // const { name, value } = event.target;
    //add the time zone formula to solve the day before issue
    if (event) {
      event.setHours((-1 * event.getTimezoneOffset()) / 60);
    }
    setLicense((previousState) => {
      return {
        ...previousState,
        validFrom: event,
      };
    });
  };

  // function that handle the date valid to
  const handleDatesChange2 = (event: any) => {
    // const { name, value } = event.target;
    if (event) {
      event.setHours((-1 * event.getTimezoneOffset()) / 60);
    }
    setLicense((previousState) => {
      return {
        ...previousState,
        validTo: event,
      };
    });
  };

  // onSubmit general function that check if the form is create or edit and give the correct function:
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAddMode) {
      CreateLicense(fields, setSubmitting);
    } else {
      UpdateLicense(id, fields, setSubmitting);
    }
  }

  // create license function:
  function CreateLicense(fields: any, setSubmitting: any) {
    // (fields.count > 0
    //   ?
    dispatch(
      createLicense(
        fields.locationID,
        fields.validFrom,
        fields.validTo,
        fields.count
      )
    )
      //   : null
      // )
      .then((data: any) => {
        history.push("/manage-licenses");
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  // update license function:
  function UpdateLicense(id: number, fields: any, setSubmitting: any) {
    dispatch(
      updateLicense(
        id,
        fields.locationID,
        fields.validFrom,
        fields.validTo,
        fields.count
      )
    )
      .then((response: any) => {
        history.push("/manage-licenses");
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //get key values we need from the saved license and the changing license state  and store in same object format so we can compare
  let savedLicenseObj: any;
  let editedLicenseObj: any;
  if (savedLicense !== undefined)
    savedLicenseObj = {
      locationID: "" + savedLicense.locationID,
      validFrom: "" + savedLicense.validFrom,
      validTo: "" + savedLicense.validTo,
      count: "" + savedLicense.count,
    };
  if (license !== undefined)
    editedLicenseObj = {
      locationID: "" + license.locationID,
      validFrom: "" + license.validFrom,
      validTo: "" + license.validTo,
      count: "" + license.count,
    };

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);
  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (_.isEqual(savedLicenseObj, editedLicenseObj)) {
      //both states are same
      setIsStateChanged(false);
    } else {
      //states are not equal
      if (isAddMode && license.count === "" && license.locationID === "") {
        setIsStateChanged(false);
      } else if (
        isAddMode &&
        license.count !== "" &&
        license.locationID !== "" &&
        license.validFrom !== null &&
        license.validTo !== null
      ) {
        setIsStateChanged(true);
      } else if (!isAddMode) setIsStateChanged(true);
    }
  };

  // handle the name input change:
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setLicense({ ...license, [name]: value });
  };

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/" />;
  }

  //get saved location for the edited license
  let license_city: any, license_locationID: any, license_location: any;
  if (
    locations !== undefined &&
    locations.length > 0 &&
    filteredArray !== undefined &&
    filteredArray.length > 0
  ) {
    license_location = locations.filter(
      (item: any) =>
        item.locationID === license.locationID && !filteredArray.includes(item)
    );
  }

  if (license_location !== undefined && license_location.length > 0) {
    license_city = license_location[0].city;
    license_locationID = license_location[0].locationID;

    //then add this location to the filtered array to show in in dropdown
    filteredArray.push({
      locationID: license_locationID,
      city: license_city,
    });
  }

  return (
    <>
      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Soll die Lizenz gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      <SensrecAdminNavbar />
      <div className="create-license-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div ref={formRef}>
              <Formik
                initialValues={license}
                validationSchema={LicenseSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  dirty,
                }) => {
                  //store errors in a stated to send as prop for save pop up
                  setTimeout(() => {
                    setValidationError(errors);
                  }, 0);
                  return (
                    <>
                      <div className="title-btns">
                        {isAddMode ? (
                          <h1>Lizenz erstellen:</h1>
                        ) : (
                          <h1>Lizenz ändern:</h1>
                        )}
                        <div className="back-btn">
                          <DefaultButton onClick={() => history.goBack()}>
                            Zurück
                          </DefaultButton>
                        </div>
                      </div>
                      <Form className="create-license-form">
                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Standort <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <Field
                                  as="select"
                                  name="locationID"
                                  id="locationID"
                                  onChange={handleDropDownChange}
                                >
                                  <option value="">Standort wählen</option>

                                  {filteredArray
                                    ? filteredArray.map(
                                        (location: any, index: any) => (
                                          <option
                                            key={index}
                                            id={location.locationID}
                                            value={location.locationID}
                                          >
                                            {location.city}
                                          </option>
                                        )
                                      )
                                    : null}
                                </Field>
                                <ErrorMessage
                                  name="locationID"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Gültig ab{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <DatePicker
                                  name="validFrom"
                                  locale="de"
                                  //call the format function inside the date
                                  selected={
                                    license.validFrom
                                      ? new Date(license.validFrom)
                                      : null
                                  }
                                  onChange={handleDatesChange}
                                  dateFormat="dd.MM.yyyy"
                                  closeOnScroll={true}
                                  isClearable
                                  showYearDropdown
                                  scrollableMonthYearDropdown
                                />
                                <ErrorMessage
                                  name="validFrom"
                                  component="div"
                                  className="new-license-error-message"
                                />
                                {/* <p className="new-license-error-message">
                                Date cannot be in the past
                              </p> */}
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Gültig bis{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <DatePicker
                                  name="validTo"
                                  locale="de"
                                  //call the format function inside the date
                                  selected={
                                    license.validTo
                                      ? new Date(license.validTo)
                                   : null
                                  }
                                  onChange={handleDatesChange2}
                                  dateFormat="dd.MM.yyyy"
                                  closeOnScroll={true}
                                  isClearable
                                  showYearDropdown
                                  scrollableMonthYearDropdown
                                />

                                {errors.validTo ? (
                                  <div className="new-license-error-message">
                                    {" "}
                                    {errors.validTo}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* <p className="new-license-error-message">
                                Date cannot be in the past
                              </p> */}
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Anzahl <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="count"
                                  id="count"
                                  type="text"
                                  placeholder="Anzahl"
                                  value={license.count}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  (<span className="required-p">*</span>
                                  ):{" "}
                                  <span className="required-p">
                                    Erforderlich
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="save-cancel-btns">
                          <DefaultButton
                            disabled={!isStateChanged}
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                          <DefaultButton
                            onClick={() => history.push("/manage-licenses")}
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateEditLicense;
