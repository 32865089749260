import http from "./http-common";
import authHeader from "./authHeader";

const getLocationIp = (id: string) => {
  return http.get(`/LocationIp/${id}`, { headers: authHeader() });
};

const getAllLocationIP = () => {
  return http.get("/LocationIp", { headers: authHeader() });
};

const createLocationIP = (data: any) => {
  return http.post("/LocationIp", data, { headers: authHeader() });
};

const updateLocationIp = (id: string, data: any) => {
  return http.put(`/LocationIp/${id}`, data, { headers: authHeader() });
};

const deleteLocationIp = (id: string) => {
  return http.delete(`/LocationIp/${id}`, { headers: authHeader() });
};

const LocationService = {
  getLocationIp,
  getAllLocationIP,
  createLocationIP,
  updateLocationIp,
  deleteLocationIp,
};

export default LocationService;
