import "./ProcessPopup.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
// import _ from "lodash";

// Actions
import {
  createProcess,
  updateProcess,
} from "../../Store/actions/processAction";
import { setMessage } from "../../Store/actions/messageAction";

// Components
import Input from "../Input/Input";
import TrashIcon from "../TrashIcon/TrashIcon";
import DefaultButton from "../DefaultButton/DefaultButton";
import FormikControl from "../FormikControl/FormikControl";

// Images:
import image from "../../Assets/Images/image.png";
import { ProcessSchema } from "../../Validations/AdminValidations";
import ImageService from "../../Services/imageService";
import ProcessService from "../../Services/processService";
import { removeLoading, setLoading } from "../../Store/actions/loadingAction";

type ProcessPopupProps = {
  processID?: string;
  label: string;
  name?: string;
  image?: any;
  errors?: any;
  Save?: () => void;
  closeProcessPopup: any;
};

function ProcessPopup(props: ProcessPopupProps) {
  const history = useHistory<any>();
  const dispatch = useDispatch<any>();

  //local states
  const initialProcessState = {
    id: "",
    name: "",
    image: image,
  };

  const [showProcessPopup, setProcessPopup] = useState(true);
  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(
    props.image ? props.image : image
  );

  //process initial state for creation or update process:
  const [process, setProcess] = useState<any>(initialProcessState);

  //   const [error, setError] = useState(false);

  // onSubmit general function that check if the form is create or edit and give the correct function:
  function onSubmit(fields: any, imageID?: any) {
    if (!props.processID) {
      CreateProcess(fields);
    } else {
      UpdateProcess(props.processID, fields);
      !baseImage && deleteProcessImage(imageID);
    }
  }
  // use effect that run at the first render and if the id is changed to get the process by id
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    props.processID && GetProcess(props.processID, { signal: signal });

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [props.processID]);

  //get  process and show them from DB:
  const GetProcess = (id: string, signal: any) => {
    ProcessService.getProcess(id)
      .then((response) => {
        //set process from DB in the process state:
        setProcess(response.data);
        // setSavedProcess(response.data);
        response.data.imageID && getProcessImage(response.data.imageID);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // create process function:
  const CreateProcess = (fields: any) => {
    // e.preventDefault();
    const { name, image } = fields;
    // create data as specific typescript data type that support images in it:
    let formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    if (name !== undefined) {
      // dispatch the create process action
      dispatch(createProcess(formData))
        .then(
          (res: any) => {
            dispatch(
              setMessage("Der Prozess wurde erfolgreich geändert.", "success")
            );

            setProcessPopup(false);
            props.closeProcessPopup(false);
            history.push("/overview-page");
          },
          (error: any) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            dispatch(setMessage(message, "error"));
          }
        )
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const UpdateProcess = (id: any, fields: any) => {
    // e.preventDefault();
    const { name, image } = fields;
    // create data as specific typescript data type that support images in it:
    let formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    if (name !== undefined) {
      // API call
      dispatch(updateProcess(id, formData))
        .then(
          (res: any) => {
            dispatch(
              setMessage("Der Prozess wurde erfolgreich geändert.", "success")
            );

            setProcessPopup(false);
            props.closeProcessPopup(false);
            history.push("/overview-page");
          },
          (error: any) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            dispatch(setMessage(message, "error"));
          }
        )
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  };

  const deleteProcessImage = (imageID: string) => {
    dispatch(setLoading());

    ImageService.deleteImage(imageID)
      .then((response) => {
        //set process from DB in the process state:
        dispatch(removeLoading());
        setBaseImage(image);
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const toggleModal = () => {
    setProcessPopup(!showProcessPopup);
  };
  if (showProcessPopup) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const handleInputChange = (event: any) => {
    setProcess({
      ...process,
      name: event.target.value,
    });
  };

  //function to show the process image in the preview position if we are editing an existing process:
  const getProcessImage = (imageID: any) => {
    ImageService.getImage(imageID).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.png");
      document.body.appendChild(link);
      // link.click();
      setBaseImage(link);
    });
  };
  // check if we have error coming as prop from formik
  //   setTimeout(() => {
  //     if (
  //       _.has(props.errors, "title") ||
  //       _.has(props.errors, "name") ||
  //       _.has(props.errors, "description") ||
  //       _.has(props.errors, "validFrom") ||
  //       _.has(props.errors, "validTo") ||
  //       _.has(props.errors, "locationID") ||
  //       _.has(props.errors, "count") ||
  //       _.has(props.errors, "ip") ||
  //       _.has(props.errors, "city") ||
  //       _.has(props.errors, "code")
  //     ) {
  //       setError(true);
  //     } else {
  //       setError(false);
  //     }
  //   }, 0);
  return (
    <>
      <Formik
        initialValues={process}
        validationSchema={ProcessSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid, dirty, errors }) => {
          return (
            <>
              <Form>
                <div className="modal">
                  <div onClick={toggleModal} className="overlay"></div>
                  <div className="modal-content-process-popup">
                    <h2>{props.label}</h2>
                    <div className="process-popup-name-field-container">
                      <h3>Prozessname: </h3>
                      <FormikControl
                        control="input"
                        name="name"
                        id="name"
                        type="text"
                        value={values.name}
                        onChange={(e: any) => {
                          handleInputChange(e);
                          setFieldValue("name", e.target.value);
                        }}
                      />
                    </div>
                    <div className="process-popup-image-container">
                      <label className="image-input" htmlFor="image-input">
                        <img
                          src={baseImage ? baseImage : image}
                          alt="process-popup-img"
                          className="process-popup-image"
                        />
                        <Input
                          id="image-input"
                          type="file"
                          name="image"
                          accept="image/*"
                          placeholder="Durchsuchen.."
                          onClick={(e: any) => (e.target.value = null)}
                          // value=""
                          onChange={async (event: any) => {
                            setFieldValue("image", event.target.files[0]);
                            const base64 = await convertBase64(
                              event.target.files[0]
                            );
                            setBaseImage(base64);

                            setProcess({
                              ...process,
                              image: event.target.files[0],
                            });
                          }}
                        />
                      </label>
                      <TrashIcon
                        // id={process.imageID}
                        className="icon-trash-popup"
                        onClick={(event: any) => {
                          setFieldValue("image", "");
                          setBaseImage(null);
                          // deleteProcessImage(process.imageID);
                        }}
                      />
                    </div>

                    <div className="process-popup-btns">
                      <DefaultButton
                        className="my-button"
                        type="submit"
                        disabled={!isValid}
                        onClick={() => onSubmit("", process.imageID)}
                      >
                        Speichern
                      </DefaultButton>
                      <DefaultButton
                        onClick={props.closeProcessPopup}
                        className="my-button"
                      >
                        Abbrechen
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default ProcessPopup;
