import "./DefaultButton.css";

export interface DefaultButtonProps {
  type?: any;
  onClick?: any;
  disabled?: any;
  id?: any;
  className?: any;
}

const DefaultButton: React.FC<DefaultButtonProps> = (DefaultButtonProps) => {
  return (
    <button
      id={DefaultButtonProps.id}
      className={
        DefaultButtonProps.disabled ? "my-button disabled" : "my-button"
      }
      type={DefaultButtonProps.type}
      onClick={DefaultButtonProps.onClick}
      disabled={DefaultButtonProps.disabled}
    >
      {DefaultButtonProps.children}
    </button>
  );
};

export default DefaultButton;
