import "./ManageProcesses.css";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../Store/actions/messageAction";

//Components:
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import Loader from "../../../Components/Loader/Loader";
import ProcessTable from "../ProcessTable/ProcessTable";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

export interface ManageProcessesProps {}

const ManageProcesses: React.FC<ManageProcessesProps> = () => {
  const dispatch = useDispatch<any>();

  //redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      <AdminNavbar />

      <div className="create-processes-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="title-btns">
              <h1>Prozesse verwalten:</h1>
              <div className="topBtn">
                <Link to="/create-edit-process">
                  <DefaultButton>Neu Erstellen</DefaultButton>
                </Link>
              </div>
            </div>

            <div className="processes-table">
              <ProcessTable />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ManageProcesses;
