import http from "./http-common";
import authHeader from "./authHeader";

const getInstructionById = (id: number) => {
  return http.get(`/​Instruction/GetById/${id}`, {
    headers: authHeader(),
  });
};

const getInstructionNames = (code: any) => {
  return http.get(`/Instruction/GetInstructionNamesByFunctionCode/${code}`, {
    headers: authHeader(),
  });
};

const getInstructionBySubProcessFunctionID = (id: number) => {
  return http.get(`/Instruction/GetBySubProcessFunctionID/${id}`, {
    headers: authHeader(),
  });
};

const createInstruction = async (data: any) => {
  const res = await http.post("/Instruction", data, { headers: authHeader() });
  return res;
};

const updateInstruction = (data: any) => {
  return http.put("/Instruction/", data, { headers: authHeader() });
};

const deleteInstruction = (id: number) => {
  return http.delete(`/Instruction/${id}`, { headers: authHeader() });
};
// add the delete instructions endpoint to delete the reset the values to their default state
const deleteInstructions = (data:any) => {
  return http.delete('/Instruction/DeleteInstructions',{data, headers: authHeader()});
};
const InstructionService = {
  getInstructionById,
  getInstructionNames,
  getInstructionBySubProcessFunctionID,
  createInstruction,
  updateInstruction,
  deleteInstruction,
  deleteInstructions,
};

export default InstructionService;
