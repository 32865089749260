import "./User.css";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteUser } from "../../../Store/actions/userAction";

// components:
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

export interface UserProps {
  id: number;
  username: string;
  location: string;
  email: string;
  ip: string;
  validFrom: string;
  validTo: string;
  pathname: any;
}

const User: React.FC<UserProps> = ({
  id,
  username,
  location,
  email,
  ip,
  validFrom,
  validTo,
  pathname,
}) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  // local state
  const [isDeletePopup, setDeletePopup] = useState(false);

  // function to delete a user
  const DeleteUser = () => {
    dispatch(deleteUser(id))
      .then(() => {
        history.push("/manage-users");
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <>
      {isDeletePopup ? (
        <tr>
          <td>
            <DeletePopup
              label="Soll dieser Benutzer wirklich gelöscht werden?"
              Delete={DeleteUser}
              closeDeletePopup={() => setDeletePopup(false)}
            />
          </td>
        </tr>
      ) : null}
      <tr>
        <td className="my-td-label-user">
          <h3>{username}</h3>
        </td>
        <td className="my-td-label-user">
          <h3>{location}</h3>
        </td>
        <td className="my-td-label-user">
          <h3>{email}</h3>
        </td>
        <td className="my-td-label-user">
          <h3>{ip}</h3>
        </td>
        <td className="my-td-label-user">
          <h3>{moment.utc(validFrom).format("DD.MM.YYYY")}</h3>
        </td>
        <td className="my-td-label-user">
          <h3>{moment.utc(validTo).format("DD.MM.YYYY")}</h3>
        </td>
        <td className="my-td-label-user">
          <Link to={pathname + id}>
            <DefaultButton>Ändern</DefaultButton>
          </Link>
        </td>
        <td className="my-td-label-user">
          <DefaultButton onClick={() => setDeletePopup(true)}>
            Löschen
          </DefaultButton>
        </td>
      </tr>
    </>
  );
};

export default User;
