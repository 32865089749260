import "./AssignFunctions.css";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { DndProvider, useDrop } from "react-dnd";
import { ToolsDnd } from "../../../Utils/dndTypes";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector, useDispatch } from "react-redux";

// Services
import FunctionService from "../../../Services/functionService";
import SubProcessService from "../../../Services/subProcessService";

// Actions
import { setMessage } from "../../../Store/actions/messageAction";
import { getAllProcesses } from "../../../Store/actions/processAction";
import { setPresetProcess } from "../../../Store/actions/presetProcessAction";
import { setSubPresetProcess } from "../../../Store/actions/presetSubProcessAction";
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";

//Components:
import Tool from "../../../Components/Tool/Tool";
import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import TrashIcon from "../../../Components/TrashIcon/TrashIcon";
import AssignedFunction from "../AssignedFunction/AssignedFunction";
import DeletePopup from "./../../../Components/DeletePopup/DeletePopup";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

export interface AssignFunctionsProps {}

const AssignFunctions: React.FC<AssignFunctionsProps> = () => {
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const { presetProcessID } = useSelector(
    (state: any) => state.PresetProcessReducer
  );
  const { presetSubProcessID } = useSelector(
    (state: any) => state.PresetSubProcessReducer
  );
  const processes = useSelector((state: any) => state.ProcessReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  // local states
  const [functions, setFunctions] = useState<any>();
  const [subProcess, setSubProcess] = useState<any>();
  const [subProcesses, setSubProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState("");
  const [selectedSubProcess, setSelectedSubProcess] = useState("");

  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    subProcessFunctionAssignmentID: "",
  });

  // function that handle the Process drop down
  const handleProcessDropDownChange = (event: any) => {
    const selectedProcess = event.target.value;
    setSelectedProcess(selectedProcess);
    getSubProcessesByProcessID(selectedProcess);
    dispatch(
      setPresetProcess({
        presetProcessID: selectedProcess,
        presetProcessName: "",
      })
    );
    setSelectedSubProcess("");
    dispatch(setSubPresetProcess(""));
  };

  // after selecting a processes we get the sub-process of the selected process:
  const getSubProcessesByProcessID = (id: string) => {
    dispatch(setLoading());
    SubProcessService.getSubProcessesByProcessID(id)
      .then((response) => {
        if (response.data.length > 0) {
          setSubProcesses([]);
          setSubProcesses(response.data);
          dispatch(removeLoading());
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function that handle the sub processes drop down
  const handleSubProcessDropDownChange = (event: any) => {
    const selectedSubProcess = event.target.value;
    setSelectedSubProcess(selectedSubProcess);
    getSubProcessByID(selectedSubProcess);
    dispatch(
      setSubPresetProcess({
        presetSubProcessID: selectedSubProcess,
        presetSubProcessName: "",
      })
    );
  };

  // after selecting a subProcess we get the functions of the selected subProcess:
  const getSubProcessByID = (id: string) => {
    dispatch(setLoading());
    SubProcessService.getSubProcess(id)
      .then((response) => {
        setSubProcess(response.data);
        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // get all function to show them as tools
  const getAllFunctions = () => {
    dispatch(setLoading());
    FunctionService.getAllFunctions()
      .then((res) => {
        if (res.data.length > 0) {
          setFunctions(res.data);
        }
        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function that assign the dragged and dropped function to the sub process selected
  const assignFunctionToSubProcess = (
    subProcessID: any,
    functionID: number,
    order: number,
    name: string
  ) => {
    // check if the user select process and sub process
    if (
      (selectedProcess === "" && presetProcessID === "") ||
      (subProcessID === "" && presetSubProcessID === "")
    )
      return dispatch(
        setMessage(
          "Wählen Sie zunächst einen Prozess und einen Teilprozess aus.",
          "error"
        )
      );

    //API call
    dispatch(setLoading());
    SubProcessService.assignFunctionToSubProcess(subProcessID, {
      functionID,
      order,
    })
      .then((data) => {
        dispatch(
          getSubProcessByID(
            presetSubProcessID ? presetSubProcessID : subProcessID
          ),
          setMessage(name + " Function assigned successfully!", "success")
        );
        dispatch(removeLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // delete an assigned function to sub process
  const deleteSubProcessFunctionAssignment = (
    subProcessID: any,
    subProcessFunctionAssignmentID: any
  ) => {
    dispatch(setLoading());
    SubProcessService.deleteSubProcessFunctionAssignment(
      subProcessFunctionAssignmentID
    )
      .then((data) => {
        dispatch(
          setMessage("Die Funktion wurde erfolgreich gelöscht.", "success")
        );
        dispatch(removeLoading());
        getSubProcessByID(
          presetSubProcessID ? presetSubProcessID : subProcessID
        );
        setDeletePopup({
          deletePopup: false,
          subProcessFunctionAssignmentID: "",
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // use effect that run at the first render and when the presetProcessID and the presetSubProcessID are changed
  useEffect(
    () => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      presetProcessID && getSubProcessesByProcessID(presetProcessID);
      presetSubProcessID && getSubProcessByID(presetSubProcessID);

      dispatch(getAllProcesses(), { signal: signal });
      getAllFunctions();

      document.title = "JMS - Teilprozess verwalten";

      return function cleanup() {
        abortController.abort();
      };
    },
    // eslint-disable-next-line
    [presetProcessID, presetSubProcessID, dispatch]
  );

  // declare the droppable area
  const [{ isOver }, drop] = useDrop({
    accept: ToolsDnd.TOOL,

    // get from the draggable item the send data
    drop: (item: any, monitor) =>
      assignFunctionToSubProcess(
        presetSubProcessID ? presetSubProcessID : selectedSubProcess,
        item.functionID,
        item.order,
        item.title
      ),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // checking the user role to restrict some page
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("You are not an Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Soll diese Funktion wirklich gelöscht werden?"
          Delete={() =>
            deleteSubProcessFunctionAssignment(
              selectedSubProcess,
              isDeletePopup.subProcessFunctionAssignmentID
            )
          }
          closeDeletePopup={() =>
            setDeletePopup({
              deletePopup: false,
              subProcessFunctionAssignmentID: "",
            })
          }
        />
      ) : null}

      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      <AdminNavbar />

      <div className="edit-process-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h1>Teilprozess verwalten:</h1>
            <div className="edit-processes">
              <div className="col-1">
                <table className="subProcess-table">
                  <tbody>
                    <tr>
                      <td>
                        <h2>Prozess:</h2>
                      </td>
                      <td className="process-name-dropdown">
                        <select
                          name="items"
                          onChange={handleProcessDropDownChange}
                          value={
                            presetProcessID ? presetProcessID : selectedProcess
                          }
                        >
                          <option value="">Prozess wählen</option>
                          {processes
                            ? processes.map((Process: any, index: any) => (
                                <option
                                  key={index}
                                  id={Process.processID}
                                  value={Process.processID}
                                >
                                  {Process.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="selected-process-msg msg-color">
                  {!selectedProcess && !presetProcessID ? (
                    <h5>Prozess wählen</h5>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-2">
                <table className="subProcess-table">
                  <tbody>
                    <tr className="col-2-1">
                      <td>
                        <h2>Teilprozess:</h2>
                      </td>
                      <td className="process-name-dropdown">
                        <select
                          className="select"
                          name="items"
                          onChange={handleSubProcessDropDownChange}
                          value={
                            presetSubProcessID
                              ? presetSubProcessID
                              : selectedSubProcess
                          }
                        >
                          <option value="0">Teilprozess wählen</option>
                          {subProcesses
                            ? subProcesses.map((subProcess: any) => (
                                <option
                                  key={subProcess.subProcessID}
                                  id={subProcess.subProcessID}
                                  value={subProcess.subProcessID}
                                >
                                  {subProcess.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="selected-subprocess-msg msg-color">
                  {!selectedSubProcess && !presetSubProcessID ? (
                    <h5>Teilprozess wählen </h5>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <hr className="hr-dashed" />

            <DndProvider backend={HTML5Backend}>
              {/* add a condition to check whether we have a process and a subprocess or a  */}
              {(selectedProcess && selectedSubProcess) ||
              (presetProcessID && presetSubProcessID) ? (
                <div>
                  <table className="check-table">
                    <tbody>
                      <tr
                        className="inputs"
                        ref={drop}
                        style={{ backgroundColor: isOver ? "none" : "none" }}
                      >
                        {subProcess && subProcess.assignedFunctions
                          ? subProcess.assignedFunctions.map(
                              (assignedFunction: any, index: any) => {
                                // setFunctionOrder(index);
                                return (
                                  <td key={index}>
                                    <AssignedFunction
                                      imageID={assignedFunction.imageID}
                                      name={assignedFunction.name}
                                      order={index}
                                    />
                                  </td>
                                );
                              }
                            )
                          : null}

                        <td>
                          <Input></Input>
                        </td>
                      </tr>
                      <tr className="icons">
                        {subProcess && subProcess.assignedFunctions
                          ? subProcess.assignedFunctions.map(
                              (assignedFunction: any, index: any) => {
                                return (
                                  <td key={index}>
                                    <TrashIcon
                                      id={
                                        assignedFunction.subProcessFunctionAssignmentID
                                      }
                                      onClick={() =>
                                        setDeletePopup({
                                          deletePopup: true,
                                          subProcessFunctionAssignmentID:
                                            assignedFunction.subProcessFunctionAssignmentID,
                                        })
                                      }
                                    />
                                  </td>
                                );
                              }
                            )
                          : null}
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}
              <div className="toolbox">
                <h2>Prozessbausteine</h2>
                <table className="toolbox-table">
                  <tbody>
                    <tr>
                      {functions &&
                        functions.map((fun: any, index: any) => (
                          <td key={index}>
                            <Tool
                              functionID={fun.functionID}
                              imageID={fun.imageID}
                              title={fun.name}
                            />
                          </td>
                        ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </DndProvider>
          </>
        )}
      </div>
    </>
  );
};

export default AssignFunctions;
