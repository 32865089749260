import http from "./http-common";
import authHeader from "./authHeader";

const getProcess = (id: string) => {
  return http.get(`/Process/${id}`, { headers: authHeader() });
};

const getAllProcesses = () => {
  return http.get("/Process", { headers: authHeader() });
};

const createProcess = async (data: any) => {
  const res = await http.post("/Process", data, { headers: authHeader() });
  return res;
};

const updateProcess = (id: string, data: any) => {
  return http.put(`/Process/${id}`, data, { headers: authHeader() });
};

const deleteProcess = (id: string) => {
  return http.delete(`/Process/${id}`, { headers: authHeader() });
};

const ProcessService = {
  getProcess,
  getAllProcesses,
  createProcess,
  updateProcess,
  deleteProcess,
};

export default ProcessService;
