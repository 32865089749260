import "./Tool.css";
import { useDrag } from "react-dnd";
import { useState, useEffect } from "react";
import { ToolsDnd } from "../../Utils/dndTypes";
import ImageService from "../../Services/imageService";

export interface ToolProps {
  functionID: number;
  imageID: any;
  title: string;
}

const Tool: React.FC<ToolProps> = ({ functionID, imageID, title }) => {
  //the tool comp is draggable:
  const [{ isDragging }, drag] = useDrag({
    // we give it a type, mandatory from the library:
    type: ToolsDnd.TOOL,

    // we sent with the draggable item the following data:
    item: {
      type: ToolsDnd.TOOL,
      functionID: functionID,
      title: title,
      order: 1,
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  // state to save the base64 code to show images:
  const [baseImage, setBaseImage] = useState<any>();

  useEffect(() => {
    // call the getImage API and send the image ID of every process then we get a downloadable file
    // we convert it to a blob then to a anchor link and we render it in the DOM
    ImageService.getImage(imageID).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.png");
      document.body.appendChild(link);
      // link.click();
      setBaseImage(link);
    });

    return () => {
      setBaseImage("");
    };
  }, [imageID]);

  return (
    <div
      className="tool-card"
      ref={drag}
      style={{ opacity: isDragging ? "0.5" : "1" }}
    >
      <img src={baseImage} alt={title} />

      <h2>{title}</h2>
    </div>
  );
};

export default Tool;
