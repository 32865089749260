import { CREATE_LOCATION, GET_LOCATIONS } from "../actions/types";

// initiate the default state value = empty array:
const initialState = [] as any;

function LocationReducer(locations = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LOCATION:
      // return all existing location and add the new one return in the payload:
      return [...locations, payload];

    case GET_LOCATIONS:
      // return all returned location from DB:
      return payload;

    default:
      return locations;
  }
}

export default LocationReducer;
