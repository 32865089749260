import http from "./http-common";
import authHeader from "./authHeader";

const getLicense = (id:number) => {
  return http.get(`/License/${id}`, { headers: authHeader() });
};

const getAllLicenses = () => {
  return http.get("/License", { headers: authHeader() });
};

const createLicense = (data: any) => {
  return http.post("/License", data, { headers: authHeader() });
};

const updateLicense = (id: number, data: any) => {
  return http.put(`/License/${id}`, data, { headers: authHeader() });
};

const deleteLicense = (id: number) => {
  return http.delete(`/License/${id}`, { headers: authHeader() });
};

const LicenseService = {
  getLicense,
  getAllLicenses,
  createLicense,
  updateLicense,
  deleteLicense,
};

export default LicenseService;
