import React from "react";
import "./CountFunction.css";
import Select from "react-select";
import { useState, useEffect, useRef } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

// Services
import UnitService from "./../../../../Services/unitService";
import InstructionService from "../../../../Services/instructionService";

// Actions
import { setMessage } from "../../../../Store/actions/messageAction";
import {
  setLoading,
  removeLoading,
} from "../../../../Store/actions/loadingAction";

// validations
import { CountSchema } from "../../../../Validations/AdminValidations";

//Components:
import Loader from "../../../../Components/Loader/Loader";
import CountUnitPopup from "../../CountUnitPopup/CountUnitPopup";
import DeletePopup from "../../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "./../../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../../Components/MessageNotification/MessageNotification";

// Images:
import add from "../../../../Assets/Images/add.png";
import minus from "../../../../Assets/Images/minus.png";
import SavePopup from "../../../../Components/SavePopup/SavePopup";

function CountFunction() {
  const dispatch = useDispatch<any>();

  // redux state
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local states
  // state to know if the component is on create or update mode
  const [isAdd, setIsAdd] = useState(true);

  // descriptions state
  const [descriptions, setDescription] = useState([
    {
      instructionID: "",
      name: "",
      order: "1",
      instructionUnits: [] as any,
    },
  ]);

  // local state Count:
  const [count, setCount] = useState({
    instructionID: "",
    title: "",
    description: "",
  });

  //store saved count coming from DB in a state
  const [savedCount, setSavedCount] = useState<any>();
  //store saved description coming from DB in a state
  const [savedDescriptions, setSavedDescriptions] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if instruction is changed
  const [isInstructionHandle, setInstructionHandle] = useState(false);
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  // state to know if the manage Units popup is open or no
  const [isCountUnitPopup, setCountUnitPopup] = useState(false);

  // state where we save the count name already created in the DB
  // const [countNames, setCountName] = useState<any>();

  const [countNames, setCountNames] = useState<any>();

  // state where we save all units from DB
  const [units, setUnit] = useState([{ unitID: "", name: "" }]);

  // state for the selected units in the react select
  const [selectedUnits, setSelectedUnits] = useState<any>([]);

  //state for the delete popup
  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    instructionID: "",
  });

  //create a new state for the reset button
  const [isDeleteInstructionPopup, setDeleteInstructionPopup] = useState({
    deletePopup: false,
    instructionIDs: "",
  });

  // state for the new description button
  const [newDescriptionButtonState, setNewDescriptionButtonState] =
    useState(true);

  // state for the new description name
  const [newDescription, setNewDescription] = useState<any>();

  //state for the selected
  const [selectedInstruction, setSelectedInstruction] = useState("");

  // state that check if the posted data are approved to be sent to the DB or no
  const [approved, setApproved] = useState(true);

  const [countInstructionIDs, setCountInstructionIDs] = useState<any>();

  // here we convert the naming from react select defaults to options as we need the naming in our state and DB
  let options: any;
  //get function options
  if (units) {
    options = units.map(function (item: any) {
      return { value: item.unitID, label: item.name };
    });
  }

  // get the subProcessFunctionID from the url paramter
  let params: any = useParams();
  const { id } = params;
  const subProcessFunctionID = id;

  // variable to be sent to the backend to check for name duplicate
  const checkForNameDuplication = true;

  useEffect(
    () => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      GetAllUnits({ signal: signal });

      return function cleanup() {
        abortController.abort();
      };
    },
    // eslint-disable-next-line
    [isCountUnitPopup]
  );

  // function to get all units
  const GetAllUnits = (signal: any) => {
    dispatch(setLoading());
    UnitService.getAllUnits()
      .then(
        (res) => {
          setUnit(res.data);
          dispatch(removeLoading());
          // dispatch(setMessage("Eingaben erfolgreich gelöscht.", "success"));
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // function to get all count names from DB form the count instruction
  const getCountNames = () => {
      dispatch(setLoading());
      InstructionService.getInstructionNames("COUNT")
        .then((res) => {
          dispatch(removeLoading());
          let values = removeDuplicates(res.data, (item: any) => item.name);
          setCountNames(values);
        },(error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading());
        });
  }


  // function that filter the array base on name:
  function removeDuplicates(data: any, key: any) {
    return [...new Map(data?.map((item: any) => [key(item), item])).values()];
  }

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number) => {
    dispatch(setLoading());
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then(
        (response) => {
          setDescription(response.data);
          dispatch(removeLoading());
          setSavedDescriptions(response.data);
          response.data.length > 0 ? setIsAdd(false) : setIsAdd(true);
          //  create a new array to store the Count instructionIDs
          const countInstructionIDsArray = [];
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              //pusht he instructionIDs into the new array
              countInstructionIDsArray.push(response.data[i]?.instructionID);
              // store the new array into a local state
              setCountInstructionIDs(countInstructionIDsArray);

              setCount(() => {
                return {
                  instructionID: response.data[0]?.instructionID
                    ? response.data[0]?.instructionID
                    : "",
                  title: response.data[0]?.title ? response.data[0].title : "",
                  description: response.data[0]?.description
                    ? response.data[0].description
                    : "",
                };
              });
              setSavedCount(() => {
                return {
                  instructionID: response.data[0]?.instructionID
                    ? response.data[0]?.instructionID
                    : "",
                  title: response.data[0]?.title ? response.data[0].title : "",
                  description: response.data[0]?.description
                    ? response.data[0].description
                    : "",
                };
              });
            }
          } else {
            setDescription([
              {
                instructionID: "",
                name: "",
                order: "1",
                instructionUnits: [],
              },
            ]);

            setCount({
              instructionID: "",
              title: "",
              description: "",
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };
  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);
  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (
      JSON.stringify(savedCount) === JSON.stringify(count) &&
      JSON.stringify(descriptions) === JSON.stringify(savedDescriptions)
    ) {
      //both states are same
      if (isInstructionHandle) {
        if (selectedInstruction !== "") {
          setIsStateChanged(true);
        } else {
          setIsStateChanged(false);
        }
      } else {
        setIsStateChanged(false);
      }
    } else {
      //states are not equal
  // setIsStateChanged(true);

    if (selectedInstruction !== "" || count.title || count.description) {
      setIsStateChanged(true);
    } else {
        setIsStateChanged(false);
      
    }
    }

    if (newDescription && selectedUnits.length > 0) {
      setNewDescriptionButtonState(false);
    } else {
      setNewDescriptionButtonState(true);
    }
  };

  // we get the location and fill them in the dropdown, and if edit we get license by ID
  useEffect(() => {
    subProcessFunctionID &&
      getInstructionBySubProcessFunctionID(subProcessFunctionID);
    getCountNames();
    //When I added another count function the title and the description were the same as the previous one
    setCount({
      instructionID: "",
      title: "",
      description: "",
    });
   //call the setTimeout method to prevent the popup from appearing continuously 
    setTimeout(
      () =>
        setSavePopup({
          savePopup: false,
        }),
      0
    );
    // eslint-disable-next-line
  }, [id]);

  //function that add an empty sub-process to the state to be created when clicking on the + button:
  const handleAddFields = (index: any) => {
    setDescription([
      ...descriptions,
      {
        instructionID: "",
        name: "",
        order: index + 2,
        instructionUnits: [] as any,
      },
    ]);
  };

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleRemoveFields = (index: any, id: any) => {
    // we check if we have id so we show the popup else only we remove it from the local state
    setApproved(true);
    if (id) {
      setDeletePopup({
        deletePopup: true,
        instructionID: id,
      });
    } else {
      const values = [...descriptions];
      values.splice(index, 1);
      setDescription(values);
    }

    if (descriptions.length === 1) {
      setDescription([
        {
          instructionID: "",
          name: "",
          order: "1",
          instructionUnits: [] as any,
        },
      ]);
    }
  };

  //onChange function for the new description name
  const handleNewDescriptionNameChange = (event: any) => {
    setNewDescription(event.target.value.trimStart());
  };

  // onChange function for the units
  const handleUnitsSelect = (event: any) => {
    let values = Array.isArray(event) ? event.map((x: any) => x) : [];
    let options: any;
    if (values) {
      options = values.map((val: any) => {
        return { unitID: val.value, name: val.label };
      });
      setSelectedUnits(options);
    }
    if (newDescription && selectedUnits.length > 0) {
      setNewDescriptionButtonState(false);
    } else {
      setNewDescriptionButtonState(true);
    }
  };

  // function that handle the Add new description
  const addNewDescription = () => {
    // we are checking if the default added feilds is empty so we override it by the new one
    if (descriptions[0].name === "") {
      setDescription([
        {
          instructionID: "",
          name: newDescription,
          order: descriptions.length as any,
          instructionUnits: selectedUnits,
        },
      ]);

      dispatch(removeLoading());
    } else {
      // else we add new felid with old data
      setDescription([
        ...descriptions,
        {
          instructionID: "",
          name: newDescription,
          order: descriptions.length as any,
          instructionUnits: selectedUnits,
        },
      ]);

      dispatch(removeLoading());
    }

    //add the new description name to the count Names array
    countNames.push({
      name: newDescription,
      instructionUnits: selectedUnits,
    });

    // call the clearing function
    onClear();

    return setNewDescriptionButtonState(true);
  };

  // reference to the react select to empty it once we add the new description
  const selectInputRef: any = useRef();

  //function that clear the new description name and the react select after submit:
  const onClear = () => {
    if (selectInputRef !== undefined) {
      selectInputRef.current.select.clearValue();
    }
    setNewDescription("");
  };

  // function to handle the select description drop down
  const handleInstructionDropDownChange = (event: any, index: any) => {
    const selectedDescriptionName = event.target.value;
    setSelectedInstruction(event.target.value);
    const values = [...descriptions];
    values[index].name = selectedDescriptionName;
    let unitIDs: any;
    countNames.map((countName: any, index: any) => {
      if (countName.name === selectedDescriptionName) {
        unitIDs = countName.instructionUnits;
      }
      return unitIDs;
    });
    values[index].instructionUnits = unitIDs;
    setInstructionHandle(true);
    setDescription(values);

    if (selectedDescriptionName !== "") {
      setApproved(true);
    } else {
      setApproved(false);
    }
  };

  // on submit checking if the form is create or update
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAdd) {
      createCount(fields, setSubmitting);
      setInstructionHandle(false);
    } else {
      updateCount(fields, setSubmitting);
      setInstructionHandle(false);
    }
  }

  // create Count function:
  function createCount(fields: any, setSubmitting: any) {
    const { title, description } = fields;
    dispatch(setLoading());

    let descriptionsArray: any = [];

    for (let i = 0; i < descriptions.length; i++) {
      descriptionsArray.push({
        instructionID: descriptions[i].instructionID
          ? descriptions[i].instructionID
          : null,
        title: title,
        description: description,
        checkForNameDuplication,
        subProcessFunctionID: subProcessFunctionID,
        name: descriptions[i].name,
        order: descriptions[i].order,
        unitIDs: descriptions[i].instructionUnits.map(
          (instructionUnit: any) => instructionUnit.unitID
        ),
        instructionItems: [],
      });
    }

    for (let i = 0; i < descriptionsArray.length; i++) {
      setApproved(true);
      if (!descriptionsArray[i].name) {
        dispatch(
          setMessage("Der Name der Zählungs-Beschreibung fehlt.", "error")
        );
        // setDisabled(true);
        dispatch(removeLoading());
        return setApproved(false);
      }
      dispatch(removeLoading());
    }

    if (approved) {
      dispatch(setLoading());

      InstructionService.updateInstruction(descriptionsArray)

        .then(
          (response: any) => {
            dispatch(
              setMessage("Eingaben erfolgreich gespeichert.", "success")
            );
            subProcessFunctionID &&
              getInstructionBySubProcessFunctionID(subProcessFunctionID);
            dispatch(removeLoading());
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
            dispatch(removeLoading());
          }
        )
        .catch((e: any) => {
          console.log(e);
          dispatch(setMessage(e.message, "error"));
        });
    }
  }

  // update Count function:
  function updateCount(fields: any, setSubmitting: any) {
    setApproved(true);
    const { title, description } = fields;
    dispatch(setLoading());

    let descriptionsArray: any = [];

    for (let i = 0; i < descriptions.length; i++) {
      descriptionsArray.push({
        instructionID: descriptions[i].instructionID
          ? descriptions[i].instructionID
          : null,
        title: title,
        description: description,
        checkForNameDuplication,
        subProcessFunctionID: subProcessFunctionID,
        name: descriptions[i].name,
        order: descriptions[i].order,
        unitIDs: descriptions[i].instructionUnits.map(
          (instructionUnit: any) => instructionUnit.unitID
        ),
        instructionItems: [],
      });
    }

    for (let i = 0; i < descriptionsArray.length; i++) {
      setApproved(true);
      if (!descriptionsArray[i].name) {
        dispatch(
          setMessage("Der Name der Zählungs-Beschreibung fehlt.", "error")
        );
        dispatch(removeLoading());
        return setApproved(false);
      }
      dispatch(removeLoading());
    }

    if (approved) {
      InstructionService.updateInstruction(descriptionsArray)
        .then(
          (response: any) => {
            dispatch(
              setMessage("Eingaben erfolgreich gespeichert.", "success")
            );
            subProcessFunctionID &&
              getInstructionBySubProcessFunctionID(subProcessFunctionID);
            dispatch(removeLoading());
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
            setSavePopup({ savePopup: false });
            dispatch(removeLoading());
          }
        )
        .catch((e: any) => {
          console.log(e);
          dispatch(setMessage(e.message, "error"));
        });
    }
  }

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleDelete = (id: any) => {
    // we check if we have id so we show the popup else only we remove it from the local state
    if (id) {
      //call the setDeleteInstructionPopup to delete the instructionsIDs
      setDeleteInstructionPopup({
        deletePopup: true,
        instructionIDs: id,
      });
    } else {
      setDescription([
        {
          instructionID: "",
          name: "",
          order: "1",
          instructionUnits: [] as any,
        },
      ]);
      // local state FreeText:
      setCount({
        instructionID: "",
        title: "",
        description: "",
      });
    }
  };

  // function to delete a instruction
  const deleteInstruction = (id: any) => {
    dispatch(setLoading());
    InstructionService.deleteInstruction(id)
      .then(
        (res) => {
          setDeletePopup({ deletePopup: false, instructionID: "" });
          dispatch(removeLoading());
          dispatch(setMessage("Item gelöscht.", "success"));
          subProcessFunctionID &&
            getInstructionBySubProcessFunctionID(subProcessFunctionID);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          setSavePopup({ savePopup: false });
          dispatch(removeLoading());
        }
      )
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // fire an api call on the deleteInstructions API to remove all the instructionIds on the reset button
  const deleteInstructions = (countInstructionIDs: any) => {
    dispatch(setLoading());
    InstructionService.deleteInstructions(countInstructionIDs)
      .then((res) => {
        setDeleteInstructionPopup({ deletePopup: false, instructionIDs: "" });
        // remove the title and desc after clicking the reset button
        setCount({instructionID:"",title:"",description:""});
        dispatch(removeLoading());
        dispatch(setMessage("Eingaben erfolgreich gelöscht.", "success"));
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("You are not an Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  // handle the name input change:
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setCount({ ...count, [name]: value });
  };

  //store title input in local storage
  const count_title = document.getElementById("title") as HTMLInputElement;
  localStorage.setItem(
    "count-title",
    count_title && JSON.stringify(count_title.value)
  );

  //store description input in local storage
  const count_desc = document.getElementById("desc") as HTMLInputElement;
  localStorage.setItem(
    "count-desc",
    count_desc && JSON.stringify(count_desc.value)
  );

  // let validation_errors: any;

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Sollen Ihre Eingaben zur Funktion Zählung gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
            //to prevent the popup from showing up after saving any changes        
            setSavePopup({ savePopup: false });
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}

      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Sollen Ihre Eingaben wirklich gelöscht werden?"
          Delete={() => deleteInstruction(isDeletePopup.instructionID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, instructionID: "" })
          }
        />
      ) : null}
      {/* call the delete instructions popup to pass the deleteInstructions as an onclick event*/}
      {isDeleteInstructionPopup.deletePopup ? (
        <DeletePopup
          label="Sollen Ihre Eingaben wirklich gelöscht werden?"
          Delete={() => deleteInstructions(countInstructionIDs)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, instructionID: "" })
          }
        />
      ) : null}

      {isCountUnitPopup ? (
        <CountUnitPopup closeCountUnitPopup={() => setCountUnitPopup(false)} />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div ref={formRef}>
            <Formik
              initialValues={count}
              validationSchema={CountSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                //store errors in a stated to send as prop for save pop up
                setTimeout(() => {
                  setValidationError(errors);
                }, 0);
                return (
                  <>
                    <div className="evaluation-count">
                      <h1>Zählung</h1>
                      <Form className="count-body">
                        <div className="first-table-count">
                          <table className="function-table">
                            <tbody>
                              <tr>
                                <td>
                                  <h2>Beschreibungen:</h2>
                                </td>
                                <td>
                                  <h2 className="count-second-row">
                                    Transporteinheiten:
                                  </h2>
                                </td>
                              </tr>
                              {descriptions &&
                                descriptions.map((description, index) => (
                                  <tr key={index}>
                                    <td>
                                      <select
                                        className="select"
                                        name="name"
                                        onChange={(event) =>
                                          handleInstructionDropDownChange(
                                            event,
                                            index
                                          )
                                        }
                                        value={
                                          description.name
                                            ? description.name
                                            : newDescription
                                        }
                                      >
                                        <option value="">
                                          Beschreibung wählen
                                        </option>
                                        {countNames
                                          ? countNames.map(
                                              (countName: any, index: any) => (
                                                <option
                                                  key={index}
                                                  value={countName.name}
                                                >
                                                  {countName.name}
                                                </option>
                                              )
                                            )
                                          : null}
                                      </select>
                                      <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="new-license-error-message"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="count-second-row"
                                        type="text"
                                        readOnly
                                        value={
                                          description.instructionUnits &&
                                          description.instructionUnits.map(
                                            (unit: any, index: any) =>
                                              " " + unit.name
                                          )
                                        }
                                      />
                                    </td>
                                    {(descriptions[index].name &&
                                      descriptions[index].name?.length !== 0) ||
                                    index !== 0 ? (
                                      <td>
                                        <img
                                          src={minus}
                                          className="icon-img"
                                          alt="remove"
                                          onClick={() =>
                                            handleRemoveFields(
                                              index,
                                              descriptions[index].instructionID
                                            )
                                          }
                                        />
                                      </td>
                                    ) : null}

                                    {descriptions.length === index + 1 &&
                                    descriptions[index].name?.length !== 0 ? (
                                      <td>
                                        <img
                                          className="icon-img2"
                                          src={add}
                                          alt="add"
                                          onClick={() => handleAddFields(index)}
                                        />
                                      </td>
                                    ) : null}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <p>oder</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h2>Neues Item:</h2>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "15rem" }}>
                                  <Field
                                    name="newDescriptionName"
                                    value={newDescription}
                                    placeholder="Beschreibung eingeben"
                                    onChange={handleNewDescriptionNameChange}
                                    // validate={() =>
                                    //   validateNewDescription(newDescription)
                                    // }
                                  />
                                  <ErrorMessage
                                    name="newDescriptionName"
                                    component="div"
                                    className="new-license-error-message"
                                  />
                                </td>
                                {/* remove the style and add a className */}
                                <td className="count-unit-dropdown">
                                  <Select
                                    name="units"
                                    options={units && options}
                                    isMulti
                                    onChange={handleUnitsSelect}
                                    ref={selectInputRef}
                                    // value={selectedUnits}
                                  />
                                  <ErrorMessage
                                    name="units"
                                    component="div"
                                    className="new-location-error-message"
                                  />
                                </td>
                                <td>
                                  <DefaultButton
                                    type="button"
                                    onClick={addNewDescription}
                                    disabled={newDescriptionButtonState}
                                  >
                                    hinzufügen
                                  </DefaultButton>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="second-table-count">
                          <table>
                            <tbody>
                              <tr className="count-second-table-margin">
                                <td>
                                  <h2>Überschrift:</h2>
                                </td>
                                <td className="count-last-field">
                                  <Field
                                    id="title"
                                    name="title"
                                    value={
                                      JSON.parse(
                                        localStorage.getItem("count-title")!
                                      )
                                        ? JSON.parse(
                                            localStorage.getItem("count-title")!
                                          )
                                        : values.title
                                    }
                                    placeholder="Überschrift einfügen"
                                    onChange={handleInputChange}
                                  />
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="new-license-error-message"
                                  />
                                </td>
                              </tr>
                              <tr className="count-second-table-margin">
                                <td className="header-count">
                                  <h2>Anweisungen:</h2>
                                </td>
                                <td style={{ width: "20rem" }}>
                                  <Field
                                    as="textarea"
                                    rows="5"
                                    id="desc"
                                    className="count-last-field"
                                    name="description"
                                    value={
                                      JSON.parse(
                                        localStorage.getItem("count-desc")!
                                      )
                                        ? JSON.parse(
                                            localStorage.getItem("count-desc")!
                                          )
                                        : values.description
                                    }
                                    type="textarea"
                                    placeholder="Anweisung einfügen"
                                    onChange={handleInputChange}
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="new-license-error-message"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "10rem" }}></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="reset-btn-count">
                          <DefaultButton
                            type="reset"
                            onClick={() => {
                              // send countInstructionIDs to the handleDelete
                              handleDelete(countInstructionIDs);
                            }}
                            // onClick={() =>
                            //   setDeletePopup({
                            //     deletePopup: true,
                            //     instructionID: values.instructionID,
                            //     index: "1",
                            //   })
                            // }
                          >
                            zurücksetzen
                          </DefaultButton>
                          <DefaultButton
                            // disable the button until the three values are entered
                            // chaeck for the presence of the count names
                            disabled={
                              !isStateChanged || !countNames || !count.title
                            }
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                        </div>
                      </Form>
                      <div className="create-buttons">
                        <DefaultButton onClick={() => setCountUnitPopup(true)}>
                          Neue Transporteinheit
                        </DefaultButton>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
}

export default CountFunction;
