import "./Location.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { deleteLocation } from "../../../Store/actions/locationAction";

// components:
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import { setMessage } from "../../../Store/actions/messageAction";

export interface LocationProps {
  id?: number;
  index?: any;
  label?: string;
  pathname?: any;
  assignedFunctions?: any;
}

const Location: React.FC<LocationProps> = ({
  id,
  index,
  label,
  assignedFunctions,
  pathname,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // local state
  const [isDeletePopup, setDeletePopup] = useState(false);

  // delete location function:
  const DeleteLocation = () => {
    dispatch(deleteLocation(id!))
      .then(
        () => {
          history.push("/manage-locations");
             dispatch(
               setMessage("Der Standort wurde erfolgreich gelöscht.", "success")
             );
        },
        (error:any) => {
          // get error message from everywhere in the response:
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // show failed message:
          dispatch(setMessage(message, "error"));

  
     
        }
      )
      .catch((e: any) => {
        console.log(e);
      });
  };
  return (
    <>
      {isDeletePopup ? (
        <tr>
          <td>
            <DeletePopup
              label="Soll dieser Standort wirklich gelöscht werden?"
              Delete={DeleteLocation}
              closeDeletePopup={() => setDeletePopup(false)}
            />
          </td>
        </tr>
      ) : null}
      <tr key={id}>
        <td className="my-td-label-location">
          <h3>{label}</h3>
        </td>

        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "SCAN") {
                return (
                  <h2 key={index} style={{ textAlign: "center" }}>
                    X
                  </h2>
                );
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "LIST") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "FREETEXT") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "SIGNATURE") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "INSTRUCTION") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "DATE") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "NUMBER") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "YESNO") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "PHOTO") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>
        <td className="my-td-label-location">
          {assignedFunctions &&
            assignedFunctions.map((assignedFunction: any, index: any) => {
              if (assignedFunction.code === "COUNT") {
                return <h2 key={index}>X</h2>;
              }
              return null;
            })}
        </td>

        <td className="my-td-label-locations">
          <Link to={pathname + id}>
            <DefaultButton>Ändern</DefaultButton>
          </Link>
        </td>
        <td className="my-td-label-locations">
          <DefaultButton onClick={() => setDeletePopup(true)}>
            Löschen
          </DefaultButton>
        </td>
      </tr>
    </>
  );
};

export default Location;
