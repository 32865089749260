import "./DeletePopup.css";
import { useState } from "react";
import DefaultButton from "../DefaultButton/DefaultButton";

export default function DeletePopup({
  label,
  closeDeletePopup,
  Delete,
  buttonText,
}: {
  label: string;
  closeDeletePopup: () => void;
  Delete: () => void;
  buttonText?: string;
}) {
  const [deletePopup, setDeletePopup] = useState(true);

  const toggleModal = () => {
    setDeletePopup(!deletePopup);
  };

  if (deletePopup) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      <div className="modal">
        <div onClick={toggleModal} className="overlay"></div>
        <div className="modal-content">
          <h2>{label}</h2>
          <div className="popup-btns">
            <DefaultButton onClick={Delete}>
              {buttonText ? buttonText : "Löschen"}
            </DefaultButton>
            <DefaultButton onClick={closeDeletePopup}>Abbrechen</DefaultButton>
          </div>
        </div>
      </div>
    </>
  );
}
