import "./AdminNavbar.css";
import { NavLink, useHistory } from "react-router-dom";

export interface AdminNavbarProps {
  subProcess?: any;
}

const AdminNavbar: React.FC<AdminNavbarProps> = ({ subProcess }) => {

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();
  return (
    <div className="navbar">
      <ul className="my-ul">
        {/* <NavLink
          className={
            // we check if the url contain or includes the names to change style:
            currentRoute.includes("create-edit-process") ||
              currentRoute.includes("manage-processes")
              ? "my-li-active my-is-active"
              : "my-li"
          }
          activeClassName="my-is-active"
          to={{ pathname: "/manage-processes" }}
        >
          <li>Prozesse</li>
        </NavLink> */}

        <NavLink
          id="manage-sub-processes"
          className={
            currentRoute.includes("manage-sub-processes")
              ? "my-li-active"
              : "my-li"
          }
          activeClassName="my-is-active"
          to={{ pathname: "/manage-sub-processes" }}
        >
          <li>Teilprozesse</li>
        </NavLink>

        <NavLink
          className={
            currentRoute.includes("assign-function") ? "my-li-active" : "my-li"
          }
          activeClassName="my-is-active"
          to={{ pathname: "/assign-functions" }}
        >
          <li>Teilprozesse anpassen</li>
        </NavLink>

        <NavLink
          className={
            currentRoute.includes("process-parameter")
              ? "my-li-active"
              : "my-li"
          }
          activeClassName="my-is-active"
          to={{ pathname: "/process-parameter" }}
        // to={{
        //   pathname:
        //     "/process-parameter/" +
        //     subProcess?.assignedFunctions[0]?.name +
        //     "/" +
        //     subProcess?.assignedFunctions[0]?.subProcessFunctionAssignmentID,
        // }}
        >
          <li>Prozessparameter</li>
        </NavLink>

        <NavLink
          className={
            currentRoute.includes("system-setting") ? "my-li-active" : "my-li"
          }
          activeClassName="my-is-active"
          to={{ pathname: "/system-setting" }}
        >
          <li>Systemeinstellung</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default AdminNavbar;
