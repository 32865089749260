export const ToolsDnd = {
  TOOL: "tool",
};

export const subProcessDnd = {
  ORDER: "order",
};

const DndTypes = {
  ToolsDnd,
  subProcessDnd,
};

export default DndTypes;
