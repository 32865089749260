import {
  CREATE_USER,
  GET_USERS,
  GET_ONE_USER,
  // UPDATE_USER,
  DELETE_USER,
} from "./types";

import UserService from "../../Services/userService";
import { setMessage } from "../actions/messageAction";
import { setLoading, removeLoading } from "../actions/loadingAction";

export const CreateUserAction =
  (
    firstname: any,
    lastname: any,
    email: any,
    password: any,
    ip: any,
    locationID: any,
    licenseValidFrom: any,
    licenseValidTo: any,
    roles: any
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading());

      await UserService.createUser({
        firstname,
        lastname,
        email,
        password,
        ip,
        locationID,
        licenseValidFrom,
        licenseValidTo,
        roles,
      }).then(
        (data) => {
          dispatch({
            type: CREATE_USER,
            payload: data,
          });

          dispatch(removeLoading());
          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(removeLoading());
          return Promise.reject(message);
        }
      );
    } catch (err) {
      dispatch(setMessage(err, "error"));
      dispatch(removeLoading());
      return Promise.reject(err);
    }
  };

export const getAllUsers = () => async (dispatch: any) => {
  try {
    // dispatch(setLoading());

    const res = await UserService.getAllUsers();

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
    dispatch(removeLoading());

    return Promise.resolve(res.data);
  } catch (err: any) {
    console.log(err);
    dispatch(setMessage(err.message, "error"));
    dispatch(removeLoading());
  }
};

export const updateUser =
  (id: any, ipAddress: any, locationID: any, licenseValidTo: any) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading());

      await UserService.updateUser(id, {
        ipAddress,
        locationID,
        licenseValidTo,
      }).then(
        (data) => {
          dispatch(removeLoading());
          dispatch(setMessage("Der Benutzer wurde erfolgreich geändert.", "success"));
          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
          return Promise.reject();
        }
      );
    } catch (err) {
      dispatch(setMessage(err, "error"));
      dispatch(removeLoading());
      return Promise.reject(err);
    }
  };

export const getUser = (title: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    const res = await UserService.getUser(title);

    dispatch({
      type: GET_ONE_USER,
      payload: res.data,
    });

    dispatch(removeLoading());
  } catch (err: any) {
    console.log(err);
    dispatch(setMessage(err.message, "error"));
    dispatch(removeLoading());
  }
};

export const deleteUser = (id: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    await UserService.deleteUser(id).then(
      (data) => {
        dispatch({
          type: DELETE_USER,
          payload: { id },
        });

        dispatch(removeLoading());
        dispatch(setMessage("Der Benutzer wurde erfolgreich gelöscht.", "success"));
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject();
      }
    );
  } catch (err: any) {
    console.log(err);
    dispatch(setMessage(err.message, "error"));
    dispatch(removeLoading());
  }
};
