import {
  CREATE_LICENSE,
  GET_LICENSES,
  GET_ONE_LICENSE,
  UPDATE_LICENSE,
  DELETE_LICENSE,
} from "./types";

import LicenseService from "../../Services/licenseService";
import { setMessage } from "../actions/messageAction";
import { setLoading, removeLoading } from "../actions/loadingAction";

export const createLicense =
  (locationID: number, validFrom: any, validTo: any, count: any) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading());

      await LicenseService.createLicense({
        locationID,
        validFrom,
        validTo,
        count,
      }).then(
        (data) => {
          dispatch({
            type: CREATE_LICENSE,
            payload: data,
          });
          dispatch(removeLoading());
          dispatch(setMessage("Die Lizenz wurde erfolgreich erstellt.", "success"));
          // return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
          // return Promise.reject();
        }
      );
    } catch (err) {
      dispatch(setMessage(err, "error"));

      // return Promise.reject(err);
    }
  };

export const getAllLicenses = () => async (dispatch: any) => {
  try {
    // dispatch(setLoading());

    const res = await LicenseService.getAllLicenses();

    dispatch({
      type: GET_LICENSES,
      payload: res.data,
    });
    dispatch(removeLoading());

    return Promise.resolve(res.data);
  } catch (err: any) {
    dispatch(setMessage(err.message, "error"));
    dispatch(removeLoading());
    console.log(err);
  }
};

export const updateLicense =
  (id: number, locationID: number, validFrom: any, validTo: any, count: any) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading());
      const res = await LicenseService.updateLicense(id, {
        locationID,
        validFrom,
        validTo,
        count,
      }).then(
        (data) => {
          dispatch({
            type: UPDATE_LICENSE,
            payload: data,
          });

          dispatch(removeLoading());
          dispatch(setMessage("Die Lizenz wurde erfolgreich geändert.", "success"));
          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
          return Promise.reject();
        }
      );
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getLicense = (title: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    const res = await LicenseService.getLicense(title);

    dispatch({
      type: GET_ONE_LICENSE,
      payload: res.data,
    });
    dispatch(removeLoading());
  } catch (err) {
    console.log(err);
  }
};

export const deleteLicense = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    await LicenseService.deleteLicense(id).then(
      (data) => {
        dispatch({
          type: DELETE_LICENSE,
          payload: { id },
        });
        dispatch(removeLoading());
        dispatch(setMessage("Die Lizenz wurde erfolgreich gelöscht", "success"));
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject();
      }
    );
  } catch (err) {
    console.log(err);
  }
};
