import "./FreeText.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

// Action
import { setMessage } from "../../../../Store/actions/messageAction";
import {
  setLoading,
  removeLoading,
} from "../../../../Store/actions/loadingAction";
// Service
import InstructionService from "../../../../Services/instructionService";
// Validation
import { FreeTextSchema } from "../../../../Validations/AdminValidations";
//Components:
import Loader from "../../../../Components/Loader/Loader";
import DeletePopup from "../../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "./../../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../../Components/MessageNotification/MessageNotification";
import SavePopup from "../../../../Components/SavePopup/SavePopup";
import FormikControl from "../../../../Components/FormikControl/FormikControl";

export interface FreeTextProps {
  subProcess: any;
}

const FreeText: React.FC<FreeTextProps> = (props) => {
  const dispatch = useDispatch<any>();

  //redux state
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local state
  // constant to check if the form is create or update:
  const [isAdd, setIsAdd] = useState(true);

  //store saved free text coming from DB in a state
  const [savedFreeText, setSavedFreeText] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    instructionID: "",
  });

  // local state FreeText:
  const [freeText, setFreeText] = useState({
    instructionID: "",
    title: "",
    description: "",
  });

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { id } = params;
  const subProcessFunctionID = id;

  // constant we send to the backend to check for name duplicates or no:
  const checkForNameDuplication = false;

  useEffect(
    () => {
      subProcessFunctionID &&
        getInstructionBySubProcessFunctionID(subProcessFunctionID);
    },
    // eslint-disable-next-line
    [subProcessFunctionID]
  );

  // we get the instruction according to the subProcessFunction id:
  const getInstructionBySubProcessFunctionID = (id: number) => {
    dispatch(setLoading());
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response) => {
        response.data.length > 0 ? setIsAdd(false) : setIsAdd(true);
        dispatch(removeLoading());
        setFreeText(() => {
          return {
            instructionID: response.data[0]?.instructionID
              ? response.data[0]?.instructionID
              : "",
            title: response.data[0]?.title ? response.data[0].title : "",
            description: response.data[0]?.description
              ? response.data[0].description
              : "",
          };
        });
        setSavedFreeText(() => {
          return {
            instructionID: response.data[0]?.instructionID
              ? response.data[0]?.instructionID
              : "",
            title: response.data[0]?.title ? response.data[0].title : "",
            description: response.data[0]?.description
              ? response.data[0].description
              : "",
          };
        });
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    //call the setTimeout method to prevent the popup from appearing continuously
    setTimeout(
      () =>
        setSavePopup({
          savePopup: false,
        }),
      0
    );
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);
  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (JSON.stringify(savedFreeText) === JSON.stringify(freeText)) {
      //both states are same
      setIsStateChanged(false);
    } else {
      //states are not equal
      setIsStateChanged(true);
    }
  };

  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAdd) {
      createFreeText(fields, setSubmitting);
    } else {
      updateFreeText(fields, setSubmitting);
    }
  }

  // create FreeText function:
  function createFreeText(fields: any, setSubmitting: any) {
    const { title, description } = fields;
    dispatch(setLoading());
    InstructionService.createInstruction([
      {
        title,
        description,
        subProcessFunctionID,
        checkForNameDuplication,
        instructionItems: [],
      },
    ])
      .then((data: any) => {
        dispatch(removeLoading());
        dispatch(setMessage("Eingaben erfolgreich gespeichert.", "success"));
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
      })
      .catch((e: any) => {
        console.log(e);
        dispatch(removeLoading());
      });
  }

  // handle the name input change:
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFreeText({ ...freeText, [name]: value.trimStart() });
    // disable the popup on the onChange
    setSavePopup({
      savePopup: false,
    });
  };

  // update FreeText function:
  function updateFreeText(fields: any, setSubmitting: any) {
    const { instructionID, title, description } = fields;
    dispatch(setLoading());
    InstructionService.updateInstruction([
      {
        instructionID,
        title,
        description,
        subProcessFunctionID,
        checkForNameDuplication,
        instructionItems: [],
      },
    ])

      .then((response: any) => {
        dispatch(setMessage("Eingaben erfolgreich gespeichert.", "success"));
        dispatch(removeLoading());
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
      })
      .catch((e: any) => {
        console.log(e);
        dispatch(removeLoading());
      });
  }

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleDelete = (id: any) => {
    // we check if we have id so we show the popup else only we remove it from the local state
    if (id) {
      setDeletePopup({
        deletePopup: true,
        instructionID: id,
      });
    } else {
      setFreeText({
        instructionID: "",
        title: "",
        description: "",
      });
    }
  };

  //delete freeText
  const deleteInstruction = (id: any) => {
    dispatch(setLoading());
    InstructionService.deleteInstruction(id)
      .then((res) => {
        setDeletePopup({ deletePopup: false, instructionID: "" });
        dispatch(removeLoading());
        dispatch(setMessage("Eingaben erfolgreich gelöscht.", "success"));
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("You are not an Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Sollen Ihre Eingaben zur Funktion Freitext gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
            setSavePopup({ savePopup: false });
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Sollen Ihre Eingaben wirklich gelöscht werden?"
          Delete={() => deleteInstruction(isDeletePopup.instructionID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, instructionID: "" })
          }
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div ref={formRef}>
            <Formik
              initialValues={freeText}
              validationSchema={FreeTextSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                //store errors in a stated to send as prop for save pop up
                setTimeout(() => {
                  setValidationError(errors);
                }, 0);
                return (
                  <>
                    <div className="evaluation-freetext">
                  
                      <h1>Freitext</h1>
                      <Form>
                        <table className="function-table-freetext">
                          <tbody>
                            <tr>
                              <td>
                                <h2>Überschrift:</h2>
                              </td>
                              <td
                                className="large-textbox"
                                style={{ width: "83%" }}
                              >
                                <FormikControl
                                  control="input"
                                  name="title"
                                  id="title"
                                  placeholder="Überschrift einfügen"
                                  value={freeText.title}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="header-freetext">
                                <h2>Anweisungen:</h2>
                              </td>
                              <td style={{ width: "20rem" }}>
                                <Field
                                  as="textarea"
                                  rows="5"
                                  className="freetext-description"
                                  name="description"
                                  value={freeText.description}
                                  placeholder="Anweisung einfügen"
                                  type="textarea"
                                  onChange={handleInputChange}
                                />
                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "10rem" }}></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="reset-btn-freetext">
                          <DefaultButton
                            type="reset"
                            onClick={() => handleDelete(values.instructionID)}
                            // onClick={() =>
                            //   setDeletePopup({
                            //     deletePopup: true,
                            //     instructionID: values.instructionID,
                            //   })
                            // }
                          >
                            zurücksetzen
                          </DefaultButton>
                          <DefaultButton
                            disabled={
                              !isStateChanged ||
                              !freeText.title 
                            }
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                        </div>
                      </Form>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default FreeText;
