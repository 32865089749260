import { CREATE_USER, GET_USERS, DELETE_USER } from "../actions/types";

const initialState = [] as any;

function UserReducer(users = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_USER:
      return [...users, payload];

    case GET_USERS:
      return payload;

    case DELETE_USER:
      return users.filter(({ id }: { id: any }) => id !== payload.userID);

    default:
      return users;
  }
}

export default UserReducer;
