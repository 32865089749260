import { SET_JOB, REMOVE_JOB } from "../actions/types";

const initialState = { jobID: "" };

const JobReducer = (job = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_JOB:
      return payload;

    case REMOVE_JOB:
      return (job.jobID = "");

    default:
      return job;
  }
};

export default JobReducer;
