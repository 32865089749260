/* eslint-disable eqeqeq */
import "./BarcodeScanner.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// services
// import JobService from "../../../Services/jobService";
import InstructionService from "../../../Services/instructionService";
import JobParameterService from "../../../Services/jobParameterService";

// actions
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import {
  addJobParameter,
  resetJobParameter,
  updateJobParameterRedux,
} from "../../../Store/actions/jobParameterAction";
import { setMessage } from "../../../Store/actions/messageAction";

// components:
import Scanner from "./Scanner";
import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import UserButton from "../../../Components/UserButton/UserButton";

//add setSubProcess as a prop
function BarcodeScanner({
  subProcess,
  setSubProcess,
}: {
  subProcess: any;
  setSubProcess: any;
}) {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const job = useSelector((state: any) => state.JobReducer);

  const { loading } = useSelector((state: any) => state.LoadingReducer);
  // the redux array that store the finished instructions
  const jobParametersArray = useSelector(
    (state: any) => state.JobParameterReducer
  );

  // get the jobID from redux state
  const jobID = job.jobID;

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  //local state:
  const [camera, setCamera] = useState(false);
  //the barcode number reader by the reader or even wrote by the user in the input
  const [value, setValue] = useState<any>();
  // if we are in update mode this will be the job parameter ID
  const [jobParameterID, setJobParameterID] = useState<any>();
  // this is the initial instruction for example the title and the description of the scan instruction (function = instruction here)
  const [scan, setScan] = useState<any>("");
  // state to know if we are in the last function in the assigned functions main array
  const [isLastFunction, setLastFunction] = useState<any>(false);
  // nextFunction index in the assigned functions main array
  const [nextFunction, setNextFunction] = useState<any>("");
  //add savePopup state
  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
    isLogin: false,
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  //get the id from the param
  const { fun, id } = params;
  const code = fun.toUpperCase();

  // function that manage the next function indexing
  const nextFunctionFun = () => {
    // map over the assigned functions to the selected sub process
    subProcess?.assignedFunctions?.map((subProcessData: any, index: any) => {
      // check to see the current function we are in where it is in the assigned functions array
      if (
        subProcessData.code === code &&
        subProcessData.subProcessFunctionAssignmentID == id
      ) {
        // when we find our function, we check if it is the last one in the array
        if (index === subProcess?.assignedFunctions.length - 1) {
          // if yes we put the last function state true to navigate to the final page on clicking the next button
          setLastFunction(true);
          // and we set the next function index equal the new index
          setNextFunction(index + 1);
        } else {
          // if it is not the last function we just set the next function index
          setNextFunction(index + 1);
        }
      }
      return nextFunction;
    });
  };

  // get the job parameter by filter mean: by jobID and InstructionID
  const getJobParameterByFilter = () => {
    const { instructionID } = scan;
    // API call
    JobParameterService.getJobParametersByMultipleFilter(jobID, [instructionID])
      .then((response: any) => {
        setValue(response.data[0].value);
        setJobParameterID(response.data[0].jobParameterID);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    // get the instruction by sub process function id
    nextFunction &&
      getInstructionBySubProcessFunctionID(
        subProcess?.assignedFunctions[nextFunction - 1]
          ?.subProcessFunctionAssignmentID,
        { signal: signal }
      );
    // get job parameter by jobID and instruction ID
    jobParametersArray[0] &&
      (jobParametersArray[0]?.jobID === jobID ||
        jobParametersArray[0][0]?.jobID === jobID) &&
      scan?.instructionID &&
      jobParametersArray[nextFunction - 1]?.instructionID ===
        scan?.instructionID &&
      getJobParameterByFilter();
    // turn the camera on from the beginning
    setCamera(true);
    // know what is the next function in this function
    nextFunctionFun();
    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [subProcess, nextFunction, scan?.instructionID, dispatch, id]);

  // get the instruction by sub process function ID
  const getInstructionBySubProcessFunctionID = (id: number, signal: any) => {
    // API call
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response: any) => {
        //add a condition to inform the user that there are no data added by the user
        if (response?.data?.length > 0) {
          setScan(response.data[0]);
        } else {
          dispatch(
            setMessage("No data have been entered by the admin", "error")
          );
        }
        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // this is a function used by the camera
  const onDetected = (result: any) => {
    setValue(result);
  };

  // handle the input field
  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  // this function save the job parameter even create or update
  const saveJobParameter = () => {
    const { instructionID } = scan;
    dispatch(setLoading());

    // check if we still in the same job
    jobParametersArray[0] &&
    (jobParametersArray[0]?.jobID === jobID ||
      jobParametersArray[0][0]?.jobID === jobID) &&
    jobParameterID
      ? // API call update
        JobParameterService.updateJobParameter([
          { jobParameterID, jobID, instructionID, value },
        ])
          .then(
            (response) => {
              dispatch(removeLoading());
              // add the data created in the redux store
              dispatch(
                updateJobParameterRedux({
                  jobParameterName: "scan",
                  title: scan?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  value: value,
                })
              );
              // navigate even to the next instruction or to the final page
              isLastFunction ||
              subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            dispatch(setMessage(err, "error"));
            console.log("err", err);
          })
      : // API call create
        JobParameterService.createJobParameter([
          {
            jobID,
            instructionID,
            value,
          },
        ])
          .then(
            (response) => {
              // add the data created in the redux store
              dispatch(
                addJobParameter({
                  jobParameterName: "scan",
                  title: scan?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  value: value,
                })
              );
              dispatch(removeLoading());
              // navigate even to the next instruction or to the final page
              isLastFunction
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            nextFunctionFun();
            history.push(
              "/sub-processes-overview/" +
                subProcess?.assignedFunctions[nextFunction - 1]?.code +
                "/" +
                subProcess?.assignedFunctions[nextFunction - 1]
                  ?.subProcessFunctionAssignmentID
            );
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          });
  };

  //call the finishJob api, remove the redux store of the jobParameter array
  const saveJob = () => {
    if (isSavePopup.isLogin === false) {
      history.push(`/sub-processes-overview/T/${subProcess.processID}`);
    } else {
      history.push("/login");
    }
    setSubProcess(null);
    dispatch(resetJobParameter());
  };

  //Add a popup to warn the user while moving from one page to another
  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        if (e.target.tagName.toLowerCase() === "a") {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: true,
          });
        } else {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: false,
          });
        }
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Möchtest du aufhören?"
          Save={() => {
            saveJob();
            setSavePopup({ savePopup: false, isLogin: false });
          }}
          closeSavePopup={() =>
            setSavePopup({ savePopup: false, isLogin: false })
          }
          buttonText="Ja"
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Sub Process Name */}
          <div className="sub-process-function-name">
            <p>{subProcess.name}</p>

            {/* Instruction Title */}
            <h3 className="sub-process-function-name-h3">{scan?.title}</h3>
          </div>

          {/* Instruction description */}
          <div>
            <p>{scan?.description}</p>
          </div>

          {/* Instruction Body container */}
          <div className="user-scan-body">
            <div className="barcode-scanner-body">
              {/* Scanner camera */}
              <div className="scanner-input">
                <div className="my-App">
                  <div className="container">
                    {camera && <Scanner onDetected={onDetected} />}
                  </div>
                </div>
              </div>

              {/* the Scan title  */}
              <div className="scanner-output">
                <div className="sub-process-function-name">
                  <h4> Aufgenommene {scan?.title}:</h4>
                </div>

                {/* the barcode number  */}
                <Input
                  name="value"
                  value={value}
                  onChange={handleInputChange}
                />
                {/* Refresh the camera and clear value */}
                <UserButton
                  onClick={() => {
                    // stop the camera
                    setCamera(false);
                    // restart the camera
                    setTimeout(() => {
                      setCamera(true);
                    }, 200);
                    // empty the value
                    setValue("");
                  }}
                >
                  erneut scannen
                </UserButton>
              </div>
            </div>
          </div>

          {/* Back and next buttons */}
          <div className="back-next-buttons">
            <UserButton
              //add a condition to check if it's the first function, so direct the user to the subProcess page
              onClick={() => {
                nextFunction === 1 &&
                subProcess?.assignedFunctions.length !==
                  jobParametersArray?.length
                  ? setSavePopup({
                      savePopup: true,
                      isLogin: false,
                    })
                  : subProcess?.assignedFunctions.length ===
                    jobParametersArray?.length
                  ? history.push("/sub-processes-overview/final-page/")
                  : history.push(
                      "/sub-processes-overview/" +
                        subProcess?.assignedFunctions[nextFunction - 2]?.code +
                        "/" +
                        subProcess?.assignedFunctions[nextFunction - 2]
                          ?.subProcessFunctionAssignmentID
                    );
              }}
            >
              zurück
            </UserButton>
            <UserButton disabled={!value} onClick={saveJobParameter}>
              {subProcess?.assignedFunctions.length ===
              jobParametersArray?.length
                ? "speichern"
                : "weiter"}
            </UserButton>
          </div>
        </>
      )}
    </>
  );
}

export default BarcodeScanner;
