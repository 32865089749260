/* eslint-disable eqeqeq */
import "./ListBox.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// services

import InstructionService from "../../../Services/instructionService";
import JobParameterService from "../../../Services/jobParameterService";

// Actions
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import {
  addJobParameter,
  resetJobParameter,
  updateJobParameterRedux,
} from "../../../Store/actions/jobParameterAction";
import { setMessage } from "../../../Store/actions/messageAction";

// components:
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import UserButton from "../../../Components/UserButton/UserButton";

function ListBox({
  subProcess,
  setSubProcess,
}: {
  subProcess: any;
  setSubProcess: any;
}) {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const job = useSelector((state: any) => state.JobReducer);

  const { loading } = useSelector((state: any) => state.LoadingReducer);
  // the redux array that store the finished instructions
  const jobParametersArray = useSelector(
    (state: any) => state.JobParameterReducer
  );

  // get the jobID from redux state
  const jobID = job.jobID;

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  //local state:
  // listBox is the initial state of the main function
  const [listBox, setListBox] = useState<any>();

  // listBoxItems are the items of the main list
  const [listBoxItems, setListBoxItems] = useState<any>({});
  // state to know if we are in the last function in the assigned functions main array
  const [isLastFunction, setLastFunction] = useState<any>(false);

  // nextFunction index in the assigned functions main array
  const [nextFunction, setNextFunction] = useState<any>("");

  // if we are in update mode this will be the job parameter ID
  const [jobParameterID, setJobParameterID] = useState<any>();
  //  selected or done instruction items
  const [doneInstructionItems, setDoneInstructionItems] = useState<any>([]);

  // show this popup in case the user has changed some data without saving
  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
    isLogin: false,
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { fun, id } = params;

  const code = fun.toUpperCase();

  // function that manage the next function indexing
  const nextFunctionFun = () => {
    // map over the assigned functions to the selected sub process
    subProcess?.assignedFunctions?.map((subProcessData: any, index: any) => {
      // check to see the current function we are in where it is in the assigned functions array

      if (
        subProcessData.code === code &&
        subProcessData.subProcessFunctionAssignmentID == id
      ) {
        // when we find our function, we check if it is the last one in the array
        if (index === subProcess?.assignedFunctions.length - 1) {
          // if yes we put the last function state true to navigate to the final page on clicking the next button
          setLastFunction(true);
          // and we set the next function index equal the new index
          setNextFunction(index + 1);
        } else {
          // if it is not the last function we just set the next function index
          setNextFunction(index + 1);
        }
      }
      return nextFunction;
    });
  };

  // get the job parameter by filter mean: by jobID and InstructionID
  const getJobParameterByFilter = () => {
    const { instructionID } = listBox;
    // API call
    JobParameterService.getJobParametersByMultipleFilter(jobID, [instructionID])
      .then((response: any) => {
        // set the local array from the redux array
        setDoneInstructionItems(
          jobParametersArray[nextFunction - 1].doneInstructionItems
        );
        // add the array return all in it then get the ID in the loop below
        setJobParameterID(response.data);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    // know what is the next function in this function
    nextFunctionFun();
    // get the instruction by sub process function id
    nextFunction &&
      getInstructionBySubProcessFunctionID(
        subProcess?.assignedFunctions[nextFunction - 1]
          ?.subProcessFunctionAssignmentID,
        { signal: signal }
      );
    // get job parameter by jobID and instruction ID
    jobParametersArray[0] &&
      (jobParametersArray[0]?.jobID === jobID ||
        jobParametersArray[0][0]?.jobID === jobID) &&
      listBox?.instructionID &&
      jobParametersArray[nextFunction - 1]?.instructionID ===
        listBox.instructionID &&
      getJobParameterByFilter();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [subProcess, nextFunction, listBox?.instructionID, dispatch, id]);

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number, signal: any) => {
    // API call
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response: any) => {
        if (response?.data?.length > 0) {
          setListBox(response.data[0]);
        } else {
          dispatch(
            setMessage("No data have been added from the admin", "error")
          );
        }
        setListBoxItems(response.data[0].instructionItems);
        // console.log(
        //   "response.data[0].instructionItems",
        //   response.data[0].instructionItems
        // );
        // dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        // dispatch(removeLoading());
      });
  };

  // function that handle when we click on one of the list items
  const handleInstructionItemClick = (instructionItemName: any) => {
    // check is the array of done or selected items include the new selected or no
    if (!doneInstructionItems?.includes(instructionItemName)) {
      // if no add it to the done array
      setDoneInstructionItems((prev: any) => [...prev, instructionItemName]);
    } else {
      // else remove it from the array
      setDoneInstructionItems((prev: any) =>
        prev.filter((val: any) => val !== instructionItemName)
      );
    }
  };

  // temp array where we put in all the list item objects to submit to the backend
  let finalListBoxItemsArray: any = [];
  for (let i = 0; i < listBoxItems.length; i++) {
    // check if we have jobParameterID so we add it cz it will be used for update API
    jobParameterID
      ? finalListBoxItemsArray.push({
          jobParameterID: jobParameterID[i]?.jobParameterID,
          jobID: jobID,
          instructionID: listBoxItems[i].instructionID,
          instructionItemID: listBoxItems[i].instructionItemID,
          value: doneInstructionItems.includes(listBoxItems[i].name)
            ? "yes"
            : "no",
        })
      : finalListBoxItemsArray.push({
          jobID: jobID,
          instructionID: listBoxItems[i].instructionID,
          instructionItemID: listBoxItems[i].instructionItemID,
          value: doneInstructionItems.includes(listBoxItems[i].name)
            ? "yes"
            : "no",
        });
  }

  // temp array where we put in all the list item objects to submit to the redux store
  // let finalListBoxArray: any = [];
  // console.log("finalListBoxArray:", finalListBoxArray);
  // for (let i = 0; i < listBoxItems.length; i++) {
  //   finalListBoxArray.push({
  //     name: listBoxItems[i].name,
  //     path: currentRoute,
  //     jobID: jobID,
  //     instructionID: listBoxItems[i].instructionID,
  //     instructionItemID: listBoxItems[i].instructionItemID,
  //     value: doneInstructionItems.includes(listBoxItems[i].name) ? "yes" : "no",
  //   });
  // }

  const saveJobParameter = () => {
    dispatch(setLoading());

    // check if we still in the same job
    jobParametersArray[0] &&
    (jobParametersArray[0]?.jobID === jobID ||
      jobParametersArray[0][0]?.jobID === jobID) &&
    jobParameterID
      ? // API call update
        JobParameterService.updateJobParameter(finalListBoxItemsArray)
          .then(
            (response) => {
              dispatch(removeLoading());
              dispatch(
                updateJobParameterRedux({
                  jobParameterName: "list",
                  title: listBox?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: listBox.instructionID,
                  doneInstructionItems: doneInstructionItems,
                })
              );

              isLastFunction ||
              subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("message", message);

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            nextFunctionFun();
            history.push(
              "/sub-processes-overview/" +
                subProcess?.assignedFunctions[nextFunction - 1]?.code +
                "/" +
                subProcess?.assignedFunctions[nextFunction - 1]
                  ?.subProcessFunctionAssignmentID
            );
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          })
      : JobParameterService.createJobParameter(finalListBoxItemsArray)
          .then(
            (response) => {
              dispatch(removeLoading());
              dispatch(
                addJobParameter({
                  jobParameterName: "list",
                  title: listBox?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: listBox.instructionID,
                  doneInstructionItems: doneInstructionItems,
                })
              );

              isLastFunction
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            nextFunctionFun();
            history.push(
              "/sub-processes-overview/" +
                subProcess?.assignedFunctions[nextFunction - 1]?.code +
                "/" +
                subProcess?.assignedFunctions[nextFunction - 1]
                  ?.subProcessFunctionAssignmentID
            );
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          });
  };

  //call the finishJob api, remove the redux store of the jobParameter array
  const saveJob = () => {
    if (isSavePopup.isLogin === false) {
      history.push(`/sub-processes-overview/T/${subProcess.processID}`);
    } else {
      history.push("/login");
    }
    setSubProcess(null);
    dispatch(resetJobParameter());
  };

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        if (e.target.tagName.toLowerCase() === "a") {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: true,
          });
        } else {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: false,
          });
        }
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Möchtest du aufhören?"
          Save={() => {
            saveJob();
            setSavePopup({ savePopup: false, isLogin: false });
          }}
          closeSavePopup={() =>
            setSavePopup({ savePopup: false, isLogin: false })
          }
          buttonText="Ja"
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="sub-process-function-name">
            <p>{subProcess.name}</p>
            <h3 className="sub-process-function-name-h3">{listBox?.title}</h3>
          </div>

          <div>
            <p>{listBox?.description}</p>
          </div>

          <div className="user-list-body">
            <div className="list-items">
              {listBox?.instructionItems ? (
                listBox?.instructionItems.map(
                  (instructionItem: any, index: any) => {
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          handleInstructionItemClick(instructionItem.name)
                        }
                        className={
                          doneInstructionItems?.includes(instructionItem.name)
                            ? "one-list-item-done"
                            : "one-list-item-default"
                        }
                      >
                        <p>{instructionItem.name}</p>
                      </div>
                    );
                  }
                )
              ) : (
                <h1>no list items</h1>
              )}
            </div>
          </div>

          <div className="back-next-buttons">
            {/* <UserButton onClick={saveJob}>zurück</UserButton> */}
            <UserButton
              onClick={() => {
                nextFunction === 1 &&
                subProcess?.assignedFunctions.length !==
                  jobParametersArray?.length
                  ? setSavePopup({
                      savePopup: true,
                      isLogin: false,
                    })
                  : subProcess?.assignedFunctions.length ===
                    jobParametersArray?.length
                  ? history.push("/sub-processes-overview/final-page/")
                  : history.push(
                      "/sub-processes-overview/" +
                        subProcess?.assignedFunctions[nextFunction - 2]?.code +
                        "/" +
                        subProcess?.assignedFunctions[nextFunction - 2]
                          ?.subProcessFunctionAssignmentID
                    );
              }}
            >
              zurück
            </UserButton>
            <UserButton onClick={saveJobParameter}>
              {subProcess?.assignedFunctions.length ===
              jobParametersArray?.length
                ? "speichern"
                : "weiter"}
            </UserButton>
          </div>
        </>
      )}
    </>
  );
}

export default ListBox;
