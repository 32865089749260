import "./UserOverviewPage.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// services
import ImageService from "../../../Services/imageService";
import ProcessService from "../../../Services/processService";

// actions
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import { setMessage } from "../../../Store/actions/messageAction";
import { setPresetProcess } from "../../../Store/actions/presetProcessAction";
// import { setSubPresetProcess } from "../../../Store/actions/presetSubProcessAction";

//Components:
import Loader from "../../../Components/Loader/Loader";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

//Images:
import Image from "../../../Assets/Images/image.png";
import Header from "../../../Components/Header/Header";

export interface UserOverviewPageProps {}

const UserOverviewPage: React.FC<UserOverviewPageProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  const [processes, setProcesses] = useState<any>();
  const [processImages, setProcessImages] = useState<any>();

  useEffect(() => {
    // dispatch(removeLoading());
    // get all processes
    GetProcesses();
    dispatch(setPresetProcess(""));

    // eslint-disable-next-line
  }, []);

  // when clicking on any process in overview page we redirect to the manage processes with detail of process clicked:
  const goToSubProcesses = (processID: number, processName: any) => {
    history.push("/sub-processes-overview/T/" + processID);
    // dispatch(setPresetProcess({ processID, processName }));
    // dispatch(setSubPresetProcess(""));
  };

  // get and display all processes in the DB:
  const GetProcesses = () => {
    dispatch(setLoading());

    ProcessService.getAllProcesses()
      .then(
        (response) => {
          setProcesses(response.data);
          // dispatch(removeLoading());
          getImages(response.data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log("message", message);

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
          return Promise.reject();
        }
      )
      .catch((e) => {
        dispatch(removeLoading());
        console.log(e);
      });
  };

  // function to get all images that take images IDs
  function getImages(data: any) {
    dispatch(setLoading());

    if (data !== undefined) {
      let imageIDs = [];
      for (let item of data) {
        if (item.imageID !== undefined) {
          imageIDs.push(item.imageID);
        }
      }
      ImageService.getAllImages(imageIDs, data)
        .then((res) => {
          setProcessImages(res.data);
          dispatch(removeLoading());
        })
        .catch((e: any) => {
          console.log(e);
          dispatch(removeLoading());
        });
    }
  }

  return (
    <>
      <Header/>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="user-overview-processes">
            {processes
              ? processes.map((process: any, index: any) => (
                  <div
                    className="user-one-process"
                    key={index}
                    onClick={() =>
                      goToSubProcesses(process.processID, process.name)
                    }
                  >
                    {processImages &&
                      processImages
                        .filter((item: any) => item.imageID === process.imageID)
                        .map(function (image: any) {
                          process.image = image.image;
                          return null;
                        })}
                    <h4>{process.name}</h4>
                    <img
                      className="user-one-process-img"
                      src={
                        process.image && process.imageID !== undefined
                          ? "data:image/jpeg;base64," + process.image
                          : Image
                      }
                      alt=""
                    />
                  </div>
                ))
              : null}
          </div>
        </>
      )}
    </>
  );
};

export default UserOverviewPage;
