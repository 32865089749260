import http from "./http-common";
import authHeader from "./authHeader";

const getJob = (id: number) => {
  return http.get(`/Job/${id}`, { headers: authHeader() });
};

const getAllJobs = () => {
  return http.get("/Job/", { headers: authHeader() });
};

const createJob = async (data: any) => {
  const res = await http.post("/Job/BeginJob", data, { headers: authHeader() });
  return res;
};

//remove the data from finish job 
const finishJob = (id: number) => {
  return http.put(`/Job/FinishJob/${id}`, { headers: authHeader() });
};

const JobService = {
  getJob,
  getAllJobs,
  createJob,
  finishJob,
};

export default JobService;
