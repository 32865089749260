import "./ListFunction.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setMessage } from "../../../../Store/actions/messageAction";
import InstructionService from "../../../../Services/instructionService";
import { FreeTextSchema } from "../../../../Validations/AdminValidations";
import {
  setLoading,
  removeLoading,
} from "../../../../Store/actions/loadingAction";

//Components:
import Loader from "../../../../Components/Loader/Loader";
import DeletePopup from "../../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "./../../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../../Components/MessageNotification/MessageNotification";

//Images:
import add from "../../../../Assets/Images/add.png";
import minus from "../../../../Assets/Images/minus.png";
import SavePopup from "../../../../Components/SavePopup/SavePopup";

export interface ListFunctionProps {
  subProcess: any;
}

const ListFunction: React.FC<ListFunctionProps> = (props) => {
  const dispatch = useDispatch<any>();

  //redux state
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local state
  //List  initial state:
  const initialFieldValues = {
    name: "",
    order: "1" as any,
  };

  const [instructionItems, setMyInstructionItems] = useState([
    initialFieldValues,
  ]);

  const [isAdd, setIsAdd] = useState(true);

  //store saved list coming from DB in a state
  const [savedList, setSavedList] = useState<any>();
  //store saved instruction items coming from DB in a state
  const [savedInstructionItems, setSavedInstructionItems] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    instructionID: "",
  });

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  // local state List:
  const [list, setList] = useState({
    instructionID: "",
    title: "",
    description: "",
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { id } = params;
  const subProcessFunctionID = id;

  // constant we send to the backend to check for name duplicates or no:
  const checkForNameDuplication = false;

  //function that add an empty sub-process to the state to be created when clicking on the + button:
  const handleAddFields = (index: any) => {
    setMyInstructionItems([
      ...instructionItems,
      {
        name: "",
        order: index + 2,
      },
    ]);
  };

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleRemoveFields = (index: any) => {
    const values = [...instructionItems];
    values.splice(index, 1);
        for (let i = 0; i < instructionItems.length - 1; i++) {
          values[i].order = i + 1;
        }
        setMyInstructionItems(values);

    if (instructionItems.length === 1) {
      setMyInstructionItems([
        {
          name: "",
          order: "1",
        },
      ]);
    }
  };

  // function that chandle the input change
  const handleInputChange = (index: any, event: any) => {
    const values: any = [...instructionItems];
    values[index][event.target.name] = event.target.value.trimStart();
    setMyInstructionItems(values);
    // disable the popup on the onChange
    setSavePopup({
      savePopup: false,
    });
  };

  // handle the name input change:
  const handleListInputChange = (event: any) => {
    const { name, value } = event.target;
    setList({ ...list, [name]: value.trimStart() });
    // disable the popup on the onChange
    setSavePopup({
      savePopup: false,
    });
  };

  // we get the location and fill them in the dropdown, and if edit we get license by ID
  useEffect(
    () => {
      subProcessFunctionID &&
        getInstructionBySubProcessFunctionID(subProcessFunctionID);
    },
    // eslint-disable-next-line
    [subProcessFunctionID]
  );

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number) => {
    dispatch(setLoading());
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response) => {
        response.data.length > 0 ? setIsAdd(false) : setIsAdd(true);
        dispatch(removeLoading());
        setList(() => {
          return {
            instructionID: response.data[0]?.instructionID
              ? response.data[0]?.instructionID
              : "",
            title: response.data[0]?.title ? response.data[0].title : "",
            description: response.data[0]?.description
              ? response.data[0].description
              : "",
            // instructionItems: response.data[0]?.instructionItems
            //   ? response.data[0]?.instructionItems
            //   : "",
          };
        });
        setSavedList(() => {
          return {
            instructionID: response.data[0]?.instructionID
              ? response.data[0]?.instructionID
              : "",
            title: response.data[0]?.title ? response.data[0].title : "",
            description: response.data[0]?.description
              ? response.data[0].description
              : "",
            // instructionItems: response.data[0]?.instructionItems
            //   ? response.data[0]?.instructionItems
            //   : "",
          };
        });
        if (response.data[0]?.instructionItems.length > 0) {
          setMyInstructionItems(response.data[0].instructionItems);
          setSavedInstructionItems(
            JSON.stringify(response.data[0].instructionItems)
          );
        } else {
          setMyInstructionItems([
            {
              name: "",
              order: "1",
            },
          ]);
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function that handle the on submit formik form
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAdd) {
      createList(fields, setSubmitting);
    } else {
      updateList(fields, setSubmitting);
    }
  }

  // create List function:
  function createList(fields: any, setSubmitting: any) {
    let { title, description } = fields;
    dispatch(setLoading());
    InstructionService.createInstruction([
      {
        title,
        description,
        subProcessFunctionID,
        checkForNameDuplication,
        instructionItems,
      },
    ])
      .then(
        (data: any) => {
          dispatch(setMessage("Eingaben erfolgreich gespeichert.", "success"));
          dispatch(removeLoading());
          subProcessFunctionID &&
            getInstructionBySubProcessFunctionID(subProcessFunctionID);
          // setDisabled(true);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((e: any) => {
        console.log(e);
        dispatch(removeLoading());
      });
  }

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    //call the setTimeout method to prevent the popup from appearing continuously
    setTimeout(
      () =>
        setSavePopup({
          savePopup: false,
        }),
      0
    );
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  useEffect(() => {
    //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
    setTimeout(() => CheckIfStateChanged(), 0);
  })

  const CheckIfStateChanged = () => {
    //compare states coming from DB with our states
    if (
      savedInstructionItems === JSON.stringify(instructionItems) &&
      JSON.stringify(savedList) === JSON.stringify(list)
    ) {
      //both states are same
      setIsStateChanged(false);
    } else {
      for (let i = 0; i < instructionItems.length; i++){
        if (!instructionItems[i].name ) {
          setIsStateChanged(false)
        }else {
          setIsStateChanged(true)
        }
      }
    }
   
  };

  // update List function:
  function updateList(fields: any, setSubmitting: any) {
    const { instructionID, title, description } = fields;
    dispatch(setLoading());

    InstructionService.updateInstruction([
      {
        instructionID,
        title,
        description,
        subProcessFunctionID,
        checkForNameDuplication,
        instructionItems,
      },
    ])

      .then(
        (response: any) => {
          dispatch(setMessage("Eingaben erfolgreich gespeichert.", "success"));
          subProcessFunctionID &&
            getInstructionBySubProcessFunctionID(subProcessFunctionID);
          // setDisabled(true);
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // replace the already existed message with the message coming from the backend
          if (
            error.response.data.message ===
            "Instruction Items contain duplicates"
          ) {
            dispatch(setMessage("Listenelemente dürfen sich nicht doppeln.", "error"));
          } else if (error.message === "Request failed with status code 400") {
            dispatch(
              setMessage(
                "Bitte geben Sie einen Namen für das Listenelement ein.",
                "error"
              )
            );
          } else {
            dispatch(setMessage(message, "error"));
          }

          dispatch(removeLoading());
          // setDisabled(true);
        }
      )
      .catch((e: any) => {
        console.log(e);
      });
  }

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleDelete = (id: any) => {
    // we check if we have id so we show the popup else only we remove it from the local state
    if (id) {
      setDeletePopup({
        deletePopup: true,
        instructionID: id,
      });
    } else {
      setList({
        instructionID: "",
        title: "",
        description: "",
      });

      setMyInstructionItems([{ name: "", order: "1" }]);
    }
  };

  // function that delete the list instruction
  const deleteInstruction = (id: any) => {
    dispatch(setLoading());
    InstructionService.deleteInstruction(id)
      .then((res) => {
        setDeletePopup({ deletePopup: false, instructionID: "" });
        dispatch(removeLoading());
        dispatch(setMessage("Eingaben erfolgreich gelöscht.", "success"));
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // function to validate if a feild value exict or we show a required error message:
  const validateName = (value: any) => {
    let error;
    if (!value) {
      error = "Erforderlich";
    }
    return error;
  };

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("You are not an Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Sollen Ihre Eingaben zur Funktion Liste gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
            setSavePopup({ savePopup: false });
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Sollen Ihre Eingaben wirklich gelöscht werden?"
          Delete={() => deleteInstruction(isDeletePopup.instructionID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, instructionID: "" })
          }
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div ref={formRef}>
            <Formik
              initialValues={list}
              validationSchema={FreeTextSchema}
              onSubmit={onSubmit}
              //Remove validateOnMount since it affected the list field on the first render
              // validateOnMount
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                isValid,
              }) => {
                //store errors in a stated to send as prop for save pop up
                setTimeout(() => {
                  setValidationError(errors);
                }, 0);
                return (
                  <>
                    <div className="list-function-body">
                      <h1>Liste</h1>
                      <Form>
                        <div className="list-body">
                          <div className="first-table-list">
                            <table>
                              <tbody>
                                {instructionItems.map(
                                  (instructionItem, index) => (
                                    <tr key={index} className="col-2-2">
                                      <td className="create-sub-process-label-list">
                                        <h2>Listenelement:</h2>
                                      </td>
                                      <td className="instructionItem-name-input">
                                        <Field
                                          id="name"
                                          type="text"
                                          name="name"
                                          value={instructionItem.name}
                                          placeholder="Listenelement eingeben"
                                          onChange={(event: any) =>
                                            handleInputChange(index, event)
                                          }
                                          validate={() =>
                                            validateName(
                                              instructionItems[index].name
                                            )
                                          }
                                        />
                                        {!instructionItems[index].name && (
                                          <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="new-license-error-message"
                                          />
                                        )}
                                      </td>

                                      {instructionItems[index].name.length !==
                                        0 || index !== 0 ? (
                                        <td>
                                          <img
                                            src={minus}
                                            className="icon-img-list"
                                            alt="remove"
                                            onClick={() =>
                                              handleRemoveFields(
                                                index
                                                // instructionItem.instructionItemID
                                              )
                                            }
                                          />
                                        </td>
                                      ) : null}

                                      {instructionItems.length === index + 1 &&
                                      instructionItems[index].name.length !==
                                        0 ? (
                                        <td>
                                          <img
                                            className="icon-img-list2"
                                            src={add}
                                            alt="add"
                                            onClick={() =>
                                              handleAddFields(index)
                                            }
                                          />
                                        </td>
                                      ) : null}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="second-table-list">
                            <table className="">
                              <tbody>
                                <tr>
                                  <td>
                                    <h2>Überschrift:</h2>
                                  </td>
                                  <td style={{ width: "83%" }}>
                                    <Field
                                      className="list-text"
                                      name="title"
                                      value={list.title}
                                      placeholder="Überschrift einfügen"
                                      onChange={handleListInputChange}
                                    />
                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="new-license-error-message"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td className="header-list">
                                    <h2>Anweisungen:</h2>
                                  </td>
                                  <td style={{ width: "20rem" }}>
                                    <Field
                                      as="textarea"
                                      rows="5"
                                      className="list-description"
                                      name="description"
                                      value={list.description}
                                      placeholder="Anweisung einfügen"
                                      type="textarea"
                                      onChange={handleListInputChange}
                                    />
                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      className="new-license-error-message"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td style={{ width: "10rem" }}></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="reset-btn-list">
                            <DefaultButton
                              type="reset"
                              onClick={() => handleDelete(values.instructionID)}

                              // onClick={() =>
                              //   setDeletePopup({
                              //     deletePopup: true,
                              //     index: "1",
                              //     instructionID: values.instructionID,
                              //   })
                              // }
                            >
                              zurücksetzen
                            </DefaultButton>
                            <DefaultButton
                              //Replace the isValid property with the fields' values because it affected the error message of the list item
                              // add !instructionItems[0].name to check if there is a name field
                              disabled={
                                !isStateChanged ||
                                !list.title || !instructionItems
                              }
                              type="submit"
                              id="clickSave"
                            >
                              Speichern
                            </DefaultButton>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default ListFunction;
