import "./ManageFunctions.css";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../Store/actions/messageAction";

// components:
import Loader from "../../../Components/Loader/Loader";
import Function from "../Function/Function";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";
//import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import { getAllFunctions } from "../../../Store/actions/functionAction";

export interface ManageFunctionsProps {}

const ManageFunctions: React.FC<ManageFunctionsProps> = () => {
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const functions = useSelector((state: any) => state.FunctionReducer);

  //get all functions from DB:
  useEffect(() => {
    dispatch(getAllFunctions());
    document.title = "JMS - Funktionen verwalten";
  }, [dispatch]);

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/" />;
  }

  return (
    <>
      <SensrecAdminNavbar />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="manage-functions-body">
            <div className="title-btns">
              <h1>Funktionen verwalten:</h1>
              {/*} <div className="topBtn">
                <Link to="/create-edit-function">
                  <DefaultButton>Neu Erstellen</DefaultButton>
                </Link>
               </div>*/}
              {/* <NewBackButtons pathname="/create-edit-location" /> */}
            </div>

            <div>
              <table className="locations">
                <tbody>
                  <tr>
                    <th className="my-td-label-function">
                      <h2>Name</h2>
                    </th>
                    <th colSpan={2} className="my-td-label-function">
                      <h2>Aktion</h2>
                    </th>
                  </tr>
                  {functions.map((fun: any) => (
                    <Function
                      key={fun.functionID}
                      functionID={fun.functionID}
                      name={fun.name}
                      pathname="/create-edit-function/"
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManageFunctions;
