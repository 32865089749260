import "./ProcessParameter.css";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../../../Store/actions/messageAction";
import SubProcessService from "../../../Services/subProcessService";
import { getAllProcesses } from "../../../Store/actions/processAction";
import { setPresetProcess } from "../../../Store/actions/presetProcessAction";
import { setSubPresetProcess } from "../../../Store/actions/presetSubProcessAction";

//Components:
// import Input from "../../../Components/Input/Input";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import FreeText from "../Functions/FreeText/FreeText";
import ScanFunction from "../Functions/ScanFunction/ScanFunction";
import ListFunction from "../Functions/ListFunction/ListFunction";
import DateFunction from "../Functions/DateFunction/DateFunction";
import CountFunction from "../Functions/CountFunction/CountFunction";
import YesNoFunction from "../Functions/YesNoFunction/YesNoFunction";
import PhotoFunction from "../Functions/PhotoFunction/PhotoFunction";
import NumberFunction from "../Functions/NumberFunction/NumberFunction";
// import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import SignatureFunction from "../Functions/SignatureFunction/SignatureFunction";
import ProcessParameterNavBar from "../ProcessParameterNavBar/ProcessParameterNavBar";
import InstructionFunction from "../Functions/InstructionFunction/InstructionFunction";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

export interface ProcessParameterProps {}

const ProcessParameter: React.FC<ProcessParameterProps> = () => {
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  // get all process from redux store:
  const processes = useSelector((state: any) => state.ProcessReducer);
  // const subProcesses = useSelector((state: any) => state.subProcessesReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  // get preset process ID
  const { presetProcessID } = useSelector(
    (state: any) => state.PresetProcessReducer
  );
  // get preset sub process ID
  const { presetSubProcessID } = useSelector(
    (state: any) => state.PresetSubProcessReducer
  );

  // local states
  const [subProcess, setSubProcess] = useState<any>();
  const [subProcesses, setSubProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState("");
  const [selectedSubProcess, setSelectedSubProcess] = useState("");

  //process dropdown handler
  const handleProcessDropDownChange = (event: any) => {
    <Redirect
      to={{
        pathname: "/process-parameter/",
      }}
    />;

    const selectedProcess = event.target.value;
    setSelectedProcess(selectedProcess);
    getSubProcessesByProcessID(selectedProcess);
    dispatch(
      setPresetProcess({
        presetProcessID: selectedProcess,
        presetProcessName: "",
      })
    );
    dispatch(setSubPresetProcess(""));
    setSelectedSubProcess("");
  };

  // after selecting a process we get the sub-process of the selected process:
  const getSubProcessesByProcessID = (id: string) => {
    SubProcessService.getSubProcessesByProcessID(id)
      .then((response) => {
        if (response.data.length > 0) {
          // dispatch(getAllSubProcesses());
          setSubProcesses([]);
          setSubProcesses(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ------------------------------------------------------------------------------------------------------------

  //subProcess dropdown handler
  const handleSubProcessDropDownChange = (event: any) => {
    const selectedSubProcess = event.target.value;
    setSelectedSubProcess(selectedSubProcess);
    getSubProcessByID(selectedSubProcess);
    dispatch(
      setSubPresetProcess({
        presetSubProcessID: selectedSubProcess,
        presetSubProcessName: "",
      })
    );
  };

  // after selecting a subProcess we get the functions of the selected subProcess:
  const getSubProcessByID = (id: string) => {
    SubProcessService.getSubProcess(id)
      .then((response) => {
        setSubProcess(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    dispatch(getAllProcesses());
    presetProcessID && getSubProcessesByProcessID(presetProcessID);
    presetSubProcessID && getSubProcessByID(presetSubProcessID);
    document.title = "JMS - Funktionen verwalten";
  }, [presetProcessID, presetSubProcessID, dispatch]);

  //get the current route from url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      <AdminNavbar subProcess={subProcess} />

      <div className="evaluate-process-body">
        <h1>Funktionen verwalten:</h1>
        <div className="edit-processes">
          <div className="col-1">
            <table className="subProcess-table">
              <tbody>
                <tr>
                  <td>
                    <h2>Prozess:</h2>
                  </td>
                  <td className="process-name-dropdown">
                    <select
                      name="items"
                      onChange={handleProcessDropDownChange}
                      value={
                        presetProcessID ? presetProcessID : selectedProcess
                      }
                    >
                      <option value="">Prozess wählen</option>
                      {processes
                        ? processes.map((process: any, index: any) => (
                            <option
                              key={index}
                              id={process.processID}
                              value={process.processID}
                            >
                              {process.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-2">
            <table className="subProcess-table">
              <tbody>
                <tr className="col-2-1">
                  <td>
                    <h2>Teilprozess: </h2>
                  </td>
                  <td className="process-name-dropdown">
                    <select
                      style={{ width: "100%" }}
                      name="items"
                      onChange={handleSubProcessDropDownChange}
                      value={
                        presetSubProcessID
                          ? presetSubProcessID
                          : selectedSubProcess
                      }
                    >
                      <option value="0">Teilprozess wählen</option>
                      {subProcesses
                        ? subProcesses.map((subProcess: any) => (
                            <option
                              key={subProcess.subProcessID}
                              id={subProcess.subProcessID}
                              value={subProcess.subProcessID}
                            >
                              {subProcess.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {(selectedProcess && selectedSubProcess) ||
        (presetProcessID && presetSubProcessID) ? (
          <div>
            <div className="process-navbar">
              <ProcessParameterNavBar subProcess={subProcess} />
              <Redirect
                to={{
                  pathname:
                    "/process-parameter/" +
                    subProcess?.assignedFunctions[0]?.code +
                    "/" +
                    subProcess?.assignedFunctions[0]
                      ?.subProcessFunctionAssignmentID,
                }}
              />
            </div>
            {currentRoute.includes("scan") ? (
              <ScanFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("list") ? (
              <ListFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("freetext") ? (
              <FreeText subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("instruction") ? (
              <InstructionFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("signature") ? (
              <SignatureFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("date") ? (
              <DateFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("number") ? (
              <NumberFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("yesno") ? (
              <YesNoFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("photo") ? (
              <PhotoFunction subProcess={subProcess} />
            ) : null}
            {currentRoute.includes("count") ? <CountFunction /> : null}
          </div>
        ) : (
          <h1 style={{ textAlign: "center" }}>
            Wählen Sie zunächst einen Prozess und einen Teilprozess.
          </h1>
        )}
      </div>
    </>
  );
};

export default ProcessParameter;
