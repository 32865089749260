import "./Input.css";

export interface InputProps {
  id?: string;
  name?: string;
  type?: string;
  value?: string;
  placeholder?: string;
  onChange?: any;
  onClick?: any;
  onBlur?: any;
  onFocus?: any;
  accept?: any;
  readOnly?: any;
}

const Input: React.FC<InputProps> = ({
  id,
  name,
  type,
  value,
  placeholder,
  onChange,
  onClick,
  onBlur,
  onFocus,
  accept,
  readOnly,
}) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      accept={accept}
      readOnly={readOnly}
    />
  );
};

export default Input;
