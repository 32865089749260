import "./EditUser.css";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../../Services/userService";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { updateUser } from "../../../Store/actions/userAction";
import { setMessage } from "../../../Store/actions/messageAction";
import { getAllLocations } from "../../../Store/actions/locationAction";
// import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { editUserSchema } from "../../../Validations/SensrecAdminValidations";

// Components:
// import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
// import BackButton from "../../../Components/BackButton/BackButton";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

import de from "date-fns/locale/de";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import FormikControl from "../../../Components/FormikControl/FormikControl";

setDefaultLocale("de");
registerLocale("de", de);

export interface EditUserProps {
  props: any;
  match: any;
}

const EditUser: React.FC<EditUserProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  // redux states
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const locations = useSelector((state: any) => state.LocationReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  // local states
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [User, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    ip: "",
    locationID: "",
    validTo: new Date(),
  });

  //store saved user coming from DB in a state
  const [savedUser, setSavedUser] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  // get id from the url
  const { id } = props.match.params;

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  // get user from DB by ID to be edited:
  const GetUser = (id: number) => {
    UserService.getUser(id)
      .then((response) => {
        setUser(() => {
          return {
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            email: response.data.email,
            locationID: response.data.location.locationID,
            ip: response.data.ip,
            validTo: response.data.licenseValidTo,
          };
        });
        setSavedUser(() => {
          return {
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            email: response.data.email,
            locationID: response.data.location.locationID,
            ip: response.data.ip,
            validTo: response.data.licenseValidTo,
          };
        });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  // function to reset the admin password to default one
  const resetUserPassword = (userID: any) => {
    UserService.resetUserPassword({ userID })
      .then(() => {
        dispatch(setMessage("Passwort wurde zurückgesetzt.", "success"));
        setDeletePopup(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(getAllLocations());
    document.title = "JMS - Benutzer bearbeiten";

    GetUser(id);
  }, [dispatch, id]);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //get key values we need from the saved user and the changing license state  and store in same object format so we can compare
  let savedUserObj: any;
  let editedUserObj: any;
  if (savedUser !== undefined)
    savedUserObj = {
      firstname: "" + savedUser.firstname,
      lastname: "" + savedUser.lastname,
      email: "" + savedUser.email,
      ip: "" + savedUser.ip,
      locationID: "" + savedUser.locationID,
      validTo: "" + savedUser.validTo,
    };
  if (User !== undefined)
    editedUserObj = {
      firstname: "" + User.firstname,
      lastname: "" + User.lastname,
      email: "" + User.email,
      ip: "" + User.ip,
      locationID: "" + User.locationID,
      validTo: "" + User.validTo,
    };

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);
  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (JSON.stringify(savedUserObj) === JSON.stringify(editedUserObj)) {
      //both states are same
      setIsStateChanged(false);
    } else {
      //states are not equal
      setIsStateChanged(true);
    }
  };

  // function that handle the location drop down
  const handleDropDownChange = (event: any) => {
    const locationSelected = event.target.value;
    setUser((prev) => {
      return {
        ...prev,
        locationID: locationSelected,
      };
    });
  };

  // function that handle the input change
  const handleInputChange = (event: any) => {
    const { id, value } = event.target;
    setUser((previousState) => {
      return {
        ...previousState,
        [id]: value,
      };
    });
  };

  // function that handle the date change
  const handleDatesChange = (event: any) => {
    // const { name, value } = event.target;
    //add the time zone formula to solve the day before issue
    if (event) {
      event.setHours((-1 * event.getTimezoneOffset()) / 60);
    }
    setUser((previousState) => {
      return {
        ...previousState,
        validTo: event,
      };
    });
  };

  const onSubmit = (e: any) => {
    // e.preventDefault();

    dispatch(
      updateUser(props.match.params.id, User.ip, User.locationID, User.validTo)
    )
      .then((response: any) => {
        history.push("/manage-users");
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Soll der Benutzer gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      {isDeletePopup ? (
        <DeletePopup
          label="Möchten Sie das Passwort wirklich zurücksetzen?"
          Delete={() => resetUserPassword(id)}
          closeDeletePopup={() => setDeletePopup(false)}
          buttonText="zurücksetzen"
        />
      ) : null}

      <SensrecAdminNavbar />
      <div className="edit-users-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div ref={formRef}>
              <Formik
                initialValues={User}
                validationSchema={editUserSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                  //store errors in a stated to send as prop for save pop up
                  setTimeout(() => {
                    setValidationError(errors);
                  }, 0);
                  return (
                    <>
                      <div className="title-btns">
                        <h1>Benutzer ändern:</h1>
                        <div>
                          <div className="back-btn">
                            <DefaultButton onClick={() => setDeletePopup(true)}>
                              Reset Password
                            </DefaultButton>
                          </div>
                          <br />
                          <div className="back-btn">
                            <DefaultButton onClick={() => history.goBack()}>
                              Zurück
                            </DefaultButton>
                          </div>
                        </div>
                      </div>
                      <Form className="create-license-form">
                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>Name</h2>
                              </td>
                              <td className="my-td-label-user">
                                <h2>{User.firstname + " " + User.lastname}</h2>
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>E-Mail</h2>
                              </td>
                              <td className="my-td-label-user">
                                <h2>{User.email}</h2>
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  IP-Adresse{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="ip"
                                  id="ip"
                                  type="text"
                                  placeholder="0.0.0.0"
                                  value={User.ip}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Standort <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <Field
                                  as="select"
                                  name="locationID"
                                  id="locationID"
                                  onChange={handleDropDownChange}
                                >
                                  <option value="">Standort wählen</option>

                                  {locations
                                    ? locations.map(
                                        (location: any, index: any) => (
                                          <option
                                            key={index}
                                            id={location.locationID}
                                            value={location.locationID}
                                          >
                                            {location.city}
                                          </option>
                                        )
                                      )
                                    : null}
                                </Field>
                                <ErrorMessage
                                  name="locationID"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Gültig bis{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <DatePicker
                                  name="validTo"
                                  locale="de"
                                  //call the format function inside the date
                                  selected={
                                    User.validTo ? new Date(User.validTo) : null
                                  }
                                  onChange={handleDatesChange}
                                  dateFormat="dd.MM.yyyy"
                                  closeOnScroll={true}
                                  // minDate={new Date()}
                                  isClearable
                                  showYearDropdown
                                  scrollableMonthYearDropdown
                                />

                                <ErrorMessage
                                  name="validTo"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  (<span className="required-p">*</span>
                                  ):{" "}
                                  <span className="required-p">Required</span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="save-cancel-btns">
                          <DefaultButton
                            disabled={!isStateChanged}
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                          <DefaultButton
                            onClick={() => history.push("/manage-users")}
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EditUser;
