import { SET_PROCESS } from "../actions/types";

const initialState = { presetProcessID: "", presetProcessName: "" };

const PresetProcessReducer = (presetProcess = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROCESS:
      return payload;

    default:
      return presetProcess;
  }
};

export default PresetProcessReducer;
