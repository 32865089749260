/* eslint-disable eqeqeq */
import "./Instruction.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Services
// import JobService from "../../../Services/jobService";
import JobParameterService from "../../../Services/jobParameterService";
import InstructionService from "../../../Services/instructionService";

// Actions
import {
  // setLoading,
  removeLoading,
  setLoading,
} from "../../../Store/actions/loadingAction";

import {
  addJobParameter,
  resetJobParameter,
  updateJobParameterRedux,
} from "../../../Store/actions/jobParameterAction";
import { setMessage } from "../../../Store/actions/messageAction";

// components:
// import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import UserButton from "../../../Components/UserButton/UserButton";

function Instruction({
  subProcess,
  setSubProcess,
}: {
  subProcess: any;
  setSubProcess: any;
}) {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const job = useSelector((state: any) => state.JobReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const jobParametersArray = useSelector(
    (state: any) => state.JobParameterReducer
  );

  const jobID = job.jobID;

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  //local state:
  const [instruction, setInstruction] = useState<any>();
  const [isLastFunction, setLastFunction] = useState<any>(false);
  const [nextFunction, setNextFunction] = useState<any>("");
  // setJobParameterID store the jobParameterID in the update mode
  const [jobParameterID, setJobParameterID] = useState<string>();

  // popup state
  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
    isLogin: false,
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { fun, id } = params;
  const code = fun.toUpperCase();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  const nextFunctionFun = () => {
    subProcess?.assignedFunctions?.map((subProcessData: any, index: any) => {
      if (
        subProcessData.code === code &&
        subProcessData.subProcessFunctionAssignmentID == id
      ) {
        if (index === subProcess?.assignedFunctions.length - 1) {
          setLastFunction(true);
          setNextFunction(index + 1);
        } else {
          setNextFunction(index + 1);
        }
      }
      return nextFunction;
    });
  };

  // get the job parameter by filter mean: by jobID and InstructionID
  const getJobParameterByFilter = () => {
    // Destructuring the instructionID property from the freeText
    const { instructionID } = instruction;
    // API call to getJobParametersByMultipleFilter endpoint, takes jobID,instructionID as a param

    JobParameterService.getJobParametersByMultipleFilter(jobID, [instructionID])
      .then((response: any) => {
        // get jobParameterID from the response and store it inside the local state
        setJobParameterID(response.data[0].jobParameterID);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    dispatch(removeLoading());
    nextFunctionFun();

    nextFunction &&
      getInstructionBySubProcessFunctionID(
        subProcess?.assignedFunctions[nextFunction - 1]
          ?.subProcessFunctionAssignmentID,
        { signal: signal }
      );

    // get job parameter by jobID and instruction ID
    jobParametersArray[0] &&
      (jobParametersArray[0]?.jobID === jobID ||
        jobParametersArray[0][0]?.jobID === jobID) &&
      instruction?.instructionID &&
      jobParametersArray[nextFunction - 1]?.instructionID ===
        instruction.instructionID &&
      getJobParameterByFilter();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [subProcess, nextFunction, instruction?.instructionID, dispatch, id]);

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number, signal: any) => {
    // dispatch(setLoading());

    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response: any) => {
        if (response?.data?.length > 0) {
          setInstruction(response.data[0]);
        } else {
          dispatch(
            setMessage("No data have been added from the admin", "error")
          );
        }
        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  const saveJobParameter = () => {
    const { instructionID, description } = instruction;

    dispatch(setLoading());
    jobParameterID
      ? JobParameterService.updateJobParameter([
          { jobParameterID, jobID, instructionID, value: description },
        ])
          .then(
            (response) => {
              // add the data created in the redux store
              dispatch(
                updateJobParameterRedux({
                  jobParameterName: "instruction",
                  title: instruction?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  value: description,
                })
              );
              dispatch(removeLoading());
              // navigate either to the next instruction or to the final page
              isLastFunction ||
              subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              console.log("message", message);

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            dispatch(setMessage(err, "error"));
            console.log("err", err);
          })
      : JobParameterService.createJobParameter([
          {
            jobID,
            instructionID,
            value: description,
          },
        ])
          .then(
            (response) => {
              // add the data created in the redux store
              dispatch(
                addJobParameter({
                  jobParameterName: "instruction",
                  title: instruction?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  value: description,
                })
              );
              dispatch(removeLoading());
              // navigate either to the next instruction or to the final page
              isLastFunction
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            dispatch(setMessage(err, "error"));
            console.log("err", err);
          });
  };

  //call the finishJob api, remove the redux store of the jobParameter array
  const saveJob = () => {
    if (isSavePopup.isLogin === false) {
      history.push(`/sub-processes-overview/T/${subProcess.processID}`);
    } else {
      history.push("/login");
    }
    setSubProcess(null);
    dispatch(resetJobParameter());
  };

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        if (e.target.tagName.toLowerCase() === "a") {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: true,
          });
        } else {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: false,
          });
        }
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Möchtest du aufhören?"
          Save={() => {
            saveJob();
            setSavePopup({ savePopup: false, isLogin: false });
          }}
          closeSavePopup={() =>
            setSavePopup({ savePopup: false, isLogin: false })
          }
          buttonText="Ja"
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="user-instruction">
            <div className="sub-process-function-name">
              <p>{subProcess.name}</p>
              <h3 className="sub-process-function-name-h3">
                {instruction?.title}
              </h3>
            </div>

            <div className="user-instruction-body">
              <p>{instruction?.description}</p>
            </div>

            <div className="back-next-buttons">
              <UserButton
                onClick={() => {
                  nextFunction === 1 &&
                  subProcess?.assignedFunctions.length !==
                    jobParametersArray?.length
                    ? saveJob()
                    : subProcess?.assignedFunctions.length ===
                      jobParametersArray?.length
                    ? history.push("/sub-processes-overview/final-page/")
                    : history.push(
                        "/sub-processes-overview/" +
                          subProcess?.assignedFunctions[nextFunction - 2]
                            ?.code +
                          "/" +
                          subProcess?.assignedFunctions[nextFunction - 2]
                            ?.subProcessFunctionAssignmentID
                      );
                }}
              >
                zurück
              </UserButton>
              <UserButton onClick={saveJobParameter}>
                {subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                  ? "speichern"
                  : "weiter"}
              </UserButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Instruction;
