//Combine Reducers
import JobReducer from "./jobReducer";
import UserReducer from "./userReducer";
import { combineReducers } from "redux";
import LoginReducer from "./authReducer";
import LicenseReducer from "./licenseReducer";
import MessageReducer from "./messageReducer";
import LoadingReducer from "./loadingReducer";
import LocationIPReducer from "./locationIPReducer";
import ProcessReducer from "./processReducer";
import LocationReducer from "./locationReducer";
import FunctionReducer from "./functionReducer";
import SubProcessesReducer from "./subProcessesReducer";
import JobParameterReducer from "./jobParameterReducer";
import PresetProcessReducer from "./presetProcessReducer";
import PresetSubProcessReducer from "./presetSubProcessReducer";

export default combineReducers({
  JobReducer,
  LoginReducer,
  MessageReducer,
  LocationReducer,
  LocationIPReducer,
  LicenseReducer,
  UserReducer,
  LoadingReducer,
  ProcessReducer,
  SubProcessesReducer,
  FunctionReducer,
  PresetProcessReducer,
  PresetSubProcessReducer,
  JobParameterReducer,
});
