import "./UserButton.css";

export interface UserButtonProps {
  type?: any;
  onClick?: any;
  disabled?: any;
  id?: any;
}

function UserButton(UserButtonProps: any) {
  return (
    <button
      id={UserButtonProps.id}
      className={
        UserButtonProps.disabled ? "user-button disabled" : "user-button"
      }
      type={UserButtonProps.type}
      onClick={UserButtonProps.onClick}
      disabled={UserButtonProps.disabled}
    >
      {UserButtonProps.children}
    </button>
  );
}

export default UserButton;
