import "./LicenseList.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLicenses } from "../../../Store/actions/licenseAction";

// components:
import License from "../License/License";

export interface LicenseListProps {}

const LicenseList: React.FC<LicenseListProps> = () => {
  const dispatch = useDispatch<any>();

  // redux state
  const licenses = useSelector((state: any) => state.LicenseReducer);

  //get all licenses from DB:
  useEffect(() => {
    dispatch(getAllLicenses());
  }, [dispatch]);

  return (
    <>
      <table className="licenses">
        <tbody>
          <tr>
            <th className="my-td-label-licenses">
              <h2>Standort</h2>
            </th>
            <th className="my-td-label-licenses">
              <h2>Gültig ab</h2>
            </th>
            <th className="my-td-label-licenses">
              <h2>Gültig bis</h2>
            </th>
            <th className="my-td-label-licenses">
              <h2>Anzahl</h2>
            </th>
            <th colSpan={2} className="my-td-label-licenses">
              <h2 className="h2-action">Aktion</h2>
            </th>
          </tr>

          {/* map through the license and display them in a table: */}
          {licenses.map((license: any, index: any) => (
            <License
              key={index}
              licenseID={license.licenseID}
              location={license.location && license.location.city}
              validFrom={license.validFrom}
              validTo={license.validTo}
              count={license.count}
              pathname="/create-edit-license/"
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LicenseList;
