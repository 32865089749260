import "./ManageUsers.css";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../Store/actions/messageAction";
import { Link } from "react-router-dom";

//components:
import UserList from "../UserList/UserList";
import Loader from "../../../Components/Loader/Loader";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
// import NewBackButtons from "./../../../Components/NewBackButtons/NewBackButtons";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

export interface ManageUsersProps {}

const ManageUsers: React.SFC<ManageUsersProps> = () => {
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  useEffect(() => {
    document.title = "JMS - Benutzer verwalten";
  }, []);

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/" />;
  }

  return (
    <>
      <SensrecAdminNavbar />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="manage-users-body">
            <div className="title-btns">
              <h1>Benutzer verwalten:</h1>
              <div className="topBtn">
                <Link to="/create-user">
                  <DefaultButton>Neu Erstellen</DefaultButton>
                </Link>
              </div>
            </div>

            <div>
              <UserList />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManageUsers;
