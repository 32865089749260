import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";

export const setMessage = (messageText: any, messageType: any) => ({
  type: SET_MESSAGE,
  payload: { messageText, messageType },
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
