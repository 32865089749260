import "./ScanFunction.css";
import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

// Actions
import { setMessage } from "../../../../Store/actions/messageAction";
import {
  setLoading,
  removeLoading,
} from "../../../../Store/actions/loadingAction";
// Validation
import { ScanSchema } from "../../../../Validations/AdminValidations";
// Service
import InstructionService from "../../../../Services/instructionService";

//Components:
import Loader from "../../../../Components/Loader/Loader";
import DeletePopup from "../../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "./../../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../../Components/MessageNotification/MessageNotification";
import SavePopup from "../../../../Components/SavePopup/SavePopup";

export interface ScanFunctionProps {
  subProcess: any;
}

const ScanFunction: React.FC<ScanFunctionProps> = (props) => {
  const dispatch = useDispatch<any>();

  //redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states

  //state for the scan names to be setted in the dropdown
  const [scanNames, setScanNames] = useState<any>();

  //store saved scan coming from DB in a state
  const [savedScan, setSavedScan] = useState<any>();
  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");
  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    instructionID: "",
  });

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  // local state Scan:
  const [scan, setScan] = useState({
    instructionID: "",
    title: "",
    description: "",
    scanItem: "",
    name: "",
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { id } = params;
  const subProcessFunctionID = id;

  // constant we send to the backend to check for name duplicates or no:
  const checkForNameDuplication = false;

  // we get the location and fill them in the dropdown, and if edit we get license by ID
  useEffect(() => {
    //call the setTimeout method to prevent the popup from appearing continuously
    setTimeout(
      () =>
        setSavePopup({
          savePopup: false,
        }),
      0
    );

    subProcessFunctionID &&
      getInstructionBySubProcessFunctionID(subProcessFunctionID);
    getScanNames();
    // eslint-disable-next-line
  }, [id]);

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number) => {
    dispatch(setLoading());
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then(
        (response) => {
          // response.data.length > 0 ? setIsAdd(false) : setIsAdd(true);
          dispatch(removeLoading());
          setScan(() => {
            return {
              instructionID: response.data[0]?.instructionID
                ? response.data[0]?.instructionID
                : "",
              title: response.data[0]?.title ? response.data[0]?.title : "",
              scanItem: response.data[0]?.name ? response.data[0]?.name : "",
              name: "",
              description: response.data[0]?.description
                ? response.data[0]?.description
                : "",
            };
          });
          setSavedScan(() => {
            return {
              instructionID: response.data[0]?.instructionID
                ? response.data[0]?.instructionID
                : "",
              title: response.data[0]?.title ? response.data[0]?.title : "",
              scanItem: response.data[0]?.name ? response.data[0]?.name : "",
              name: "",
              description: response.data[0]?.description
                ? response.data[0]?.description
                : "",
            };
          });
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function that get all instructions name with code SCAN
  const getScanNames = () => {
    dispatch(setLoading());
    InstructionService.getInstructionNames("SCAN")
      .then(
        (res) => {
          dispatch(removeLoading());
          let values = removeDuplicates(res.data, (item: any) => item.name);
          setScanNames(values);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((err) => {
        console.log(err);
        dispatch(setLoading());
      });
  };

  // function that filter the array base on name:
  function removeDuplicates(data: any, key: any) {
    return [...new Map(data?.map((item: any) => [key(item), item])).values()];
  }

  // handle the select items dropdown
  const handleInstructionDropDownChange = (event: any) => {
    const mySelectedName = event.target.value;
    setScan((previousState) => {
      return {
        ...previousState,
        scanItem: mySelectedName,
        // name: mySelectedName,
      };
    });
  };

  // handle the input title and description
  const handleInputChange = (event: any) => {
    const { id, value } = event.target;
   //use trim start function to prevent the user from pressing space 
    setScan((previousState) => {
      return {
        ...previousState,
        [id]: value.trimStart(),
      };
    });
  };

  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (!scan.instructionID) {
      createScan(fields, setSubmitting);
    } else {
      updateScan(fields, setSubmitting);
    }
  }

  // create Scan function:
  function createScan(fields: any, setSubmitting: any) {
    let { title, name, scanItem, description } = fields;
    // eslint-disable-next-line
    name ? (name = name) : (name = scanItem);
    dispatch(setLoading());
    InstructionService.createInstruction([
      {
        title,
        name,
        description,
        subProcessFunctionID,
        checkForNameDuplication,
        instructionItems: [],
      },
    ])
      .then(
        (data: any) => {
          dispatch(removeLoading());
          dispatch(
            setMessage("Scan instruction added successfully!", "success")
          );
          setScan((prev) => {
            return {
              ...prev,
              name: "",
            };
          });
          subProcessFunctionID &&
            getInstructionBySubProcessFunctionID(subProcessFunctionID);
          getScanNames();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        }
      )
      .catch((e: any) => {
        console.log(e);
        dispatch(removeLoading());
      });
  }

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });

        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);

  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (JSON.stringify(savedScan) === JSON.stringify(scan)) {
      //both states are same
      setIsStateChanged(false);
    } else {
      //states are not equal
      //Remove the condition since I added the firelds on the button
      setIsStateChanged(true);
    }
  };

  // update Scan function:
  function updateScan(fields: any, setSubmitting: any) {
    let { instructionID, title, name, scanItem, description } = fields;
    // eslint-disable-next-line
    name ? (name = name) : (name = scanItem);
    dispatch(setLoading());
    InstructionService.updateInstruction([
      {
        instructionID,
        title,
        name,
        description,
        subProcessFunctionID,
        checkForNameDuplication,
        instructionItems: [],
      },
    ])

      .then((response: any) => {
        dispatch(removeLoading());
        dispatch(setMessage("Eingaben erfolgreich gespeichert.", "success"));
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
        getScanNames();
      },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        })
      .catch((e: any) => {
        console.log(e);
        dispatch(removeLoading());
      });
  }

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleDelete = (id: any) => {
    // we check if we have id so we show the popup else only we remove it from the local state
    if (id) {
      setDeletePopup({
        deletePopup: true,
        instructionID: id,
      });
    } else {
      setScan({
        instructionID: "",
        title: "",
        description: "",
        scanItem: "",
        name: "",
      });
    }
  };

  //function that handle the delete scan instruction
  const deleteInstruction = (id: any) => {
    dispatch(setLoading());
    InstructionService.deleteInstruction(id)
      .then((res) => {
        dispatch(removeLoading());
        setDeletePopup({ deletePopup: false, instructionID: "" });
        dispatch(setMessage("Eingaben erfolgreich gelöscht.", "success"));
        subProcessFunctionID &&
          getInstructionBySubProcessFunctionID(subProcessFunctionID);
        getScanNames();
      },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // setDisabled(true);
          dispatch(removeLoading());
        })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("You are not an Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Sollen Ihre Eingaben zur Funktion Scan gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
            setSavePopup({ savePopup: false });
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}
      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Sollen Ihre Eingaben wirklich gelöscht werden?"
          Delete={() => deleteInstruction(isDeletePopup.instructionID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, instructionID: "" })
          }
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div ref={formRef}>
            <Formik
              initialValues={scan}
              validationSchema={ScanSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                isValid,
              }) => {
                //store errors in a stated to send as prop for save pop up
                setTimeout(() => {
                  setValidationError(errors);
                }, 0);
                return (
                  <>
                    <div className="evaluation-scan">
                     
                      <h1>Scan</h1>
                      <Form>
                        <div className="main-table">
                          <div className="first-table">
                            <table className="function-table-scan">
                              <tbody>
                                <tr>
                                  <td>
                                    <h2 className="scan-item">Scan-Item:</h2>
                                  </td>
                                  <td>
                                    <select
                                      className="scan-select"
                                      name="scanItem"
                                      onChange={handleInstructionDropDownChange}
                                      value={scan.scanItem}
                                    >
                                      <option value="">Item wählen</option>
                                      {scanNames
                                        ? scanNames.map(
                                            (scanName: any, index: any) => (
                                              <option
                                                key={index}
                                                value={scanName.name}
                                              >
                                                {scanName.name}
                                              </option>
                                            )
                                          )
                                        : null}
                                    </select>
                                    {/* {!values.scanItem && !values.name ? (
                                      <h6 className="new-license-error-message">
                                        Erforderlich!
                                      </h6>
                                    ) : null} */}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <p>oder</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <h2 className="neues-item"> Neues Item:</h2>
                                  </td>
                                  <td>
                                    <Field
                                      className="scan-field"
                                      id="name"
                                      name="name"
                                      value={scan.name}
                                      placeholder="Item eingeben"
                                      onChange={handleInputChange}
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="div"
                                      className="new-license-error-message"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="second-table">
                            <table className="function-table">
                              <tbody>
                                <tr>
                                  <td className="first-margin-scan">
                                    <h2>Überschrift:</h2>
                                  </td>
                                  <td>
                                    <Field
                                      className="scan-title"
                                      id="title"
                                      name="title"
                                      value={scan.title}
                                      placeholder="Überschrift einfügen"
                                      onChange={handleInputChange}
                                    />
                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="new-license-error-message"
                                    />
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td className="Überschrift-margin">
                                    <h2>Anweisungen:</h2>
                                  </td>
                                  <td>
                                    <Field
                                      as="textarea"
                                      className="scan-description"
                                      rows="5"
                                      id="description"
                                      name="description"
                                      value={scan.description}
                                      placeholder="Anweisung einfügen"
                                      type="textarea"
                                      onChange={handleInputChange}
                                    />
                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      className="new-license-error-message"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="reset-btn-scan">
                          <DefaultButton
                            type="reset"
                            onClick={() => handleDelete(values.instructionID)}
                            // onClick={() =>
                            //   setDeletePopup({
                            //     deletePopup: true,
                            //     instructionID: values.instructionID,
                            //   })
                            // }
                          >
                            zurücksetzen
                          </DefaultButton>
                          {/* replace isValid with field's values to check if there are scanItem and name field*/}
                          <DefaultButton
                            disabled={
                              (!isStateChanged ||
                              !scan.title ||!scan.scanItem ) && (!scan.name)                         }
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                        </div>
                      </Form>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default ScanFunction;
