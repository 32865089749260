import "./CountUnitPopup.css";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UnitService from "./../../../Services/unitService";
import { Formik, Form, Field, ErrorMessage } from "formik";

// Actions
import { setMessage } from "../../../Store/actions/messageAction";
import { removeLoading } from "../../../Store/actions/loadingAction";

// Validation
import { UnitSchema } from "../../../Validations/AdminValidations";
// Components:
import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import TrashIcon from "../../../Components/TrashIcon/TrashIcon";
// import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

export default function CountUnitPopup({
  closeCountUnitPopup,
}: {
  closeCountUnitPopup: any;
}) {
  const dispatch = useDispatch<any>();

  //redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  //local states
  // eslint-disable-next-line
 
  const [CountUnitPopup, setCountUnitPopup] = useState(true);

  // const [unit, setUnit] = useState({ name: "" });
  const [units, setUnits] = useState([{ unitID: "", name: "" }]);

  const unit ={ name: "" }
  // use effect that run at the first render to get all transportation units
  useEffect(() => {
    GetAllUnits();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // function that get all transportation units
  const GetAllUnits = () => {
    UnitService.getAllUnits()
      .then(
        (res) => {
          setUnits(res.data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  // create unit function:
  const CreateUnit = (fields: any, setSubmitting: any) => {
    // dispatch(setLoading());

    // distructuring the name the fields
    const { name } = fields;
    UnitService.createUnit({ name: name })
      .then(
        (res) => {
          GetAllUnits();
          dispatch(
            setMessage("Transporteinheit erfolgreich erstellt.", "success")
          );
         fields.name = ""
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  //function to delete a unit
  const deleteUnit = (id: any) => {
    //  dispatch(setLoading());
    UnitService.deleteUnit(id)
      .then(
        (res) => {
          dispatch(removeLoading());
          dispatch(
            setMessage("Transporteinheit erfolgreich gelöscht.!", "success")
          );
          GetAllUnits();
          setUnits([{name:"",unitID:""}])
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          // dispatch(removeLoading());
        }
      )
      .catch((err) => console.log(err));
  };

  const toggleModal = () => {
    setCountUnitPopup(!CountUnitPopup);
  };

  if (CountUnitPopup) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  // checking the user role to restrict some page
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {/* {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Unit"
          Delete={() => deleteUnit(isDeletePopup.unitID)}
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, unitID: "" })
          }
        />
      ) : null} */}

      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={unit}
            validationSchema={UnitSchema}
            onSubmit={CreateUnit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue,dirty,isValid }) => {
              return (
                <>
                  <Form className="create-license-form">
                    <div className="modal">
                      <div onClick={toggleModal} className="overlay"></div>
                      <div className="modal-content-unit">
                        <h1>Übersicht Transporteinheiten</h1>

                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Neue Transporteinheit:
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td
                                className="my-td change-password"
                                style={{ width: "20rem" }}
                              >
                                <Field
                                  id="name"
                                  name="name"
                                  type="text"
                                  placeholder="Transporteinheit einfügen"
                                  // ref={unitInputRef}

                                  // onChange={handleInputChange}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                              <td style={{ paddingLeft: "2rem" }}>
                                <DefaultButton type="submit" disabled={!(dirty && isValid)}>
                                  hinzufügen
                                </DefaultButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <h2>Bestehende Transporteinheiten</h2>
                              </td>
                            </tr>
                            {units ? (
                              units.map((unit: any, index: any) => {
                                return (
                                  <tr key={index}>
                                    <td className="trash">
                                      <Input
                                        value={unit.name}
                                        readOnly={true}
                                      />
                                      <TrashIcon
                                        id={unit.unitID}
                                        onClick={() => deleteUnit(unit.unitID)}
                                        // onClick={() =>
                                        //   setDeletePopup({
                                        //     deletePopup: true,
                                        //     unitID: unit.unitID,
                                        //   })
                                        // }
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <h2>
                                Bisher sind keine Transporteinheiten
                                gespeichert.
                              </h2>
                            )}
                          </tbody>
                        </table>

                        <div className="popup-btns-count">
                          {/* <DefaultButton type="submit">Speichern</DefaultButton> */}
                          <DefaultButton onClick={closeCountUnitPopup}>
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
