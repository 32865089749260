import "./Function.css";
import { Link } from "react-router-dom";

import DefaultButton from "../../../Components/DefaultButton/DefaultButton";

export interface FunctionProps {
  functionID: number;
  name: any;
  pathname: any;
}

const Function: React.FC<FunctionProps> = ({ functionID, name, pathname }) => {
  return (
    <>
      <tr>
        <td className="my-td-label-function">
          <h3>{name}</h3>
        </td>

        <td className="my-td-label-function">
          <Link to={pathname + functionID}>
            <DefaultButton>Ändern</DefaultButton>
          </Link>
        </td>
      </tr>
    </>
  );
};

export default Function;
