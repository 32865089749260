import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Ungültige E-Mail Adresse.")
    .max(100)
    .required("Erforderlich!"),

  password: Yup.string()
    // .min(8, "Password should be longer than 8 characters!")
    .max(100)
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])/,
    //   "Password must have 1 uppercase and 1 number and 1 character."
    // )
    .required("Erforderlich!"),
});
