import http from "./http-common";
import authHeader from "./authHeader";

const getFunction = (id: number) => {
  return http.get(`/Function/${id}`, { headers: authHeader() });
};

const getAllFunctions = () => {
  return http.get("/Function", { headers: authHeader() });
};

const createFunction = async (data: any) => {
  const res = await http.post("/Function", data, { headers: authHeader() });
  return res;
};

const updateFunction = (id: number, data: any) => {
  return http.put(`/Function/${id}`, data, { headers: authHeader() });
};

const deleteFunction = (id: number) => {
  return http.delete(`/Function/${id}`, { headers: authHeader() });
};

const FunctionService = {
  getFunction,
  getAllFunctions,
  createFunction,
  updateFunction,
  deleteFunction,
};

export default FunctionService;
