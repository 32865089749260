import { SET_JOB, REMOVE_JOB } from "./types";

export const setJob = (job: any) => (dispatch: any) => {
  dispatch({
    type: SET_JOB,
    payload: job,
  });
};

export const removeJob = (job: any) => (dispatch: any) => {
  dispatch({
    type: REMOVE_JOB,
  });
};
