/* eslint-disable eqeqeq */
import "./Count.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Services
import InstructionService from "../../../Services/instructionService";
import JobParameterService from "../../../Services/jobParameterService";

// Actions
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import {
  addJobParameter,
  resetJobParameter,
  updateJobParameterRedux,
} from "../../../Store/actions/jobParameterAction";
import { setMessage } from "../../../Store/actions/messageAction";

// components:
import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import UserButton from "../../../Components/UserButton/UserButton";

// Images:
import add from "../../../Assets/Images/add.png";
import minus from "../../../Assets/Images/minus.png";

function Count({
  subProcess,
  setSubProcess,
}: {
  subProcess: any;
  setSubProcess: any;
}) {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const jobParametersArray = useSelector(
    (state: any) => state.JobParameterReducer
  );
  const job = useSelector((state: any) => state.JobReducer);
  const jobID = job.jobID;

  //local state:
  const [counts, setCounts] = useState<any>([]);
  //array that contain the instruction IDs to be sent to the filter API
  const [countInstructionIDs, setCountInstructionIDs] = useState<any>([]);

  const [myCounts, setMyCounts] = useState<any>([
    {
      instructionID: "",
      name: "",
      instructionUnits: [],
      unitID: "",
      count: "",
      countInstructionIDs: countInstructionIDs,
    },
  ]);
  // state to save the backup of the count
  const [countsBackup, setCountsBackup] = useState([]);
  // state to save the backup of the unitID
  const [unitIDBackup, setUnitIDBackup] = useState([]);
  // state to save the backup of the instruction
  const [instructionBackup, setInstructionBackup] = useState([
    {
      instructionID: "",
      name: "",
      instructionUnits: [],
    },
  ]);

  // if we are in update mode this will be the job parameter IDs
  const [jobParameterIDs, setJobParameterIDs] = useState<any>([]);

  const [isLastFunction, setLastFunction] = useState<any>(false);
  const [nextFunction, setNextFunction] = useState<any>("");
  //popup state
  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
    isLogin: false,
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { fun, id } = params;
  const code = fun.toUpperCase();

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  const nextFunctionFun = () => {
    subProcess?.assignedFunctions?.map((subProcessData: any, index: any) => {
      if (
        subProcessData.code === code &&
        subProcessData.subProcessFunctionAssignmentID == id
      ) {
        if (index === subProcess?.assignedFunctions.length - 1) {
          setLastFunction(true);
          setNextFunction(index + 1);
        } else {
          setNextFunction(index + 1);
        }
      }
      return nextFunction;
    });
  };

  // get the job parameter by filter mean: by jobID and InstructionID
  const getJobParameterByFilter = () => {
    JobParameterService.getJobParametersByMultipleFilter(
      jobID,
      countInstructionIDs
    )
      .then((response: any) => {
        // temp array for job parameters IDs
        let jobParametersIDs = [] as any;
        response.data?.map((item: any) => {
          return jobParametersIDs.push(item.jobParameterID);
        });
        setJobParameterIDs(jobParametersIDs);
        setMyCounts(jobParametersArray[nextFunction - 1]);
        // get the backup of the state and the save them
        let countsBackup: any = [];
        let unitIDBackup: any = [];
        let instructionBackup: any = [];
        // eslint-disable-next-line array-callback-return
        jobParametersArray[nextFunction - 1].map((job: any) => {
          let instructionObject = {
            instructionID: "",
            name: "",
            instructionUnits: [] as any,
          };
          instructionObject.name = job.name;
          instructionObject.instructionID = job.instructionID;
          // eslint-disable-next-line array-callback-return
          job.instructionUnits.map((unit: any) => {
            instructionObject.instructionUnits.push(unit);
          });
          countsBackup.push(job.count);
          unitIDBackup.push(job.unitID);
          instructionBackup.push(instructionObject);
        });
        setCountsBackup(countsBackup);
        setUnitIDBackup(unitIDBackup);
        setInstructionBackup(instructionBackup);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  // console.log(
  //   "jobParametersArray[nextFunction - 1]",
  //   jobParametersArray[nextFunction - 1]
  // );
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    dispatch(removeLoading());
    nextFunctionFun();
    nextFunction &&
      getInstructionBySubProcessFunctionID(id, { signal: signal });

    // getInstructionBySubProcessFunctionID(
    //   subProcess?.assignedFunctions[nextFunction - 1]
    //     ?.subProcessFunctionAssignmentID,
    //   { signal: signal }
    // );

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [subProcess, nextFunction, dispatch, id]);

  useEffect(() => {
    // get job parameter by jobID and instruction ID
    //to save the data of the fist count function
    jobParametersArray[nextFunction - 1] &&
      jobParametersArray[nextFunction - 1][0]?.jobID === jobID &&
      countInstructionIDs?.length > 0 &&
      jobParametersArray[nextFunction - 1][0]?.countInstructionIDs?.length ===
        countInstructionIDs.length &&
      getJobParameterByFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countInstructionIDs?.length]);

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number, signal: any) => {
    // dispatch(setLoading());
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response: any) => {
        const countInstructionIDsArray = [] as any;
        for (let i = 0; i < response.data.length; i++) {
          countInstructionIDsArray.push(response.data[i]?.instructionID);
          setCountInstructionIDs(countInstructionIDsArray);
        }
        //add a condition to inform the user that there are no data added by the user
        if (response?.data?.length > 0) {
          setCounts(response.data);
        } else {
          dispatch(setMessage("No data have been added by the admin", "error"));
        }
        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  //function that add an empty sub-process to the state to be created when clicking on the + button:
  const handleAddFields = (index: any) => {
    setMyCounts([
      ...myCounts,
      {
        instructionID: "",
        name: "",
        instructionUnits: [] as any,
        unitID: "",
        countInstructionIDs: countInstructionIDs,
      },
    ]);
  };

  //function that delete a sub-process from the state to be deleted also from DB when clicking on the + button:
  const handleRemoveFields = (index: any, id: any) => {
    // we check if we have id so we show the popup else only we remove it from the local state
    // setApproved(true);
    // if (id) {
    //   setDeletePopup({
    //     deletePopup: true,
    //     instructionID: id,
    //   });
    // } else {
    const values = [...myCounts];
    values.splice(index, 1);
    setMyCounts(values);
    // }

    if (myCounts.length === 1) {
      setMyCounts([
        {
          instructionID: "",
          name: "",
          instructionUnits: "",
          unitID: "",
          count: "",
          countInstructionIDs: countInstructionIDs,
        },
      ]);
    }
  };

  const handleDescriptionNameDropDownChange = (event: any, index: any) => {
    let instructionID = event.target.value;

    let temp = [...myCounts];
    let unitIDs: any;
    // eslint-disable-next-line array-callback-return
    counts.map((count: any) => {
      // eslint-disable-next-line eqeqeq
      if (count.instructionID == instructionID) {
        unitIDs = count.instructionUnits;
        temp[index].name = count.name;
        temp[index].instructionID = count.instructionID;
        temp[index].instructionUnits = unitIDs;
        // temp[index].count = temp[index].count;
        temp[index].unitID = unitIDs[0]?.unitID;

        temp[index].countInstructionIDs = countInstructionIDs;
        setMyCounts(temp);
        return unitIDs;
      }
    });
  };

  const handleCountInput = (event: any, index: any) => {
    let count = event.target.value;
    let temp = [...myCounts];
    temp[index].count = count;
    setMyCounts(temp);
  };

  // restore the state with the initial value of the count
  const returnTheOldVersionOfCount = () => {
    let temp = [...myCounts];
    // eslint-disable-next-line array-callback-return
    countsBackup.map((count: any, index: any) => {
      temp[index].count = count;
    });
    setMyCounts(temp);
  };

  // restore the state with the initial value of the unitID
  const returnTheOldVersionOfUnitID = () => {
    let temp = [...myCounts];
    // eslint-disable-next-line array-callback-return
    unitIDBackup.map((unitID: any, index: any) => {
      temp[index].unitID = unitID;
    });
    setMyCounts(temp);
  };

  // restore the state with initial value of the instruction
  const returnTheOldVersionOfInstructions = () => {
    let temp = [...myCounts];
    // eslint-disable-next-line array-callback-return
    instructionBackup.map((instruction: any, index: any) => {
      temp[index].instructionID = instruction.instructionID;
      temp[index].name = instruction.name;
      temp[index].instructionUnits = instruction.instructionUnits;
    });
    setMyCounts(temp);
  };

  const handleTransportationUnitDropDownChange = (event: any, index: any) => {
    let transportationUnitID = event.target.value;
    let temp = [...myCounts];
    temp[index].unitID = transportationUnitID;
    setMyCounts(temp);
  };

  let finalArray: any = [];
  let finalArray2: any = [];
  let disabled;

  for (let i = 0; i < myCounts?.length; i++) {
    if (jobParameterIDs.length > 0) {
      finalArray.push({
        jobParameterID: jobParameterIDs[i],
        jobID: jobID,
        unitID: myCounts[i]?.unitID,
        instructionID: myCounts[i]?.instructionID,
        value: myCounts[i]?.count,
        //add the path to be used in the final page on edit button
        path: currentRoute,
      });
      finalArray2.push({
        jobParameterName: "count",
        title: counts[0]?.title,
        instructionID: myCounts[i]?.instructionID,
        name: myCounts[i].name,
        instructionUnits: myCounts[i]?.instructionUnits,
        unitID: myCounts[i]?.unitID,
        count: myCounts[i]?.count,
        jobID: jobID,
        countInstructionIDs: countInstructionIDs,
        path: currentRoute,
      });
    } else {
      finalArray.push({
        name: myCounts[i]?.name,
        jobID: jobID,
        unitID: myCounts[i]?.unitID,
        instructionID: myCounts[i]?.instructionID,
        value: myCounts[i]?.count,
        path: currentRoute,
      });
      finalArray2.push({
        jobParameterName: "count",
        title: counts[0]?.title,
        instructionID: myCounts[i]?.instructionID,
        name: myCounts[i].name,
        unitID: myCounts[i]?.unitID,
        instructionUnits: myCounts[i]?.instructionUnits,
        count: myCounts[i]?.count,
        jobID: jobID,
        countInstructionIDs: countInstructionIDs,
        path: currentRoute,
      });
    }

    !myCounts[i].count || !myCounts[i].name
      ? (disabled = true)
      : (disabled = false);
  }

  const saveJobParameter = () => {
    dispatch(setLoading());

    jobParametersArray[0] &&
    (jobParametersArray[0]?.jobID === jobID ||
      jobParametersArray[0][0]?.jobID === jobID) &&
    jobParameterIDs?.length > 0
      ? JobParameterService.updateJobParameter(finalArray)
          .then(
            (response) => {
              dispatch(removeLoading());
              dispatch(updateJobParameterRedux(finalArray2));

              isLastFunction ||
              subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            nextFunctionFun();
            history.push(
              "/sub-processes-overview/" +
                subProcess?.assignedFunctions[nextFunction - 1]?.code +
                "/" +
                subProcess?.assignedFunctions[nextFunction - 1]
                  ?.subProcessFunctionAssignmentID
            );
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          })
      : JobParameterService.createJobParameter(finalArray)
          .then(
            (response) => {
              dispatch(addJobParameter(finalArray2));

              dispatch(removeLoading());
              isLastFunction
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
              isLastFunction
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            nextFunctionFun();
            history.push(
              "/sub-processes-overview/" +
                subProcess?.assignedFunctions[nextFunction - 1]?.code +
                "/" +
                subProcess?.assignedFunctions[nextFunction - 1]
                  ?.subProcessFunctionAssignmentID
            );
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          });
  };

  const saveJob = () => {
      if (isSavePopup.isLogin === false) {
        history.push(`/sub-processes-overview/T/${subProcess.processID}`);
      } else {
        history.push("/login");
      }
    setSubProcess(null);
    dispatch(resetJobParameter());
  };

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        if (e.target.tagName.toLowerCase() === "a") {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: true,
          });
        } else {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: false,
          });
        }
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Möchtest du aufhören?"
          Save={() => {
            saveJob();
            setSavePopup({ savePopup: false ,isLogin:false});
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false ,isLogin:false })}
          buttonText="Ja"
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="sub-process-function-name">
              <p>{subProcess.name}</p>
              <h3 className="sub-process-function-name-h3">
                {counts && counts[0]?.title}
              </h3>
            </div>

            <div>
              <p>{counts && counts[0]?.description}</p>
            </div>
            <div className="user-count-body">
              {/* table headers */}
              <table className="user-count-table">
                <tbody>
                  <tr>
                    <th className="Beschreibung">Beschreibung</th>
                    <th className="Transporteinheit">Transporteinheit</th>
                    <th className="Anzahl">Anzahl</th>
                  </tr>

                  {myCounts?.map((count: any, index: any) => {
                    return (
                      <>
                        <tr>
                          {/* description Name */}
                          <td>
                            <select
                              name="items"
                              onChange={(event) =>
                                handleDescriptionNameDropDownChange(
                                  event,
                                  index
                                )
                              }
                              value={
                                myCounts[index].instructionID
                                  ? myCounts[index].instructionID
                                  : "  Beschreibung wählen"
                              }
                            >
                              <option id="" value="">
                                Beschreibung wählen
                              </option>
                              {counts
                                ? counts.map((val: any, index: any) => (
                                    <option
                                      key={index}
                                      id={val.instructionID}
                                      value={val.instructionID}
                                    >
                                      {val.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </td>

                          {/* transportation Units */}
                          <td>
                            <select
                              name="items"
                              onChange={(e: any) =>
                                handleTransportationUnitDropDownChange(e, index)
                              }
                              value={
                                myCounts[index].unitID
                                  ? myCounts[index].unitID
                                  : myCounts[index]?.instructionUnits[0]?.unitID
                              }
                            >
                              {myCounts[index]?.instructionUnits
                                ? myCounts[index]?.instructionUnits?.map(
                                    (val: any, index: any) => (
                                      <option
                                        key={index}
                                        id={val.unitID}
                                        value={val.unitID}
                                      >
                                        {val.name}
                                      </option>
                                    )
                                  )
                                : null}
                            </select>
                          </td>

                          {/* Count: Anzahl */}
                          <td className="user-count-count">
                            <Input
                              name="count"
                              value={myCounts[index].count}
                              placeholder="Anzahl"
                              onChange={(e: any) => handleCountInput(e, index)}
                            />
                          </td>

                          <td className="user-count-icons">
                            {/* {(myCounts[index].name &&
                              myCounts[index].name?.length !== 0) ||
                            index !== 0 ? ( */}
                            <img
                              src={minus}
                              className="icon-img"
                              alt="remove"
                              onClick={() =>
                                handleRemoveFields(
                                  index,
                                  myCounts[index].instructionID
                                )
                              }
                            />
                            {/* ) : null} */}
                          </td>
                        </tr>
                        <tr>
                          <td className="user-count-icons">
                            {myCounts.length === index + 1 &&
                            myCounts[index].name?.length !== 0 &&
                            myCounts[index].count ? (
                              <img
                                className="icon-img2"
                                src={add}
                                alt="add"
                                onClick={() => handleAddFields(index)}
                              />
                            ) : null}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="back-next-buttons">
              <UserButton
                onClick={() => {
                  // on the back button we restore the state with its initial value to force the redux to get back to its initial values
                  returnTheOldVersionOfCount();
                  returnTheOldVersionOfUnitID();
                  returnTheOldVersionOfInstructions();
                  nextFunction === 1 &&
                  subProcess?.assignedFunctions.length !==
                    jobParametersArray?.length
                    ? setSavePopup({
                      savePopup: true,
                       isLogin:false
                      })
                    : subProcess?.assignedFunctions.length ===
                      jobParametersArray?.length
                    ? history.push("/sub-processes-overview/final-page/")
                    : history.push(
                        "/sub-processes-overview/" +
                          subProcess?.assignedFunctions[nextFunction - 2]
                            ?.code +
                          "/" +
                          subProcess?.assignedFunctions[nextFunction - 2]
                            ?.subProcessFunctionAssignmentID
                      );
                }}
              >
                zurück
              </UserButton>
              <UserButton disabled={disabled} onClick={saveJobParameter}>
                {subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                  ? "speichern"
                  : "weiter"}
              </UserButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Count;
