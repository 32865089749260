import { CREATE_LICENSE, GET_LICENSES, DELETE_LICENSE } from "../actions/types";

const initialState = [] as any;

function LicenseReducer(licenses = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LICENSE:
      return [...licenses, payload];

    case GET_LICENSES:
      return payload;

    case DELETE_LICENSE:
      return licenses.filter(({ id }: { id: any }) => id !== payload.id);

    default:
      return licenses;
  }
}

export default LicenseReducer;
