import _ from "lodash";
import "./CreateEditSubProcess.css";
import { Redirect, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// actions
import {
  upsertSubProcess,
  deleteSubProcess,
} from "../../../Store/actions/subProcessesAction";
import { setMessage } from "./../../../Store/actions/messageAction";

// services
import ImageService from "../../../Services/imageService";
import SubProcessService from "../../../Services/subProcessService";
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";

// validations
// import { SubProcessSchema } from "../../../Validations/AdminValidations";

// Components:
import SavePopup from "../../../Components/SavePopup/SavePopup";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
// import DraggableSubProcess from "../DraggableSubProcess/DraggableSubProcess";
import SubProcessPopup from "../../../Components/SubProcessPopup/SubProcessPopup";

//Images:
import edit from "../../../Assets/Images/edit.png";
import Image from "../../../Assets/Images/image.png";
import TrashIcon from "../../../Components/TrashIcon/TrashIcon";
import { setSubPresetProcess } from "../../../Store/actions/presetSubProcessAction";

function CreateEditSubProcess({
  processID,
  formRef,
}: {
  processID: any;
  formRef: any;
}) {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  //local states
  //sub-process initial state:
  const initialFieldValues = [
    {
      subProcessID: "",
      name: "",
      order: "1" as any,
      imageID: "",
      image: "",
      processID: processID,
    },
  ];

  const [subProcesses, addSubProcess] = useState(initialFieldValues);

  const [subProcessImages, setSubProcessImages] = useState<any>();

  //store saved sub processes coming from DB in a state
  const [savedSubProcess, setSavedSubProcess] = useState<any>();

  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);

  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");

  // check if validation errors  exits
  // const [isValidationError, setValidationError] = useState<any>();

  const [approved, setApproved] = useState(true);

  const [isDeletePopup, setDeletePopup] = useState({
    deletePopup: false,
    index: "",
    subProcessID: "",
  });

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  const [isSubProcessPopup, setSubProcessPopup] = useState({
    subProcessPopup: false,
    subProcessID: "",
    processID: "",
    image: "",
    name: "",
    order: 1,
    label: "",
  });

  // to get reference to the used div and then check if the click is inside or outside
  // const formRef = React.useRef<HTMLDivElement>(null);

  // function to delete a process:
  const DeleteSubProcess = (index: any, id: any) => {
    //here we remove it from the state:
    const values = [...subProcesses];
    values.splice(index, 1);
    // iterate inside the subProcesses array to fix the order after deleting one of the subProcesses
    for (let i = 0; i < subProcesses.length - 1; i++) {
      values[i].order = i + 1;
    }
    addSubProcess(values);

    // if we don't have any subProcess we re show an empty one:
    if (subProcesses.length === 1) {
      addSubProcess([
        {
          subProcessID: "",
          name: "",
          order: "1",
          imageID: "",
          image: "",
          processID: processID,
        },
      ]);
    }

    if (subProcesses.length === 1) {
      //call the delete subProcess action:
      dispatch(deleteSubProcess(id))
        .then(() => {
          const abortController = new AbortController();
          const signal = abortController.signal;

          setDeletePopup({ deletePopup: false, index: "", subProcessID: "" });
          getSubProcessesByProcessID(processID, { signal: signal });
          dispatch(
            setMessage("Der Teilprozess wurde erfolgreich gelöscht.", "success")
          );
        })
        .catch((e: any) => {
          dispatch(setMessage(e.message, "error"));
          console.log(e);
        });
    } else {
      for (let i = 0; i < subProcesses.length; i++) {
        setApproved(true);
        if (!subProcesses[i].name) {
          dispatch(setMessage("SubProcess name is empty!", "error"));
          // setDisabled(true);
          dispatch(removeLoading());
          return setApproved(false);
        }
        dispatch(removeLoading());
      }

      if (approved) {
        var data = new FormData();
        for (let i = 0; i < subProcesses.length; i++) {
          data.append(`models[${i}].name`, subProcesses[i].name);
          data.append(`models[${i}].order`, subProcesses[i].order);
          data.append(`models[${i}].processID`, subProcesses[i].processID);
          data.append(
            `models[${i}].subProcessID`,
            subProcesses[i].subProcessID
          );
          data.append(`models[${i}].image`, subProcesses[i].imageID);
        }

        dispatch(upsertSubProcess(data)).then(() => {
          //call the delete subProcess action:
          dispatch(deleteSubProcess(id))
            .then(() => {
              setDeletePopup({
                deletePopup: false,
                index: "",
                subProcessID: "",
              });
              const abortController = new AbortController();
              const signal = abortController.signal;

              getSubProcessesByProcessID(processID, { signal: signal });
              dispatch(
                setMessage(
                  "Der Teilprozess wurde erfolgreich gelöscht.",
                  "success"
                )
              );
            })
            .catch((e: any) => {
              dispatch(setMessage(e.message, "error"));
              console.log(e);
            });
        });
      }
    }
  };

  //getting all sub-processes related to the processID of the main process:
  const getSubProcessesByProcessID = (id: string, signal: any) => {
    SubProcessService.getSubProcessesByProcessID(id).then((response) => {
      //checking if we have subProcess in the DB we add them to the state and show them:
      if (response.data.length > 0) {
        setSavedSubProcess(
          JSON.stringify(
            response.data.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
          )
        );
        addSubProcess(response.data);
        getImages(response.data);
      } else {
        // if we don't have also we show an empty one:
        addSubProcess([
          {
            subProcessID: "",
            name: "",
            order: "1",
            imageID: "",
            image: "",
            processID: processID,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    getSubProcessesByProcessID(processID, { signal: signal });
    document.title = "JMS - Teilprozesse bearbeiten";

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [processID, dispatch]);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, [isSubProcessPopup.subProcessID]);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  // function to get all images that take images IDs
  function getImages(data: any) {
    if (typeof data != "undefined") {
      let imageIDs = [];
      for (let item of data) {
        if (typeof item.imageID != "undefined") {
          imageIDs.push(item.imageID);
        }
      }
      ImageService.getAllImages(imageIDs, data)
        .then((res) => {
          setSubProcessImages(res.data);
        })
        .catch((e: any) => {
          console.log(e);
        });
      // }
    }
  }

  // sorting the sub process array according to the order attribute
  const sortedSubProcesses = subProcesses.sort((a, b) =>
    a.order > b.order ? 1 : -1
  );

  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 1);
  const CheckIfStateChanged = () => {
    //get key values we need from the changing sub processes state to compare with saved state
    let subProcessesObjs: any;
    if (subProcesses !== undefined)
      subProcessesObjs = subProcesses.map(function (obj: any) {
        return {
          subProcessID: obj.subProcessID,
          order: obj.order,
          name: obj.name,
          imageID: obj.imageID,
          processID: obj.processID,
          assignedFunctions: obj.assignedFunctions,
        };
      });

    //compare state coming from DB with our state
    if (subProcessesObjs !== undefined)
      if (_.isEqual(JSON.stringify(subProcessesObjs), savedSubProcess)) {
        //both states are same

        setIsStateChanged(false);

        //this is to avoid the save popup if we have no sub processes
      } else if (subProcessesObjs[0].name === "") {
        //states are not equal
        setIsStateChanged(false);
      } else {
        setIsStateChanged(true);
      }
  };

  // call the onSubmit function on the save popup to save the order of after changing the subprocess
  const onSubmit = () => {
    // e.preventDefault();
    dispatch(setLoading());

    for (let i = 0; i < subProcesses.length; i++) {
      setApproved(true);
      if (!subProcesses[i].name) {
        dispatch(setMessage("SubProcess name is empty!", "error"));
        // setDisabled(true);
        dispatch(removeLoading());
        return setApproved(false);
      }
      dispatch(removeLoading());
    }

    if (approved) {
      var data = new FormData();
      for (let i = 0; i < subProcesses.length; i++) {
        data.append(`models[${i}].name`, subProcesses[i].name);
        data.append(`models[${i}].order`, subProcesses[i].order);
        data.append(`models[${i}].processID`, subProcesses[i].processID);
        data.append(`models[${i}].subProcessID`, subProcesses[i].subProcessID);
        data.append(`models[${i}].image`, subProcesses[i].imageID);
      }

      dispatch(upsertSubProcess(data));
    }
  };

  // override the style of the drag and drop library
  // function getStyle(style: any, snapshot: any) {
  //   if (!snapshot.isDropAnimating) {
  //     return style;
  //   }

  //   // patching the existing style
  //   return {
  //     ...style,
  //     transitionDuration: `0.001s`,
  //   };
  // }

  return (
    <>
      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Soll der Teilprozess gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            onSubmit();
            setSavePopup({ savePopup: false });
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          // errors={isValidationError}
        />
      ) : null}

      {isDeletePopup.deletePopup ? (
        <DeletePopup
          label="Soll dieser Teilprozess wirklich gelöscht werden?"
          Delete={() =>
            DeleteSubProcess(isDeletePopup.index, isDeletePopup.subProcessID)
          }
          closeDeletePopup={() =>
            setDeletePopup({ deletePopup: false, index: "", subProcessID: "" })
          }
        />
      ) : null}

      {isSubProcessPopup.subProcessPopup ? (
        <SubProcessPopup
          label={isSubProcessPopup.label}
          subProcessID={isSubProcessPopup.subProcessID}
          processID={processID}
          order={isSubProcessPopup.order}
          name={isSubProcessPopup.name}
          image={isSubProcessPopup.image}
          Save={() => {
            setSavePopup({ savePopup: false });
          }}
          closeSubProcessPopup={() =>
            setSubProcessPopup({
              subProcessPopup: false,
              subProcessID: "",
              processID: "",
              order: isSubProcessPopup.order,
              name: "",
              image: "",
              label: "",
            })
          }
        />
      ) : null}
      <>
        <div>
          <h1>Teilprozesse:</h1>

          {/* react beautiful dnd library context: */}

          <div>
            <div className="overview-sub-processes">
              {sortedSubProcesses.length === 1 &&
              !sortedSubProcesses[0].subProcessID ? (
                <h1 className="no-subProcesses-indicator">
                  No Sub Processes Found
                </h1>
              ) : (
                sortedSubProcesses.map((subProcess, index) => (
                  <div>
                    {subProcessImages
                      ?.filter(
                        (item: any) => item.imageID === subProcess.imageID
                      )
                      .map(function (image: any) {
                        subProcess.image = image.image;

                        return null;
                      })}
                    <div>
                      <div className="one-sub-process">
                        <h4
                          onClick={() => {
                            history.push("/assign-functions");
                            dispatch(
                              setSubPresetProcess({
                                presetSubProcessID: subProcess.subProcessID,
                                presetSubProcessName: "",
                              })
                            );
                          }}
                          className="overview-page-process-title"
                        >
                          {subProcess.name}
                        </h4>

                        <img
                          onClick={() => {
                            history.push("/assign-functions");
                            dispatch(
                              setSubPresetProcess({
                                presetSubProcessID: subProcess.subProcessID,
                                presetSubProcessName: "",
                              })
                            );
                          }}
                          id="test"
                          className="one-process-img"
                          src={
                            subProcess.image
                              ? "data:image/jpeg;base64," + subProcess.image
                              : Image
                          }
                          alt="add"
                        />

                        <div className="one-process-icons-container">
                          <img
                            className="icon-edit"
                            src={edit}
                            alt="edit"
                            onClick={() => {
                              setSubProcessPopup({
                                subProcessPopup: true,
                                subProcessID: subProcess.subProcessID,
                                processID: processID,
                                image: subProcess.image,
                                name: subProcess.name,
                                order: subProcess.order,
                                label: "TeilProzess bearbeiten",
                              });
                            }}
                          />
                          <TrashIcon
                            id={subProcess.subProcessID}
                            onClick={() => {
                              setDeletePopup({
                                deletePopup: true,
                                subProcessID: subProcess.subProcessID,
                                index: (subProcesses?.length - 2) as any,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="save-btn-subprocess">
            <DefaultButton
              onClick={() => {
                setSubProcessPopup({
                  subProcessPopup: true,
                  subProcessID: "",
                  processID: processID,
                  name: "",
                  order: subProcesses?.length + 1,
                  image: "",
                  label: "Neuen Teilprozess erstellen",
                });
              }}
            >
              Neuer TeilProzess
            </DefaultButton>
          </div>
        </div>
      </>
    </>
  );
}

export default CreateEditSubProcess;
