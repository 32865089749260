import "./SystemSetting.css";
import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { setMessage } from "./../../../Store/actions/messageAction";
import { getAllLocationIP } from "../../../Store/actions/locationIPAction";

// Services
import locationIpService from "../../../Services/locationIpService";

// Components:
import IPAddress from "../IpAddress/IpAddress";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import Loader from "../../../Components/Loader/Loader";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

export interface SystemSettingProps {}

const SystemSetting: React.FC<SystemSettingProps> = () => {
  const dispatch = useDispatch<any>();
  const { user } = useSelector((state: any) => state.LoginReducer);
  const IpAddress = useSelector((state: any) => state.LocationIPReducer);
  const loginDetails = useSelector((state: any) => state.LoginReducer.user);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local State
  // store the count in a state to compare it with the loginDetailsCount
  const [ipAddressCount, setIpAddressCount] = useState<any>();

  // redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  useEffect(() => {
    document.title = "JMS - Systemeinstellung";
  }, []);

  useEffect(() => {
    ipAddressCountFunction();
    dispatch(getAllLocationIP());
  }, [dispatch]);

  const ipAddressCountFunction = () => {
    locationIpService
      .getAllLocationIP()
      .then((response: any) => {
        const ipAddressLength = response.data.length;
        setIpAddressCount(ipAddressLength);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      <AdminNavbar />

      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <div className="system-setting-body">
          <div className="system-settings-headers-buttons">
            <h1>IP-Adressen im {loginDetails.locationName}</h1>
            <h2>
              Anzahl Lizenzen: {ipAddressCount} von{" "}
              {loginDetails.licensesCount ? loginDetails.licensesCount : "NAN"}
            </h2>
            {loginDetails.isExpired && (
              <h2 style={{ color: "red", fontWeight: "bold" }}>
                Die Lizenzen sind abgelaufen
              </h2>
            )}

            <div className="topBtn">
              {ipAddressCount < loginDetails.licensesCount &&
              !isNaN(loginDetails.licensesCount) &&
              !loginDetails.isExpired ? (
                <Link to="/create-edit-ip-address">
                  <DefaultButton>Neue IP-Adressen</DefaultButton>
                </Link>
              ) : (
                <>
                  <DefaultButton
                    disabled={
                      ipAddressCount >= loginDetails.licensesCount ||
                      isNaN(loginDetails.licensesCount) ||
                      loginDetails.isExpired
                    }
                  >
                    Neue IP-Adressen
                  </DefaultButton>
                  <div className="maximum-number-of-licenses">
                    <h6 style={{ color: "red" }}>
                      {isNaN(loginDetails.licensesCount)
                        ? "You don't have licenses!"
                        : loginDetails.isExpired
                        ? "Die Lizenzen sind abgelaufen"
                        : "Maximale Anzahl Lizenzen erreicht"}
                    </h6>
                  </div>
                </>
              )}
            </div>
            <table className="locations">
              <tbody>
                <tr>
                  <th className="my-td-label-function">
                    <h2>Ip-Address</h2>
                  </th>
                  <th colSpan={2} className="my-td-label-function">
                    <h2>Aktion</h2>
                  </th>
                </tr>

                {/* map through the IP-Addresses and display them in a table: */}
                {IpAddress.map((ip: any, index: number) => (
                  <IPAddress
                    key={index}
                    LocationIpID={ip?.locationIpID}
                    ipAddress={ip?.ip}
                    setIpAddressCount={setIpAddressCount}
                    ipAddressCount={ipAddressCount}
                    pathname="/create-edit-ip-address/"
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SystemSetting;
