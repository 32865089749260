import {
  ADD_JOB_PARAMETER,
  UPDATE_JOB_PARAMETER,
  RESET_JOB_PARAMETER,
} from "../actions/types";

const initialState = [] as any;

const JobParameterReducer = (jobParameters = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_JOB_PARAMETER:
      // add the payload (new job parameter) to the array of parameters
      return [...jobParameters, payload];

    case UPDATE_JOB_PARAMETER:
      // console.log("payload", payload);

      // take a copy of the redux array
      let updatedJobParameters = [...jobParameters];
      // console.log("updatedJobParameters", updatedJobParameters);
      // eslint-disable-next-line array-callback-return
      updatedJobParameters.map((item: any, index: any) => {
        if (item?.length === 1) {
          if (item[0]?.title === payload[0]?.title) {
            updatedJobParameters[index] = payload;
          }
        } else {
          if (item.instructionID === payload.instructionID) {
            updatedJobParameters[index] = payload;
          }
          // // find the index for the oject we need to update
          // let index = jobParameters.findIndex((jobParameter: any) => {
          //   console.log(" jobParameter", jobParameter);
          //   jobParameter.instructionID === payload.instructionID;
          // });
          // console.log(" index", index);
          // // if there is an index so we find the object
          // if (index > -1) {
          //   // we add override the old object with the payload or the updated one
          //   updatedJobParameters[index] = payload;
          // }
        }
      });

      // then we return the new array
      return updatedJobParameters;
    case RESET_JOB_PARAMETER:
      return [];
    default:
      return jobParameters;
  }
};

export default JobParameterReducer;
