import {
  CREATE_PROCESS,
  GET_PROCESSES,
  GET_ONE_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
} from "./types";

import ProcessService from "../../Services/processService";
import { setMessage } from "../actions/messageAction";
import { setLoading, removeLoading } from "../actions/loadingAction";

export const createProcess = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    await ProcessService.createProcess(data).then(
      (data) => {
        dispatch({
          type: CREATE_PROCESS,
          payload: data,
        });
        dispatch(removeLoading());
        // dispatch(
        //   setMessage("Der Prozess wurde erfolgreich erstellt.", "success")
        // );

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // dispatch(removeLoading());
        // dispatch(setMessage(message, "error"));

        return Promise.reject(message);
      }
    );
  } catch (err: any) {
    dispatch(removeLoading());
    // dispatch(setMessage(err, "error"));

    return Promise.reject(err);
  }
};

export const getAllProcesses = () => async (dispatch: any) => {
  try {
    // dispatch(setLoading());

    await ProcessService.getAllProcesses().then(
      (res) => {
        dispatch({
          type: GET_PROCESSES,
          payload: res.data,
        });
        // dispatch(removeLoading());
        return Promise.resolve(res.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // dispatch(removeLoading());
        dispatch(setMessage(message, "error"));

        return Promise.reject();
      }
    );
  } catch (err: any) {
    // dispatch(removeLoading());
    dispatch(setMessage(err, "error"));
    console.log(err);
  }
};

export const getProcess = (processID: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    const res = await ProcessService.getProcess(processID);

    dispatch({
      type: GET_ONE_PROCESS,
      payload: res.data,
    });
    dispatch(removeLoading());
  } catch (err: any) {
    dispatch(setMessage(err, "error"));
    dispatch(removeLoading());
    console.log(err);
  }
};

export const updateProcess =
  (id: string, data: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading());

      await ProcessService.updateProcess(id, data).then(
        (data) => {
          dispatch({
            type: UPDATE_PROCESS,
            payload: data,
          });
          dispatch(removeLoading());
          // dispatch(
          //   setMessage("Der Prozess wurde erfolgreich geändert.", "success")
          // );

          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(removeLoading());
          return Promise.reject(message);
        }
      );
    } catch (err) {
      dispatch(removeLoading());
      dispatch(setMessage(err, "error"));
      return Promise.reject(err);
    }
  };

export const deleteProcess = (id: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    await ProcessService.deleteProcess(id).then(
      (data) => {
        dispatch({
          type: DELETE_PROCESS,
          payload: { id },
        });
        dispatch(removeLoading());
        dispatch(
          setMessage("Der Prozess wurde erfolgreich gelöscht.", "success")
        );
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject();
      }
    );
  } catch (err) {
    console.log(err);
  }
};
