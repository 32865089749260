import {
  UPSERT_SUB_PROCESS,
  GET_SUB_PROCESSES,
  DELETE_SUB_PROCESS,
} from "../actions/types";

const initialState = [] as any;

const SubProcessesReducer = (subProcesses = initialState, action: any) => {
  const { type, payload } = action;
  
  switch (type) {
    case UPSERT_SUB_PROCESS:
      return { ...subProcesses, payload };

    case GET_SUB_PROCESSES:
      return { ...subProcesses, subProcesses: payload };

    case DELETE_SUB_PROCESS:
      return Object.values(subProcesses).filter((id) => id !== payload.id);

    default:
      return subProcesses;
  }
};
export default SubProcessesReducer;
