import "./UserList.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../Store/actions/userAction";

// components:
import User from "../User/User";

export interface UserListProps {}

const UserList: React.FC<UserListProps> = () => {
  const dispatch = useDispatch<any>();

  // redux state
  const users = useSelector((state: any) => state.UserReducer);

  //get all users once the component renders:
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <>
      <table className="users">
        <tbody>
          <tr>
            <th className="my-td-label-user">
              <h2>Nutzername</h2>
            </th>
            <th className="my-td-label-user">
              <h2>Standort</h2>
            </th>
            <th className="my-td-label-user">
              <h2>E-Mail</h2>
            </th>
            <th className="my-td-label-user">
              <h2>IP-Adresse</h2>
            </th>
            <th className="my-td-label-user">
              <h2>Gültig ab</h2>
            </th>
            <th className="my-td-label-user">
              <h2>Gültig bis</h2>
            </th>
            <th colSpan={2} className="my-td-label-user">
              <h2 className="h2-action">Aktion</h2>
            </th>
          </tr>
          {users.map((user: any, index: any) => (
            <User
              key={index}
              id={user.userID}
              username={user.firstname + " " + user.lastname}
              location={user.location && user.location.city}
              email={user.email}
              ip={user.ip}
              validFrom={user.licenseValidFrom}
              validTo={user.licenseValidTo}
              pathname="edit-user/"
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UserList;
