/* eslint-disable eqeqeq */
import "./Date.css";
// import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Services
// import JobService from "../../../Services/jobService";
import InstructionService from "../../../Services/instructionService";
import JobParameterService from "../../../Services/jobParameterService";

// Actions
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import {
  addJobParameter,
  resetJobParameter,
  // resetJobParameter,
  updateJobParameterRedux,
} from "../../../Store/actions/jobParameterAction";
import { setMessage } from "../../../Store/actions/messageAction";

// components:
// import Input from "../../../Components/Input/Input";
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import UserButton from "../../../Components/UserButton/UserButton";

import de from "date-fns/locale/de";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
// import SavePopup from "../../../Components/SavePopup/SavePopup";
// import _ from "lodash";

registerLocale("de", de);
setDefaultLocale("de");

type UserDateProps = {
  subProcess: any;
  setSubProcess: any;
};

const UserDate = (props: UserDateProps) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const job = useSelector((state: any) => state.JobReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  // the redux array that store the finished instructions
  const jobParametersArray = useSelector(
    (state: any) => state.JobParameterReducer
  );
  // jobId from redux state
  const jobID = job.jobID;

  // we get the url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  //local state:
  // const [value, setValue] = useState<any>(new Date());
  const [value, setValue] = useState<any>();
  const [date, setDate] = useState<any>();
  const [isLastFunction, setLastFunction] = useState(false);
  const [nextFunction, setNextFunction] = useState<any>("");
  // setJobParameterID store the jobParameterID in the update mode
  const [jobParameterID, setJobParameterID] = useState<string>();
  // popup state
  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
    isLogin: false,
  });

  //we get the subProcessFunction id from url param:
  let params: any = useParams();
  const { fun, id } = params;
  const code = fun.toUpperCase();

  const nextFunctionFun = () => {
    props.subProcess?.assignedFunctions?.map(
      (subProcessData: any, index: any) => {
        if (
          subProcessData.code === code &&
          subProcessData.subProcessFunctionAssignmentID == id
        ) {
          if (index === props.subProcess?.assignedFunctions.length - 1) {
            setLastFunction(true);
            setNextFunction(index + 1);
          } else {
            setNextFunction(index + 1);
          }
        }
        return nextFunction;
      }
    );
  };

  // get the job parameter by filter mean: by jobID and InstructionID
  const getJobParameterByFilter = () => {
    // Destructuring the instructionID property from the date
    const { instructionID } = date;
    // API call to getJobParametersByMultipleFilter endpoint, takes jobID,instructionID as a param
    JobParameterService.getJobParametersByMultipleFilter(jobID, [instructionID])
      .then((response: any) => {
        // store the value that has been added by the user inside the local state from the response
        setValue(response.data[0].value);
        // get jobParameterID from the response and store it inside the local state
        setJobParameterID(response.data[0].jobParameterID);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    dispatch(removeLoading());
    nextFunctionFun();

    nextFunction &&
      getInstructionBySubProcessFunctionID(
        props.subProcess?.assignedFunctions[nextFunction - 1]
          ?.subProcessFunctionAssignmentID,
        { signal: signal }
      );
    // get job parameter by jobID and instruction ID
    jobParametersArray[0] &&
      (jobParametersArray[0]?.jobID === jobID ||
        jobParametersArray[0][0]?.jobID === jobID) &&
      date?.instructionID &&
      jobParametersArray[nextFunction - 1]?.instructionID ===
        date?.instructionID &&
      getJobParameterByFilter();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [props.subProcess, nextFunction, date?.instructionID, dispatch, id]);

  // if the form is on edit, so we get license data from DB:
  const getInstructionBySubProcessFunctionID = (id: number, signal: any) => {
    // dispatch(setLoading());
    InstructionService.getInstructionBySubProcessFunctionID(id)
      .then((response: any) => {
        //add a condition to inform the user that there are no data added by the user
        if (response?.data?.length > 0) {
          setDate(response?.data[0]);
        } else {
          dispatch(setMessage("No data have been added by the admin", "error"));
        }
        dispatch(removeLoading());
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function that handle the date valid from
  const handleDatesChange = (event: Date) => {
    // const { name, value } = event;
    //add the time zone formula to solve the day before issue
    if (event) {
      event.setHours((-1 * event.getTimezoneOffset()) / 60);
    }
    setValue(event);
  };

  const saveJobParameter = () => {
    const { instructionID } = date;
    dispatch(setLoading());
    // value &&

    jobParametersArray[0] &&
    (jobParametersArray[0]?.jobID === jobID ||
      jobParametersArray[0][0]?.jobID === jobID) &&
    jobParameterID
      ? JobParameterService.updateJobParameter([
          { jobParameterID, jobID, instructionID, value },
        ])
          .then(
            (response) => {
              dispatch(
                updateJobParameterRedux({
                  jobParameterName: "Date",
                  title: date?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  value: value,
                })
              );
              dispatch(removeLoading());

              isLastFunction ||
              props.subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      props.subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      props.subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            console.log("err", err);
            dispatch(setMessage("Error", "error"));
          })
      : JobParameterService.createJobParameter([
          {
            jobID,
            instructionID,
            value,
          },
        ])
          .then(
            (response) => {
              dispatch(removeLoading());
              dispatch(
                addJobParameter({
                  jobParameterName: "Date",
                  title: date?.title,
                  path: currentRoute,
                  jobID: jobID,
                  instructionID: instructionID,
                  value: value,
                })
              );
              dispatch(removeLoading());

              isLastFunction
                ? history.push("/sub-processes-overview/final-page/")
                : history.push(
                    "/sub-processes-overview/" +
                      props.subProcess?.assignedFunctions[nextFunction]?.code +
                      "/" +
                      props.subProcess?.assignedFunctions[nextFunction]
                        ?.subProcessFunctionAssignmentID
                  );
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              dispatch(setMessage(message, "error"));
              dispatch(removeLoading());
              return Promise.reject();
            }
          )
          .catch((err) => {
            dispatch(removeLoading());
            dispatch(setMessage(err, "error"));
            console.log("err", err);
          });
  };

  //call the finishJob api, remove the redux store of the jobParameter array
  const saveJob = () => {
    if (isSavePopup.isLogin === false) {
      history.push(`/sub-processes-overview/T/${props.subProcess.processID}`);
    } else {
      history.push("/login");
    }
    props.setSubProcess(null);
    dispatch(resetJobParameter());
  };

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        if (e.target.tagName.toLowerCase() === "a") {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: true,
          });
        } else {
          setSavePopup({
            //when click is outside we show save popup
            savePopup: true,
            isLogin: false,
          });
        }
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Möchtest du aufhören?"
          Save={() => {
            saveJob();
            setSavePopup({ savePopup: false, isLogin: false });
          }}
          closeSavePopup={() =>
            setSavePopup({ savePopup: false, isLogin: false })
          }
          buttonText="Ja"
          isLogin={isSavePopup.isLogin}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="sub-process-function-name">
              <p>{props.subProcess.name}</p>
              <h3 className="sub-process-function-name-h3">{date?.title}</h3>
            </div>

            <div>
              <p>{date?.description}</p>
            </div>

            <div className="date-body">
              <DatePicker
                name="validFrom"
                locale="de"
                //call the format function inside the date
                selected={value ? new Date(value) : null}
                onChange={handleDatesChange}
                dateFormat="dd.MM.yyyy"
                closeOnScroll={true}
                // minDate={Date.now() as any}
                isClearable
                showYearDropdown
                scrollableMonthYearDropdown
              />
            </div>
            <div></div>

            <div className="back-next-buttons">
              <UserButton
                onClick={() => {
                  nextFunction === 1 &&
                  props.subProcess?.assignedFunctions.length !==
                    jobParametersArray?.length
                    ? setSavePopup({
                        savePopup: true,
                        isLogin: false,
                      })
                    : props.subProcess?.assignedFunctions.length ===
                      jobParametersArray?.length
                    ? history.push("/sub-processes-overview/final-page/")
                    : history.push(
                        "/sub-processes-overview/" +
                          props.subProcess?.assignedFunctions[nextFunction - 2]
                            ?.code +
                          "/" +
                          props.subProcess?.assignedFunctions[nextFunction - 2]
                            ?.subProcessFunctionAssignmentID
                      );
                }}
              >
                zurück
              </UserButton>
              <UserButton disabled={!value} onClick={saveJobParameter}>
                {props.subProcess?.assignedFunctions.length ===
                jobParametersArray?.length
                  ? "speichern"
                  : "weiter"}
              </UserButton>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UserDate;
