import {
  CREATE_FUNCTION,
  GET_FUNCTIONS,
  GET_ONE_FUNCTION,
  UPDATE_FUNCTION,
  DELETE_FUNCTION,
} from "./types";

import FunctionService from "../../Services/functionService";
import { setMessage } from "../actions/messageAction";
import { setLoading, removeLoading } from "../actions/loadingAction";

export const createFunction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    await FunctionService.createFunction(data).then(
      (data) => {
        dispatch({
          type: CREATE_FUNCTION,
          payload: data,
        });
        dispatch(removeLoading());
        dispatch(setMessage("Die Funktion wurde erfolgreich hinzugefügt.", "success"));
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (err) {
    dispatch(setMessage(err, "error"));
  }
};

export const getAllFunctions = () => async (dispatch: any) => {
  try {
    const res = await FunctionService.getAllFunctions();
    dispatch({
      type: GET_FUNCTIONS,
      payload: res.data,
    });
    dispatch(removeLoading());

    return Promise.resolve(res.data);
  } catch (err: any) {
    dispatch(setMessage(err.message, "error"));
    dispatch(removeLoading());
    console.log(err);
  }
};

export const updateFunction =
  (id: number, data: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading());
      const res = await FunctionService.updateFunction(id, data).then(
        (data) => {
          dispatch({
            type: UPDATE_FUNCTION,
            payload: data,
          });

          dispatch(removeLoading());
          dispatch(setMessage("Die Funktion wurde erfolgreich geändert.", "success"));
          return Promise.resolve(data);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
          return Promise.reject();
        }
      );
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const getFunction = (title: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    const res = await FunctionService.getFunction(title);

    dispatch({
      type: GET_ONE_FUNCTION,
      payload: res.data,
    });
    dispatch(removeLoading());
  } catch (err) {
    console.log(err);
  }
};

export const deleteFunction = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    await FunctionService.deleteFunction(id).then(
      (data) => {
        dispatch({
          type: DELETE_FUNCTION,
          payload: { id },
        });
        dispatch(removeLoading());
        dispatch(setMessage("Die Funktion wurde erfolgreich gelöscht", "success"));
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject();
      }
    );
  } catch (err) {
    console.log(err);
  }
};
