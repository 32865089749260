import * as Yup from "yup";

export const LocationSchema = Yup.object().shape({
  city: Yup.string().max(50).required("Erforderlich!"),
  code: Yup.string().max(50).required("Erforderlich!"),
  // functions: Yup.string().required("Required!"),
});

// const yesterday = new Date(Date.now() - 86400000);

export const LicenseSchema = Yup.object().shape({
  locationID: Yup.string().max(50).required("Erforderlich!"),
  validFrom: Yup.date().required("Erforderlich!").nullable(),
  validTo: Yup.date()
    .min(
      Yup.ref("validFrom"),
      "Das Enddatum kann nicht vor dem Startdatum liegen."
    )
    .required("Erforderlich!")
    .nullable(),
  count: Yup.number()
    .moreThan(-1, "Ungültige Anzahl: die Anzahl darf nicht negativ sein.")
    .max(500)
    .required("Erforderlich!"),
});

export const FunctionSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Erforderlich!"),
});

// function ipv4(message = "Invalid IP address") {
//   return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
//     message,
//     excludeEmptyString: true,
//   }).test("ip", message, (value) => {
//     return value === undefined || value.trim() === ""
//       ? true
//       : value.split(".").find((i) => parseInt(i, 10) > 255) === undefined;
//   });
// }

// Yup.addMethod(Yup.string, "ipv4", ipv4);

export const UserSchema = Yup.object().shape({
  firstname: Yup.string().max(50).required("Erforderlich!"),

  lastname: Yup.string().max(50).required("Erforderlich!"),

  email: Yup.string()
    .email("Ungültige E-Mail Adresse.")
    .max(50)
    .required("Erforderlich!"),

  password: Yup.string()
    .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])/,
      "Das Passwort muss aus mindestens einem Großbuchstaben, einer Zahl und einem Sonderzeichen bestehen."
    )
    .max(50)
    .required("Erforderlich!"),

  ip: Yup.string()
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Ungültige IP Adresse",
      excludeEmptyString: true,
    })
    .test(
      "ipAddress",
      "Ungültige IP Adresse: die Komponenten der IP Adresse müssen im Bereich von 0 bis 255 liegen.",
      (value) => {
        if (value === undefined || value.trim() === "") return true;
        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      }
    )
    .max(50)
    .required("Erforderlich!"),

  locationID: Yup.string().max(50).required("Erforderlich!"),

  validFrom: Yup.date().required("Erforderlich!").nullable(),
  validTo: Yup.date()
    .min(
      Yup.ref("validFrom"),
      "Das Enddatum kann nicht vor dem Startdatum liegen."
    )
    .required("Erforderlich!")
    .nullable(),
});

export const editUserSchema = Yup.object().shape({
  ip: Yup.string()
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Ungültige IP Adresse",
      excludeEmptyString: true,
    })
    .test(
      "ipAddress",
      "Ungültige IP Adresse: die Komponenten der IP Adresse müssen im Bereich von 0 bis 255 liegen.",
      (value) => {
        if (value === undefined || value.trim() === "") return true;
        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      }
    )
    .max(50)
    .required("Erforderlich!"),

  locationID: Yup.string().max(50).required("Erforderlich!"),

  validTo: Yup.date().required("Erforderlich!").nullable(),
});
