import http from "./http-common";
import authHeader from "./authHeader";

const getLocation = (id: number) => {
  return http.get(`/Location/${id}`, { headers: authHeader() });
};

const getAllLocations = () => {
  return http.get("/Location", { headers: authHeader() });
};

const createLocation = (data: any) => {
  return http.post("/Location", data, { headers: authHeader() });
};

const assignFunctionsToLocation = (data: any) => {
  return http.post("/Location/AssignFunctionsToLocation", data, {
    headers: authHeader(),
  });
};

const deleteAssignedFunction = (locationID: any, functionID: any) => {
  return http.delete(
    `/Location/${locationID}/DeleteAssignedFunction/${functionID}`,
    {
      headers: authHeader(),
    }
  );
};

const deleteAssignedFunctions = (data: any) => {
  return http.delete("/Location/DeleteAssignedFunctions", { headers: authHeader(),data });
};

const updateLocation = (id: number, data: any) => {
  return http.put(`/Location/${id}`, data, { headers: authHeader() });
};

const deleteLocation = (id: number) => {
  return http.delete(`/Location/${id}`, { headers: authHeader() });
};

const LocationService = {
  getLocation,
  getAllLocations,
  createLocation,
  updateLocation,
  assignFunctionsToLocation,
  deleteAssignedFunction,
  deleteAssignedFunctions,
  deleteLocation
};

export default LocationService;
