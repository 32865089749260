import "./CreateEditIpAddress.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Formik
import { Formik, Form } from "formik";

// Validation
import { IpAddressSchema } from "../../../Validations/AdminValidations";

// Actions
import {
  createLocationIP,
  updateLocationIP,
} from "../../../Store/actions/locationIPAction";
import { setMessage } from "../../../Store/actions/messageAction";

// Services
import locationIpService from "../../../Services/locationIpService";

// Components
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import Loader from "../../../Components/Loader/Loader";
import FormikControl from "../../../Components/FormikControl/FormikControl";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import SavePopup from "../../../Components/SavePopup/SavePopup";

export interface CreateEditLocationProps {
  props: any;
  match: any;
}

const CreateEditIPAddress: React.FC<CreateEditLocationProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const loginDetails = useSelector((state: any) => state.LoginReducer.user);

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  // this id is from the URL:
  const { id } = props.match.params;

  // isAddMode to know if the form is for creating new location or for updating exciting one:
  const isAddMode = !id;

  const [ipAddress, setIpAddress] = useState({
    locationIpID: "",
    ip: "",
  });

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  const CreateLocationIp = (fields: any) => {
    dispatch(createLocationIP(fields.ip, loginDetails.locationId))
      .then(
        () => {
          // dispatch(
          //   setMessage("Der IP-Adresse wurde erfolgreich erstellt.", "success")
          // );
          history.push("/system-setting");
        },
        (error: any) => {
          // get error message from everywhere in the response:
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // show failed message:
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((e: any) => {
        console.log(e);
      });
  };

  const UpdateLocationIp = (id: string, fields: any) => {
    dispatch(
      updateLocationIP(
        id,
        fields.ip,
        fields.locationIpID,
        loginDetails.locationId
      )
    )
      .then(
        (response: any) => {
          history.push("/system-setting");
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((e: any) => {
        console.log(e);
      });
    history.push(`/system-setting`, { from: "create-edit-ip-address" });
  };

  const onSubmit = (fields: any) => {
    if (isAddMode) {
      CreateLocationIp(fields);
    } else {
      UpdateLocationIp(props.match.params.id, fields);
    }
  };

  // if the form is on edit, so we get locationID data from DB:
  const getLocationIp = (id: string) => {
    locationIpService
      .getLocationIp(id)
      .then((response) => {
        setIpAddress(response?.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  useEffect(() => {
    !isAddMode && getLocationIp(props.match.params.id);
  }, [dispatch, isAddMode, props.match.params.id]);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
      }
  };

  return (
    <>
      {isSavePopup.savePopup ? (
        <SavePopup
          label="Soll die Lizenz gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
        />
      ) : null}

      <AdminNavbar />

      <div className="create-edit-ip-address">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h1>IP-Addesse hinzufügen</h1>

            <div ref={formRef}>
              <Formik
                initialValues={ipAddress}
                validationSchema={IpAddressSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ values, errors, dirty, isValid }) => {
                  return (
                    <>
                      <Form className="create-edit-ip-address-body">
                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  IP-Addresse{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="ip"
                                  id="ip"
                                  type="text"
                                  placeholder="IP-Address"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  (<span className="required-p">*</span>
                                  ):{" "}
                                  <span className="required-p">
                                    Erforderlich
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="save-cancel-btns-in-system-settings">
                          <DefaultButton
                            disabled={!dirty}
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                          <DefaultButton
                            type="submit"
                            onClick={() => history.push("/system-setting")}
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateEditIPAddress;
