import { SET_SUB_PROCESS } from "../actions/types";

const initialState = { presetSubProcessID: "", presetSubProcessName: "" };
const PresetSubProcessReducer = (
  presetSubProcess = initialState,
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SUB_PROCESS:
      return payload;

    default:
      return presetSubProcess;
  }
};

export default PresetSubProcessReducer;
