import "./ProcessParameterNavBar.css";
import { NavLink, useHistory } from "react-router-dom";

export interface ProcessParameterNavBarProps {
  subProcess: any;
}

const ProcessParameterNavBar: React.FC<ProcessParameterNavBarProps> = ({
  subProcess,
}) => {
  // get the currentRoute param
  const currentRoute = useHistory().location.pathname.toLowerCase();

  return (
    <div>
      <ul className="my-ul">
        {subProcess && subProcess.assignedFunctions ? (
          subProcess.assignedFunctions.map(
            (assignedFunction: any, index: any) => {
              return (
                <NavLink
                  key={index}
                  className={
                    currentRoute.includes(
                      assignedFunction.subProcessFunctionAssignmentID
                    ) &&
                    currentRoute.includes(assignedFunction.code.toLowerCase())
                      ? "my-function-li-active"
                      : "my-function-li"
                  }
                  activeClassName="is-active"
                  // put in the url the function CODE and subProcessFunction ID
                  to={{
                    pathname:
                      "/process-parameter/" +
                      assignedFunction.code +
                      "/" +
                      assignedFunction.subProcessFunctionAssignmentID,
                  }}
                >
                  <li>{assignedFunction.name}</li>
                </NavLink>
              );
            }
          )
        ) : (
          <h1 style={{ textAlign: "center" }}>
            Wählen Sie zunächst einen Prozess und einen Teilprozess.
          </h1>
        )}
      </ul>
    </div>
  );
};

export default ProcessParameterNavBar;
