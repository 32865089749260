import {
  CREATE_LOCATION_IP,
  GET_LOCATIONS_IP,
  GET_ONE_LOCATION_IP,
  UPDATE_LOCATION_IP,
  DELETE_LOCATION_IP,
} from "./types";

// Service
import LocationIPService from "../../Services/locationIpService";

// Actions
import { setMessage } from "../actions/messageAction";
import { setLoading, removeLoading } from "../actions/loadingAction";

export const createLocationIP =
  (ip: any, locationID: string) => async (dispatch: any) => {
    try {
      // show loading indicator:
      dispatch(setLoading());

      // API call to create location:
      await LocationIPService.createLocationIP({ ip, locationID }).then(
        (response) => {
          dispatch({
            type: CREATE_LOCATION_IP,
            payload: response.data,
          });
          dispatch(
            setMessage("Der IP-Adresse wurde erfolgreich erstellt.", "success")
          );
          // remove loading indicator:
          dispatch(removeLoading());
        },
        (error) => {
          // get error message from everywhere in the response:
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // remove loading indicator:
          dispatch(setMessage(message,"error"))
          dispatch(removeLoading());
          return Promise.reject(message);
        }
      );
    } catch (err) {
      // remove loading indicator:
      dispatch(removeLoading());

      // show failed message:
      dispatch(setMessage(err, "error"));
    }
  };

export const getAllLocationIP = () => async (dispatch: any) => {
  try {
    // API call to get all locations:
    const res = await LocationIPService.getAllLocationIP();

    dispatch({
      type: GET_LOCATIONS_IP,
      payload: res.data,
    });

    dispatch(removeLoading());
    return Promise.resolve(res.data);
  } catch (err: any) {
    dispatch(removeLoading());
    dispatch(setMessage(err.message, "error"));
    console.log(err);
  }
};

export const updateLocationIP =
  (id: string, ip: any, locationIpID: string, locationID: string) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading());

      // API call to update location:
      await LocationIPService.updateLocationIp(id, {
        ip,
        locationIpID,
        locationID,
      }).then(
        (response) => {
          dispatch({
            type: UPDATE_LOCATION_IP,
            payload: response.data,
          });
          dispatch(removeLoading());
          dispatch(
            setMessage("Die IP-Address wurde erfolgreich geändert.", "success")
          );
          return Promise.resolve(response);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(removeLoading());

          return Promise.reject(message);
        }
      );
    } catch (err: any) {
      dispatch(removeLoading());
      dispatch(setMessage(err, "error"));
      return Promise.reject(err);
    }
  };

export const getLocationIP = (title: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    // API call to get one location:
    const res = await LocationIPService.getLocationIp(title);

    dispatch({
      type: GET_ONE_LOCATION_IP,
      payload: res.data,
    });
    dispatch(removeLoading());
  } catch (err: any) {
    dispatch(setMessage(err, "error"));
    dispatch(removeLoading());
    console.log(err);
  }
};

export const deleteLocationIP = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    await LocationIPService.deleteLocationIp(id).then(
      (data) => {
        dispatch({
          type: DELETE_LOCATION_IP,
          payload:  id ,
        });
        dispatch(removeLoading());
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
 dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject(message);
      }
    );
  } catch (err) {
    dispatch(setMessage(err, "error"));
    console.log(err);
  }
};
