import "./CreateEditProcess.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

// validations
import { ProcessSchema } from "../../../Validations/AdminValidations";

// services
import ImageService from "../../../Services/imageService";
import ProcessService from "../../../Services/processService";

// actions
import {
  createProcess,
  updateProcess,
} from "../../../Store/actions/processAction";
import { setMessage } from "../../../Store/actions/messageAction";
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";

//Components:
import Input from "../../../Components/Input/Input";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import Loader from "../../../Components/Loader/Loader";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import TrashIcon from "../../../Components/TrashIcon/TrashIcon";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

// Images:
import image from "../../../Assets/Images/image.png";

type CreateEditProcessProps = {
  props: any;
  match: any;
};

const CreateEditProcess = (props: CreateEditProcessProps) => {
  const history = useHistory<any>();
  const dispatch = useDispatch<any>();

  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  const initialProcessState = {
    name: "",
    image: image,
  };

  //store saved process coming from DB in a state
  const [savedProcess, setSavedProcess] = useState<any>();

  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);

  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");

  //check if validation errors  exits
  const [isValidationError, setValidationError] = useState<any>();

  //process initial state for creation or update process:
  const [process, setProcess] = useState<any>(initialProcessState);

  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(image);

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  //get the id from url params:
  const { id } = props.match.params;

  // if we have an id so the form is on update mode
  const isAddMode = !id;

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  //get  process and show them in table from DB:
  const GetProcess = (id: string, signal: any) => {
    ProcessService.getProcess(id)
      .then((response) => {
        //set process from DB in the process state:
        setProcess(response.data);
        setSavedProcess(response.data);
        response.data.imageID && getProcessImage(response.data.imageID);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // use effect that run at the first render and if the id is changed to get the process by id
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    id && GetProcess(id, { signal: signal });

    document.title = "JMS - Prozess bearbeiten";

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [id]);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //function to show the process image in the preview position if we are editing an existing process:
  const getProcessImage = (imageID: any) => {
    ImageService.getImage(imageID).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.png");
      document.body.appendChild(link);
      // link.click();
      setBaseImage(link);
    });
  };

  // handle the name input change:
  const handleInputChange = (event: any) => {
    setProcess({
      ...process,
      name: event.target.value,
    });
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteProcessImage = (imageID: string) => {
    dispatch(setLoading());

    ImageService.deleteImage(imageID)
      .then((response) => {
        //set process from DB in the process state:
        dispatch(removeLoading());
        setBaseImage(null);
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // onSubmit general function that check if the form is create or edit and give the correct function:
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAddMode) {
      CreateProcess(fields, setSubmitting);
      // dispatch(removeSavePopUp())
    } else {
      UpdateProcess(id, fields, setSubmitting);
    }
  }

  // create process function:
  const CreateProcess = (fields: any, setSubmitting: any) => {
    // e.preventDefault();
    const { name, image } = fields;
    // create data as specific typescript data type that support images in it:
    let data = new FormData();
    data.append("name", name);
    data.append("image", image);

    // dispatch the create process action
    dispatch(createProcess(data))
      .then(
        (res: any) => {
          history.push("/manage-processes");
          dispatch(
            setMessage("Der Prozess wurde erfolgreich geändert.", "success")
          );
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((err: any) => {
        console.log(err);
      });
  };

  // update process function:
  const UpdateProcess = (id: any, fields: any, setSubmitting: any) => {
    // e.preventDefault();
    const { name, image } = fields;
    // create data as specific typescript data type that support images in it:
    let data = new FormData();
    data.append("name", name);
    data.append("image", image);
    // API call
    dispatch(updateProcess(id, data))
      .then(
        (res: any) => {
          history.push("/manage-processes");
          dispatch(
            setMessage("Der Prozess wurde erfolgreich geändert.", "success")
          );
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
        }
      )
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  // auth validation
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin ", "error"));
    return <Redirect to="/manage-locations" />;
  }

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);

  // custom dirty function
  const CheckIfStateChanged = () => {
    //compare state coming from DB with our state
    if (JSON.stringify(savedProcess) === JSON.stringify(process)) {
      //both states are same
      setIsStateChanged(false);
    } else {
      //states are not equal
      if (isAddMode && process.name === "") {
        setIsStateChanged(false);
      } else {
        setIsStateChanged(true);
      }
    }
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isStateChanged && isSavePopup.savePopup ? (
        <SavePopup
          label="Soll der Prozess gespeichert werden?"
          Save={() => {
            // @ts-ignore: Object is possibly 'null'.
            var button = document.getElementById("clickSave"); //trigger click on save button in formik
            button?.click();
          }}
          closeSavePopup={() => setSavePopup({ savePopup: false })}
          clickedMenu={clickedMenu}
          errors={isValidationError}
        />
      ) : null}

      <AdminNavbar />
      <div ref={formRef} className="create-processes-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Formik
              initialValues={process}
              validationSchema={ProcessSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                isValid,
                dirty,
                handleChange,
              }) => {
                //store errors in a stated to send as prop for save pop up
                setTimeout(() => {
                  setValidationError(errors);
                }, 0);
                return (
                  <>
                    <div className="title-btns">
                      <h1>Prozess bearbeiten:</h1>
                      <div className="back-btn">
                        <DefaultButton onClick={() => history.goBack()}>
                          Zurück
                        </DefaultButton>
                      </div>
                      {/* <BackButton /> */}
                    </div>
                    <div>
                      <Form
                        className="create-processes-forms"
                        // checking if the sub-processes from is displayed so the process form is update else is create:
                      >
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                <td className="create-process-label">
                                  <h2>Prozessname:</h2>
                                </td>
                                <td className="process-name-input">
                                  <Field
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    placeholder="Prozessname"
                                    // onChange={(e: any) => {

                                    //   handleInputChange(values);
                                    //   handleChange(e);
                                    // }}
                                    onChange={(e: any) => {
                                      handleInputChange(e);
                                      setFieldValue("name", e.target.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="new-license-error-message"
                                  />
                                </td>
                                
                                <td className="create-process-label">
                                  <label
                                    className="image-input"
                                    htmlFor="image-input"
                                  >
                                    <img
                                      src={baseImage ? baseImage : image}
                                      alt="add"
                                    />
                                    <Input
                                      id="image-input"
                                      type="file"
                                      name="image"
                                      accept="image/*"
                                      placeholder="Durchsuchen.."
                                      /****
                                       * OnChange function
                                       * @params : event
                                       * @body : setFieldValue
                                       * used for custom input change handlers.
                                       * it takes in two parameters
                                       * @params : field : image (name of the input)
                                       *           value : event.target.files[0]
                                       * use the convertBase64 function to preview the image
                                       */
                                      onChange={async (event: any) => {
                                        setFieldValue(
                                          "image",
                                          event.target.files[0]
                                        );
                                        const base64 = await convertBase64(
                                          event.target.files[0]
                                        );
                                        setBaseImage(base64);
                                        setProcess({
                                          ...process,
                                          image: event.target.files[0],
                                        });
                                      }}
                                    />
                                  </label>
                                </td>

                                <td className="process-trash-icon">
                                  <TrashIcon
                                    id={id}
                                    onClick={() => {
                                      setFieldValue("image", "");
                                      deleteProcessImage(process.imageID);
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3} className="process-save-btn">
                                  <DefaultButton
                                    // disabled={!(isStateChanged && isValid)}
                                    type="submit"
                                    id="clickSave"
                                  >
                                    Speichern
                                  </DefaultButton>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Form>
                    </div>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default CreateEditProcess;
