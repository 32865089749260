import "./SensrecAdminNavbar.css";
import { NavLink, useHistory } from "react-router-dom";

export interface SensrecAdminNavbarProps {}

const SensrecAdminNavbar: React.FC<SensrecAdminNavbarProps> = () => {
  const currentRoute = useHistory().location.pathname.toLowerCase();
  return (
    <>
      <div className="sensrecAdmin-navbar">
        <ul className="my-sensrecAdmin-ul">
          <NavLink
            className={
              currentRoute.includes("location")
                ? "my-sensrecAdmin-is-active my-sensrecAdmin-li-active"
                : "my-sensrecAdmin-li"
            }
            activeClassName="my-sensrecAdmin-is-active"
            to={{ pathname: "/manage-locations" }}
          >
            <li>Standorte</li>
          </NavLink>

          <NavLink
            className={
              currentRoute.includes("license")
                ? "my-sensrecAdmin-is-active my-sensrecAdmin-li-active"
                : "my-sensrecAdmin-li"
            }
            activeClassName="my-sensrecAdmin-is-active"
            to={{ pathname: "/manage-licenses" }}
          >
            <li>Lizenzen</li>
          </NavLink>

          <NavLink
            className={
              currentRoute.includes("user")
                ? "my-sensrecAdmin-is-active my-sensrecAdmin-li-active"
                : "my-sensrecAdmin-li"
            }
            activeClassName="my-sensrecAdmin-is-active"
            to={{ pathname: "/manage-users" }}
          >
            <li>Benutzer</li>
          </NavLink>

          <NavLink
            className={
              currentRoute.includes("function")
                ? "my-sensrecAdmin-is-active my-sensrecAdmin-li-active"
                : "my-sensrecAdmin-li"
            }
            activeClassName="my-sensrecAdmin-is-active"
            to={{ pathname: "/manage-functions" }}
          >
            <li>Funktionen</li>
          </NavLink>
        </ul>
      </div>
    </>
  );
};

export default SensrecAdminNavbar;
