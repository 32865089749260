import {
  CREATE_PROCESS,
  GET_PROCESSES,
  GET_ONE_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
} from "../actions/types";

const initialState = [] as any;

const ProcessReducer = (processes = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PROCESS:
      return [...processes, payload];

    case GET_ONE_PROCESS:
      return payload;

    case GET_PROCESSES:
      return payload;

    case UPDATE_PROCESS:
      return [...processes, payload];

    case DELETE_PROCESS:
      return processes.filter(({ id }: { id: any }) => id !== payload.id);

    default:
      return processes;
  }
};
export default ProcessReducer;
