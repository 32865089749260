import "../ProcessPopup/ProcessPopup.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";

// Actions
import { upsertSubProcess } from "../../Store/actions/subProcessesAction";
import { removeLoading, setLoading } from "../../Store/actions/loadingAction";
import { setMessage } from "../../Store/actions/messageAction";

// Components
import Input from "../Input/Input";
import TrashIcon from "../TrashIcon/TrashIcon";
import DefaultButton from "../DefaultButton/DefaultButton";
import FormikControl from "../FormikControl/FormikControl";

// Validation
import { ProcessSchema } from "../../Validations/AdminValidations";
// Services
import subProcessService from "../../Services/subProcessService";
import ImageService from "../../Services/imageService";
// Images:
import image from "../../Assets/Images/image.png";
// import SubProcessService from "../../Services/subProcessService";

type ProcessPopupProps = {
  subProcessID?: any;
  processID?: any;
  label: string;
  name?: string;
  image?: any;
  order?: any;
  errors?: any;
  Save?: () => void;
  closeSubProcessPopup: any;
};

function SubProcessPopup(props: ProcessPopupProps) {
  const dispatch = useDispatch<any>();

  //local states
  const initialSubProcessState = {
    id: "",
    processID: props.processID,
    order: props.order,
    name: "",
    image: image,
  };

  // subProcess local state stores the initial values of the subProcess
  const [subProcess, setSubProcess] = useState<any>(initialSubProcessState);

  const [showSubProcessPopup, setSubProcessPopup] = useState(true);
  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(
    props.image ? props.image : image
  );

  //process initial state for creation or update process:
  const [approved, setApproved] = useState(true);

  const onSubmit = (imageID: any) => {
    // e.preventDefault();
    dispatch(setLoading());
    let subProcesses = [] as any;
    //push the object data into an array
    subProcesses.push(subProcess);

    for (let i = 0; i < subProcesses.length; i++) {
      setApproved(true);
      if (!subProcesses[i].name) {
        dispatch(setMessage("SubProcess name is empty!", "error"));
        // setDisabled(true);
        dispatch(removeLoading());
        return setApproved(false);
      }
      dispatch(removeLoading());
    }

    var data = new FormData();
    if (approved) {
      //iterate inside the subProcesses array and check for the subProcessID to either create or edit
      for (let i = 0; i < subProcesses.length; i++) {
        if (subProcesses[i].subProcessID) {
          data.append(`models[${i}].name`, subProcesses[i].name);
          data.append(`models[${i}].order`, subProcesses[i].order);
          data.append(`models[${i}].processID`, subProcesses[i].processID);
          data.append(
            `models[${i}].subProcessID`,
            subProcesses[i].subProcessID ? subProcesses[i].subProcessID : null
          );
          data.append(`models[${i}].image`, subProcesses[i].image);
        } else {
          data.append(`models[${i}].processID`, subProcesses[i].processID);
          data.append(`models[${i}].order`, subProcesses[i].order);
          data.append(`models[${i}].name`, subProcesses[i].name);
          data.append(`models[${i}].image`, subProcesses[i].image);
        }
      }
      !baseImage && deleteSubProcessImage(imageID);
      dispatch(upsertSubProcess(data));
    }
  };

  // use effect that run at the first render and if the id is changed to get the process by id
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    props.subProcessID && GetSubProcess(props.subProcessID, { signal: signal });

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [props.subProcessID]);

  //get  process and show them from DB:
  const GetSubProcess = (id: string, signal: any) => {
    subProcessService
      .getSubProcess(id)
      .then((response) => {
        //set process from DB in the process state:
        setSubProcess(response.data);
        response.data.imageID && getProcessImage(response.data.imageID);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteSubProcessImage = (imageID: any) => {
    dispatch(setLoading());

    ImageService.deleteImage(imageID)
      .then((response) => {
        //set process from DB in the process state:
        dispatch(removeLoading());
        setBaseImage(null);
      })
      .catch((e) => {
        console.log(e);
        dispatch(removeLoading());
      });
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //function to show the process image in the preview position if we are editing an existing process:
  const getProcessImage = (imageID: any) => {
    ImageService.getImage(imageID).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.png");
      document.body.appendChild(link);
      setBaseImage(link);
    });
  };

  const toggleModal = () => {
    setSubProcessPopup(!showSubProcessPopup);
  };
  if (showSubProcessPopup) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const handleInputChange = (event: any) => {
    setSubProcess({
      ...subProcess,
      name: event.target.value,
    });
  };

  return (
    <>
      <Formik
        initialValues={subProcess}
        validationSchema={ProcessSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid, dirty, errors }) => {
          return (
            <>
              <Form>
                <div className="modal">
                  <div onClick={toggleModal} className="overlay"></div>
                  <div className="modal-content-process-popup">
                    <h2>{props.label}</h2>
                    <div className="process-popup-name-field-container">
                      <h3>Prozessname: </h3>
                      <FormikControl
                        control="input"
                        name="name"
                        id="name"
                        type="text"
                        value={values.name}
                        onChange={(e: any) => {
                          handleInputChange(e);
                          setFieldValue("name", e.target.value);
                        }}
                      />
                    </div>
                    <div className="process-popup-image-container">
                      <label className="image-input" htmlFor="image-input">
                        <img
                          src={baseImage ? baseImage : image}
                          alt="Teilprozesse Img"
                          className="process-popup-image"
                        />
                        <Input
                          id="image-input"
                          type="file"
                          name="image"
                          onClick={(e: any) => (e.target.value = null)}
                          accept="image/*"
                          placeholder="Durchsuchen.."
                          onChange={async (event: any) => {
                            setFieldValue("image", event.target.files[0]);
                            const base64 = await convertBase64(
                              event.target.files[0]
                            );
                            setBaseImage(base64);

                            setSubProcess({
                              ...subProcess,
                              image: event.target.files[0],
                            });
                          }}
                        />
                      </label>
                      <TrashIcon
                        // id={process.imageID}
                        className="icon-trash-popup"
                        onClick={() => {
                          setFieldValue("image", "");
                          setBaseImage(null);

                          // deleteSubProcessImage(subProcess.imageID);
                        }}
                      />
                    </div>

                    <div className="process-popup-btns">
                      <DefaultButton
                        className="my-button"
                        type="submit"
                        onClick={() => onSubmit(subProcess.imageID)}
                        // disabled={!isValid}
                      >
                        Speichern
                      </DefaultButton>
                      <DefaultButton
                        onClick={props.closeSubProcessPopup}
                        className="my-button"
                      >
                        Abbrechen
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default SubProcessPopup;
