import http from "./http-common";
import authHeader from "./authHeader";

const getUnit = (id: number) => {
  return http.get(`/Unit/${id}`, { headers: authHeader() });
};

const getAllUnits = () => {
  return http.get("/Unit", { headers: authHeader() });
};

const createUnit = (data: any) => {
  return http.post("/Unit/", data, { headers: authHeader() });
};

const updateUnit = (id: number, data: any) => {
  return http.put(`/Unit/${id}`, data, { headers: authHeader() });
};

const deleteUnit = (id: number) => {
  return http.delete(`/Unit/${id}`, { headers: authHeader() });
};

const UnitService = {
  getUnit,
  getAllUnits,
  createUnit,
  updateUnit,
  deleteUnit,
};

export default UnitService;
