import "./ManageSubProcesses.css";
import { Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../Store/actions/messageAction";
import { setPresetProcess } from "../../../Store/actions/presetProcessAction";
import { setSubPresetProcess } from "../../../Store/actions/presetSubProcessAction";
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";

//Components:
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import Loader from "../../../Components/Loader/Loader";
import ProcessService from "./../../../Services/processService";
import CreateEditSubProcess from "../CreateEditSubProcess/CreateEditSubProcess";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

export interface ManageSubProcessesProps {}

const ManageSubProcesses: React.FC<ManageSubProcessesProps> = () => {
  const dispatch = useDispatch<any>();
  // redux states
  // loggedIn user
  const { user } = useSelector((state: any) => state.LoginReducer);

  // general loading indicator
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  // auth state
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);

  // message text and type from the redux state
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  const { presetProcessID } = useSelector(
    (state: any) => state.PresetProcessReducer
  );

  //local states
  // selected process from the dropdown
  const [selectedProcess, setSelectedProcess] = useState("");

  // local process state
  const [processes, setProcesses] = useState<any>();

  // function that get all process from DB and put them in the process state
  const GetAllProcesses = (signal: any) => {
    dispatch(setLoading());
    ProcessService.getAllProcesses()
      .then((response) => {
        setProcesses(response.data);
        dispatch(removeLoading());
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // get the processID form dropdown
  const handleProcessDropDownChange = (event: any) => {
    const selectedProcess = event.target.value;
    setSelectedProcess(selectedProcess);
    dispatch(
      setPresetProcess({
        presetProcessID: selectedProcess,
        presetProcessName: "",
      })
    );
    dispatch(setSubPresetProcess(""));
  };

  useEffect(
    () => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      GetAllProcesses({ signal: signal });
      document.title = "JMS - Teilprozesse bearbeiten";

      return function cleanup() {
        abortController.abort();
      };
    },
    // eslint-disable-next-line
    []
  );

  // to get reference to the used div and then check if the click is inside or outside
  const formRef: any = React.useRef<HTMLDivElement>(null);

  // check user authentication:
  if (isLoggedIn && user.userRole !== "Admin") {
    dispatch(setMessage("Sie sind kein Admin", "error"));
    return <Redirect to="/manage-locations" />;
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      <AdminNavbar />

      <div ref={formRef} className="create-processes-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div ref={formRef} className="edit-processes">
              <div className="col-1">
                <h1>Teilprozesse bearbeiten:</h1>
                <div className="manage-sub-process-dropdown-container">
                  <h2>Prozess: </h2>
                  <select
                    name="items"
                    onChange={handleProcessDropDownChange}
                    value={presetProcessID ? presetProcessID : selectedProcess}
                  >
                    <option value="">Prozess wählen</option>
                    {processes
                      ? processes.map((process: any, index: any) => (
                          <option
                            key={index}
                            id={process.processID}
                            value={process.processID}
                          >
                            {process.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>

            <br />
            <br />

            <div className="create-process-subProcess-forms">
              <div>
                {presetProcessID ? (
                  <CreateEditSubProcess
                    processID={presetProcessID && presetProcessID}
                    formRef={formRef}
                  />
                ) : selectedProcess ? (
                  <CreateEditSubProcess
                    processID={selectedProcess && selectedProcess}
                    formRef={formRef}
                  />
                ) : (
                  <h1 style={{ textAlign: "center" }}>
                    Wählen Sie zunächst einen Prozess.
                  </h1>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ManageSubProcesses;
