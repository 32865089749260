import http from "./http-common";
import authHeader from "./authHeader";

const getJobParameter = (id: number) => {
  return http.get(`/JobParameter/${id}`, { headers: authHeader() });
};

const getAllJobParameters = () => {
  return http.get("/JobParameter/", { headers: authHeader() });
};

const getJobParametersByMultipleFilter = (jobID: any, instructionIDs: any) => {
  // let qs = require("querystring");
  //parse a URL query string into an object
  // let params = { jobID: jobID, instructionIDs: instructionIDs };
  // let stringified = qs.stringify(params);


  // receive an instructionID
  var myInstructionIDs = instructionIDs
    .map((instructionID: any) => {
      return "instructionIDs=" + instructionID;
    })
    .join("&");

  return http.get(
    `/JobParameter/GetMultipleByFilter?jobID=${jobID}&` + myInstructionIDs,
    {
      headers: authHeader(),
      responseType: "json",
    }
  );
};

const createJobParameter = async (data: any) => {
  const res = await http.post("/JobParameter", data, { headers: authHeader() });
  return res;
};

const updateJobParameter = (data: any) => {
  return http.put(`/JobParameter/`, data, { headers: authHeader() });
};

const JobParameterService = {
  getJobParameter,
  getAllJobParameters,
  getJobParametersByMultipleFilter,
  createJobParameter,
  updateJobParameter,
};

export default JobParameterService;
