
import React from 'react'
import InputField from '../FormikContainer/InputField'

// import Textarea from './Textarea'
// import Select from './Select'
// import RadioButtons from './RadioButtons'
// import CheckboxGroup from './CheckboxGroup'
// import DatePicker from './DatePicker'
// import ChakraInput from './ChakraInput'

const FormikControl = (props:any) => {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <InputField {...rest} />

    default:
      return null
  }
}

export default FormikControl
