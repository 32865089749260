import {
  ADD_JOB_PARAMETER,
  UPDATE_JOB_PARAMETER,
  RESET_JOB_PARAMETER,
} from "./types";

// action to add a job parameter to the redux store
export const addJobParameter = (jobParameter: any) => (dispatch: any) => {

  dispatch({
    type: ADD_JOB_PARAMETER,
    payload: jobParameter,
  });
};

// action ot update an exciting job parameter in the redux store
export const updateJobParameterRedux = (jobParameter: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_JOB_PARAMETER,
    payload: jobParameter,
  });
};
//add reset job parameter
export const resetJobParameter = () => (dispatch: any) => {
  dispatch({
    type: RESET_JOB_PARAMETER,
  });
};
