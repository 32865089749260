import "./CreateUser.css";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import de from "date-fns/locale/de";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { Formik, Form, Field, ErrorMessage } from "formik";

// actions
import { setMessage } from "../../../Store/actions/messageAction";
import { getAllLocations } from "../../../Store/actions/locationAction";
import { CreateUserAction } from "../../../Store/actions/userAction";

// validations
import { UserSchema } from "../../../Validations/SensrecAdminValidations";

// Components:
import Loader from "../../../Components/Loader/Loader";
import SensrecAdminNavbar from "../SensrecAdminNavbar/SensrecAdminNavbar";
import DefaultButton from "../../../Components/DefaultButton/DefaultButton";
import SavePopup from "../../../Components/SavePopup/SavePopup";
import FormikControl from "../../../Components/FormikControl/FormikControl";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

registerLocale("de", de);
setDefaultLocale("de");

export interface CreateUserProps {}

const CreateUser: React.FC<CreateUserProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const locations = useSelector((state: any) => state.LocationReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  const [loading, setLoading] = useState(false);

  //check if state is changed
  const [isStateChanged, setIsStateChanged] = useState(false);
  //store clicked nav menu in a state
  const [clickedMenu, setClickedMenu] = useState("");

  // to get reference to the used div and then check if the click is inside or outside
  const formRef = React.useRef<HTMLDivElement>(null);

  const [isSavePopup, setSavePopup] = useState({
    savePopup: false,
  });

  const [User, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    ip: "",
    locationID: "",
    validFrom: new Date(),
    validTo: new Date(),
    roles: ["Admin"],
  });

  const onSubmit = (e: any) => {
    // e.preventDefault();
    setLoading(true);
    dispatch(
      CreateUserAction(
        User.firstname,
        User.lastname,
        User.email,
        User.password,
        User.ip,
        User.locationID,
        User.validFrom,
        User.validTo,
        User.roles
      )
    )
      .then(
        (response: any) => {
          setLoading(false);
          history.push("/manage-users");
          dispatch(
            setMessage("Der Benutzer wurde erfolgreich erstellt.", "success")
          );
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          return Promise.reject();
        }
      )
      .catch((e: any) => {
        setLoading(false);
        console.log(e);
      });
  };

  // get all location to the dropdown:
  useEffect(() => {
    dispatch(getAllLocations());
    document.title = "JMS - Benutzer bearbeiten";
  }, [dispatch]);

  //Added event listener mousedown(or click) to the document whenever this component is appear on screen(mount)
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick); // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // function that handle the input change
  const handleInputChange = (event: any) => {
    const { id, value } = event.target;
    setUser((previousState) => {
      return {
        ...previousState,
        [id]: value,
      };
    });
  };

  // function that handle the select location drop down
  const handleDropDownChange = (event: any) => {
    const locationSelected = event.target.value;
    setUser((prev) => {
      return {
        ...prev,
        locationID: locationSelected,
      };
    });
  };

  // function that handle the date changes valid from
  const handleDatesChange = (event: any) => {
    // const { name, value } = event.target;
    //add the time zone formula to solve the day before issue
    setUser((previousState) => {
      return {
        ...previousState,
        validFrom: event,
      };
    });
  };

  // function that handle the date changes valid to
  const handleDatesChange1 = (event: any) => {
    // const { name, value } = event.target;
    //add the time zone formula to solve the day before issue
    setUser((previousState) => {
      return {
        ...previousState,
        validTo: event,
      };
    });
  };

  // checking if use if loggedIn or no:
  if (isLoggedIn && user.userRole !== "SensrecAdmin") {
    dispatch(setMessage("Sie sind kein Sensrec Admin", "error"));
    return <Redirect to="/overview-page" />;
  }

  //control save pop up visibility if user clicks outside component
  const handleClick = (e: any) => {
    if (
      e.target.tagName.toLowerCase() !== "html" &&
      e.target.tagName.toLowerCase() !== "input" &&
      e.target.tagName.toLowerCase() !== "button"
    )
      if (
        e.target.tagName.toLowerCase() === "li" ||
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentNode.tagName.toLowerCase() === "a"
      ) {
        setSavePopup({
          //when click is outside we show save popup
          savePopup: true,
        });
        if (formRef && formRef.current && formRef.current.contains(e.target)) {
          //if click is inside our component
          // @ts-ignore: Object is possibly 'null'.
          return;
        }
        //if click is outside our component
        if (e.target.tagName.toLowerCase() === "li") {
          setClickedMenu(e.target.parentNode.getAttribute("href")); //get text of clicked menu
        } else if (e.target.tagName.toLowerCase() === "a") {
          setClickedMenu(e.target.getAttribute("href").toLowerCase()); //get text of clicked menu
        } else {
          setClickedMenu(
            e.target.parentNode.getAttribute("href").toLowerCase()
          ); //get text of clicked menu
        }
      }
  };

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => CheckIfStateChanged(), 0);
  const CheckIfStateChanged = () => {
    //check if user entered value
    if (
      User.validFrom !== null &&
      User.validTo !== null &&
      User.firstname !== "" &&
      User.lastname !== "" &&
      User.email !== "" &&
      User.password !== "" &&
      User.ip !== "" &&
      User.locationID !== ""
    ) {
      setIsStateChanged(true);
    } else {
      setIsStateChanged(false);
    }
  };

  return (
    <>
      <SensrecAdminNavbar />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      <div className="create-user-body">
        {loading ? (
          <Loader />
        ) : (
          <>
            {isStateChanged && isSavePopup.savePopup ? (
              <SavePopup
                label="Soll der Benutzer gespeichert werden?"
                Save={() => {
                  // @ts-ignore: Object is possibly 'null'.
                  var button = document.getElementById("clickSave"); //trigger click on save button in formik
                  button?.click();
                }}
                closeSavePopup={() => setSavePopup({ savePopup: false })}
                clickedMenu={clickedMenu}
              />
            ) : null}
            <div ref={formRef}>
              <Formik
                initialValues={User}
                validationSchema={UserSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  dirty,
                }) => {
                  return (
                    <>
                      <div className="title-btns">
                        <h1>Benutzer erstellen:</h1>
                        <div className="back-btn">
                          <DefaultButton onClick={() => history.goBack()}>
                            Zurück
                          </DefaultButton>
                        </div>
                      </div>
                      <Form className="create-user-form">
                        <table>
                          <tbody>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Vorname <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="firstname"
                                  id="firstname"
                                  value={User.firstname}
                                  type="text"
                                  placeholder="Vorname"
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Nachname <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="lastname"
                                  id="lastname"
                                  type="text"
                                  placeholder="Nachname"
                                  value={User.lastname}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  E-Mail <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="email"
                                  id="email"
                                  type="text"
                                  placeholder="E-mail"
                                  value={User.email}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Password <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="password"
                                  id="password"
                                  type="text"
                                  placeholder="Passwort"
                                  value={User.password}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  IP-Adresse{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <FormikControl
                                  control="input"
                                  name="ip"
                                  id="ip"
                                  type="text"
                                  placeholder="0.0.0.0"
                                  value={User.ip}
                                  onChange={handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Standort <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <Field
                                  as="select"
                                  name="locationID"
                                  id="locationID"
                                  onChange={handleDropDownChange}
                                >
                                  <option value="">Standort wählen</option>

                                  {locations
                                    ? locations.map(
                                        (location: any, index: any) => (
                                          <option
                                            key={index}
                                            id={location.locationID}
                                            value={location.locationID}
                                          >
                                            {location.city}
                                          </option>
                                        )
                                      )
                                    : null}
                                </Field>
                                <ErrorMessage
                                  name="locationID"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Gültig ab{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <DatePicker
                                  name="validFrom"
                                  locale="de"
                                  selected={
                                    User.validFrom ? new Date(User.validFrom) : null
                                  }
                                    onChange={handleDatesChange}
                                    dateFormat="dd.MM.yyyy"
                                    closeOnScroll={true}
                                    // minDate={new Date()}
                                    isClearable
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                    />

                                <ErrorMessage
                                  name="validFrom"
                                  component="div"
                                  className="new-license-error-message"
                                  />
                              </td>
                            </tr>
                            <tr>
                              <td className="my-td-label">
                                <h2>
                                  Gültig bis{" "}
                                  <span className="required-p">*</span>
                                </h2>
                              </td>
                              <td className="my-td-input">
                                <DatePicker
                                  name="validTo"
                                  locale="de"
                                  selected={User.validTo ? new Date(User.validTo) : null}
                                  onChange={handleDatesChange1}
                                  dateFormat="dd.MM.yyyy"
                                  closeOnScroll={true}
                                  // minDate={new Date()}
                                  isClearable
                                  showYearDropdown
                                  scrollableMonthYearDropdown
                                />

                                <ErrorMessage
                                  name="validTo"
                                  component="div"
                                  className="new-license-error-message"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  (<span className="required-p">*</span>
                                  ):{" "}
                                  <span className="required-p">
                                    Erforderlich
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="save-cancel-btns">
                          <DefaultButton
                            disabled={!isStateChanged}
                            type="submit"
                            id="clickSave"
                          >
                            Speichern
                          </DefaultButton>
                          <DefaultButton
                            type="submit"
                            onClick={() => history.push("/manage-users")}
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </Form>

                      <div>
                        <p>
                          Das Passwort muss mindestens 8 Zeichen lang sein und
                          mindestens ein Zeichen aus allen der vier folgenden
                          Kategorien enthalten:
                        </p>
                        <ul>
                          <li>
                            <p style={{ fontSize: "1rem", textAlign: "left" }}>
                              Großbuchstaben A,B,C...Z
                            </p>
                          </li>
                          <li>
                            <p style={{ fontSize: "1rem" }}>
                              Kleinbuchstaben a,b,c...z
                            </p>
                          </li>
                          <li>
                            <p style={{ fontSize: "1rem" }}>Zahlen 1,2,3...9</p>
                          </li>
                          <li>
                            <p style={{ fontSize: "1rem" }}>
                              Gültige Sonderzeichen !@#$%^&*"
                            </p>
                          </li>
                        </ul>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateUser;
