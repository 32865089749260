import "./SubProcessesOverview.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Redirect } from "react-router-dom";

// Services
import JobService from "../../../Services/jobService";
import ImageService from "../../../Services/imageService";
import SubProcessService from "../../../Services/subProcessService";

// Action
import { setJob } from "../../../Store/actions/jobAction";
// import { setSubPresetProcess } from "../../../Store/actions/presetSubProcessAction";
import {
  setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";

//components
import Date from "../Date/Date";
import Count from "../Count/Count";
import Photo from "../Photo/Photo";
import YesNo from "../YesNo/YesNo";
import Number from "../Number/Number";
import ListBox from "../ListBox/ListBox";
import FreeText from "../FreeText/FreeText";
import Signature from "../Signature/Signature";
import FinalPage from "../FinalPage/FinalPage";
import Instruction from "../Instruction/Instruction";
import Loader from "../../../Components/Loader/Loader";
import BarcodeScanner from "../BarcodeScanner/BarcodeScanner";
import MessageNotification from "../../../Components/MessageNotification/MessageNotification";

//Images:
import Image from "../../../Assets/Images/image.png";
import Header from "../../../Components/Header/Header";

function SubProcessesOverview() {
  // const history = useHistory();
  const dispatch = useDispatch<any>();

  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  const [subProcess, setSubProcess] = useState<any>();
  const [subProcesses, addSubProcess] = useState<any>();
  const [subProcessImages, setSubProcessImages] = useState<any>();

  // get the subProcessFunctionID from the url parameter
  let params: any = useParams();
  const { id } = params;
  const processID = id;

  //get the current route from url
  const currentRoute = useHistory().location.pathname.toLowerCase();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    processID && getSubProcessesByProcessID(processID, { signal: signal });

    dispatch(removeLoading());
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [processID]);

  // after selecting a subProcess we get the functions of the selected subProcess:
  //change the id type to string
  const getSubProcessByID = (id: string) => {
    dispatch(setLoading());
    id &&
      SubProcessService.getSubProcess(id)
        .then((response) => {
          setSubProcess(response.data);
          dispatch(removeLoading());

          // fire the begin of a Job
          JobService.createJob({ processID })
            .then((response) => {
              dispatch(setJob(response.data));
            })
            .catch((err) => {
              console.log("err", err);
            });

          // goToSubProcessesFunctions();
        })
        .catch((e) => {
          dispatch(removeLoading());
          console.log(e);
        });
  };

  //getting all sub-processes related to the processID of the main process:
  //change the id type to string
  const getSubProcessesByProcessID = (id: string, signal: any) => {
    dispatch(setLoading());

    SubProcessService.getSubProcessesByProcessID(id).then((response) => {
      dispatch(removeLoading());

      //checking if we have subProcess in the DB we add them to the state and show them:
      if (response.data.length > 0) {
        addSubProcess(response.data);
        getImages(response.data);
      }
    });
  };

  // function to get all images that take images IDs
  function getImages(data: any) {
    dispatch(setLoading());

    if (typeof data != "undefined") {
      let imageIDs = [];
      for (let item of data) {
        if (typeof item.imageID != "undefined") {
          imageIDs.push(item.imageID);
        }
      }
      ImageService.getAllImages(imageIDs, data)
        .then((res) => {
          setSubProcessImages(res.data);
          dispatch(removeLoading());
        })
        .catch((e: any) => {
          console.log(e);
          dispatch(removeLoading());
        });
      // }
    }
  }

  return (
    <>
      <Header subProcess={subProcess} />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          {!subProcess ? (
            <div className="sub-processes-user-overview">
              {subProcesses && <h3>Bitte wählen Sie einen Teilprozess</h3>}
              <div className="sub-process-overview">
                {subProcesses ? (
                  subProcesses.map((subProcess: any, index: any) => (
                    <div
                      className="user-one-sub-process"
                      key={index}
                      onClick={() => getSubProcessByID(subProcess.subProcessID)}
                    >
                      {subProcessImages &&
                        subProcessImages
                          .filter(
                            (item: any) => item.imageID === subProcess.imageID
                          )
                          .map(function (image: any) {
                            subProcess.image = image.image;
                            return null;
                          })}
                      <h4>{subProcess.name}</h4>
                      <img
                        className="user-sub-process-img"
                        src={
                          subProcess.image && subProcess.imageID !== undefined
                            ? "data:image/jpeg;base64," + subProcess.image
                            : (Image as any)
                        }
                        alt=""
                      />
                    </div>
                  ))
                ) : (
                  <h1>No sub processes found!</h1>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div>
                <Redirect
                  to={{
                    pathname:
                      "/sub-processes-overview/" +
                      subProcess?.assignedFunctions[0]?.code +
                      "/" +
                      subProcess?.assignedFunctions[0]
                        ?.subProcessFunctionAssignmentID,
                  }}
                />
              </div>
              {/* send setSubProcess as a prop in the 10 functions */}
              {currentRoute.includes("scan") ? (
                <BarcodeScanner
                  subProcess={subProcess}
                  setSubProcess={setSubProcess}
                />
              ) : null}
              {currentRoute.includes("list") ? (
                <ListBox
                  subProcess={subProcess}
                  setSubProcess={setSubProcess}
                />
              ) : null}
              {currentRoute.includes("freetext") ? (
                <FreeText
                  subProcess={subProcess}
                  setSubProcess={setSubProcess}
                />
              ) : null}
              {currentRoute.includes("instruction") ? (
                <Instruction
                  subProcess={subProcess}
                  setSubProcess={setSubProcess}
                />
              ) : null}
              {currentRoute.includes("signature") ? (
                <Signature
                  subProcess={subProcess}
                  setSubProcess={setSubProcess}
                />
              ) : null}
              {currentRoute.includes("date") ? (
                <Date subProcess={subProcess} setSubProcess={setSubProcess} />
              ) : null}
              {currentRoute.includes("number") ? (
                <Number subProcess={subProcess} setSubProcess={setSubProcess} />
              ) : null}
              {currentRoute.includes("yesno") ? (
                <YesNo subProcess={subProcess} setSubProcess={setSubProcess} />
              ) : null}
              {currentRoute.includes("photo") ? (
                <Photo subProcess={subProcess} setSubProcess={setSubProcess} />
              ) : null}
              {currentRoute.includes("count") ? (
                <Count subProcess={subProcess} setSubProcess={setSubProcess} />
              ) : null}
              {currentRoute.includes("final") ? (
                <FinalPage
                  subProcess={subProcess}
                  setSubProcess={setSubProcess}
                />
              ) : null}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SubProcessesOverview;
