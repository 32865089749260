import "./SavePopup.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { LogOutAction } from "../../Store/actions/authAction";
import _ from "lodash";

//components
import DefaultButton from "../DefaultButton/DefaultButton";

export default function SavePopup({
    label,
    closeSavePopup,
    Save,
    buttonText,
    clickedMenu,
  errors,
    //add is Login prop
  isLogin,
}: {
    label: any;
    closeSavePopup: any;
    Save: any;
    buttonText?: any;
    clickedMenu?: any;
    errors?: any;
  isLogin?: any;
}) {
    const [savePopup, setSavePopup] = useState(true);
    const [error, setError] = useState(false);
    const dispatch = useDispatch<any>();

    // we get the url
    const currentRoute = useHistory().location.pathname.toLowerCase();

    const toggleModal = () => {
        setSavePopup(!savePopup);
    };

  // check if we have error coming as prop from formik
  setTimeout(() => {
    if (
_.has(errors, "title") ||
_.has(errors, "name") ||
_.has(errors, "description") ||
_.has(errors, "validFrom") ||
_.has(errors, "validTo") ||
_.has(errors, "locationID") ||
_.has(errors, "count") ||
_.has(errors, "ip") ||
_.has(errors, "city") ||
_.has(errors, "code")
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, 0);

  if (savePopup) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  let path = clickedMenu ? clickedMenu : currentRoute;

    const logout = () => {
      dispatch(LogOutAction());
    }

  return (
    <>
      <div className="modal">
        <div onClick={toggleModal} className="overlay"></div>
        <div className="modal-content">
          <h2>{label}</h2>
          <div className="popup-btns">
            <DefaultButton onClick={error ? closeSavePopup : Save}>
              {buttonText ? buttonText : "Speichern"}
            </DefaultButton>
            <NavLink onClick={closeSavePopup} to={{ pathname: path }}>
              <DefaultButton
                onClick={() => {
                  if (clickedMenu === "/") logout();
                }}>
                {/* to custom the cancel button in the save popup in the user zone */}
            {buttonText ? "Nein" : "Abbrechen"}</DefaultButton>
            </NavLink>
          </div>
        </div>
      </div>
        </>
    );
}
