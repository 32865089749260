//declare actions types

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SHOW_LOADING = "SHOW_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_ONE_LOCATION = "GET_ONE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

export const CREATE_LICENSE = "CREATE_LICENSE";
export const GET_LICENSES = "GET_LICENSES";
export const GET_ONE_LICENSE = "GET_ONE_LICENSE";
export const UPDATE_LICENSE = "UPDATE_LICENSE";
export const DELETE_LICENSE = "DELETE_LICENSE";

export const CREATE_USER = "CREATE_USER";
export const GET_USERS = "GET_USERS";
export const GET_ONE_USER = "GET_ONE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_PROCESS = "CREATE_PROCESS";
export const GET_PROCESSES = "GET_PROCESSES";
export const GET_ONE_PROCESS = "GET_ONE_PROCESS";
export const UPDATE_PROCESS = "UPDATE_PROCESS";
export const DELETE_PROCESS = "DELETE_PROCESS";

export const SHOW_SUB_PROCESS = "SHOW_SUB_PROCESS";
export const HIDE_SUB_PROCESS = "HIDE_SUB_PROCESS";

export const GET_SUB_PROCESSES = "GET_SUB_PROCESSES";
export const GET_SUB_PROCESSES_BY_PROCESS_ID =
  "GET_SUB_PROCESSES_BY_PROCESS_ID";
export const UPSERT_SUB_PROCESS = "UPSERT_SUB_PROCESS";
export const UPDATE_SUB_PROCESS = "UPDATE_SUB_PROCESS";
export const DELETE_SUB_PROCESS = "DELETE_SUB_PROCESS";

export const CREATE_FUNCTION = "CREATE_FUNCTION";
export const GET_FUNCTIONS = "GET_FUNCTIONS";
export const DELETE_FUNCTION = "DELETE_FUNCTION";
export const UPDATE_FUNCTION = "UPDATE_FUNCTION";
export const GET_ONE_FUNCTION = "GET_ONE_FUNCTION";

export const SET_PROCESS = "SET_PROCESS";
export const SET_SUB_PROCESS = "SET_SUB_PROCESS";

export const GET_LOCATIONS_IP = "GET_LOCATIONS_IP";
export const GET_ONE_LOCATION_IP = "GET_ONE_LOCATION_IP";
export const CREATE_LOCATION_IP = "CREATE_LOCATION_IP";
export const UPDATE_LOCATION_IP = "UPDATE_LOCATION_IP";
export const DELETE_LOCATION_IP = "DELETE_LOCATION_IP";

export const SET_JOB = "SET_JOB";
export const REMOVE_JOB = "REMOVE_JOB";

export const ADD_JOB_PARAMETER = "ADD_JOB_PARAMETER";
export const UPDATE_JOB_PARAMETER = "UPDATE_JOB_PARAMETER";
export const RESET_JOB_PARAMETER = "RESET_JOB_PARAMETER";