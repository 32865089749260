import http from "./http-common";
import authHeader from "./authHeader";

const getImage = (id: number) => {
  return http.get(`/Download/${id}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};

const getAllImages = (imageIDs: any, data: any) => {
  let qs = require("querystring"); //parse a URL query string into an object
  let params = { imageID: imageIDs };
  let stringified = qs.stringify(params);
  return http.get(`/Download?` + stringified, {
    headers: authHeader(),
    params: {
      stringified,
    },
    responseType: "json",
  });
};

const deleteImage = (id: string) => {
  return http.delete(`/Download/${id}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};

const ImageService = {
  getImage,
  getAllImages,
  deleteImage,
};

export default ImageService;
