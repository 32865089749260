import "./AssignedFunction.css";
import { useState, useEffect } from "react";
import ImageService from "../../../Services/imageService";
import Loader from "../../../Components/Loader/Loader";
import {
  // setLoading,
  removeLoading,
} from "../../../Store/actions/loadingAction";
import { useSelector, useDispatch } from "react-redux";

export interface AssignedFunctionProps {
  imageID: any;
  name: any;
  order: any;
}

const AssignedFunction: React.FC<AssignedFunctionProps> = ({
  imageID,
  name,
  order,
}) => {
  // state to save the base64 code to show images:
  const [baseImage, setBaseImage] = useState<any>();
  const dispatch = useDispatch<any>();

  const { loading } = useSelector((state: any) => state.LoadingReducer);

  useEffect(
    () => {
      // dispatch(setLoading());
      // call the getImage API and send the image ID of every process then we get a downloadable file
      // we convert it to a blob then to a anchor link and we render it in the DOM

      ImageService.getImage(imageID).then((response) => {
        dispatch(removeLoading());
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.png");
        document.body.appendChild(link);
        // link.click();
        setBaseImage(link);
      });
    },
    // eslint-disable-next-line
    [imageID]
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="assignedFunction">
          <img src={baseImage} alt="" />
        </div>
      )}
    </>
  );
};

export default AssignedFunction;
